<template>
    <div class="total_container">
        <Tooltip v-bind:content="JSON.stringify(value, null, 2)"
                 max-width="200"
                 v-bind:transfer="true"
                 placement="top-start">
            <div v-if="value.hasOwnProperty('MarketPhase')"
                class="phase_container"
            >
                <span style="margin-right: 5px">时间段:</span>

                <template v-for="(v,k) in value['MarketPhase']">
                    <tag v-bind:key="k" class="phase_tag">{{v}}</tag>
                </template>
            </div>
        </Tooltip>
    </div>
</template>

<script>

    export default {
        name: 'conditionActiveInfo',
        props:{
            value:{// conditionActiveInfo 对象
                type:Object,
                default:null,
                required:false
            },
        },
        components: {
        },
        computed:{
            displayText:function () {

                const operation = this.value['operation'];
                const value = this.value['value'];
                if( operation == '>' || operation == '<' || operation == ">=" || operation == "<="){
                    return operation + " " + value;
                }else if(operation == "in" || operation == "not_in"){
                    return operation + " [" + value[0] + "," + value[1] + "]" ;
                }

                return "Invalid";
            }
        }
    }

</script>

<style scoped>

    .total_container{
        display: inline-block;
        margin-right: 5px;
    }

    .phase_container{
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        border-color: lightgray;

        padding-left: 5px;
    }

    .phase_tag{
        word-break: keep-all;
    }

</style>
