<template>
    <div class="positionList">
        <Card>
            <p slot="title">仓位列表</p>

            <div slot="extra">

                <Poptip title="高级配置" content="content" placement="bottom" trigger="hover" >
                    <a-icon type="appstore" style="margin-right: 5px;vertical-align:middle"/>
                    <div slot="content">
                        隐藏已清仓合约 <i-Switch size="small" v-model="hideZeroPosition"/>
                    </div>
                </Poptip>

                <RadioGroup size="small" v-model="view_type" type="button">
                    <Radio label="list">列表视图</Radio>
                    <Radio label="card">卡片视图</Radio>
                </RadioGroup>
            </div>

            <PositionListView v-if="view_type=='list'"
                              v-bind:hideZeroPosition="hideZeroPosition"
            ></PositionListView>

            <PositionCardView v-if="view_type=='card'"
                              v-bind:hideZeroPosition="hideZeroPosition"
            ></PositionCardView>


        </Card>
    </div>
</template>

<script>

import PositionCardView from "./Position/PositionCardView";
import PositionListView from "./Position/PositionListView";


export default {
    name: 'position',
    components: {
        PositionCardView,
        PositionListView
    },
    data: function () {
        return {
            view_type: "list",
            hideZeroPosition: false,
        }
    },
    computed:{
    }
}

</script>

<style scoped src="../../style/compactCard.css" />
<style scoped>

    .positionList {
        margin: 10px;
    }

</style>
