<template>
    <div>
        positioncardview

        <PositionCard v-for="(item,idx) in records"
                      v-bind:data="item"
                      v-bind:key="idx"
        ></PositionCard>
    </div>
</template>

<script>

    import PositionCard from "../../common/PositionCard";

    export default {
        name: 'positioncardview',
        components: {
            PositionCard
        },
        data: function () {
            return {
            }
        },
        computed:{
            records: function () {
                var ret = [];
                var positions = this.$store.state.accountPositionGroup;
                for (var idx=0; idx < positions.length; idx++) {
                    var item = positions[idx];
                    console.log("positionGroup:",item);

                    ret.push(item);
                }

                return ret;
            }
        }
    }

</script>

<style scoped>

</style>
