<template>
    <div class="container" ref="container">
        <!--priceTag:{{combinePrice}}-->
    </div>
</template>

<script>

    import serverMarketDataService from "@/service/serverMarketDataService";
    import util from "@/util";
    import Konva from "konva";

    /*
    {
        "ask_price": -1,
        "ask_size": 0,
        "bid_price": -1,
        "bid_size": 0,
        "close_price": null,
        "halted": false,
        "last_price": 0.01,
        "last_size": 10,
        "open_price": 0.26,
        "tick_size": 0.01,
        "tick_time": "2023-03-18 04:46:55.037254",
        "volume": 1190300
    }*/

    export default {
        name: 'PriceTag',
        components: {
        },
        props:{
            contract_id:{
                type: Number,
                default:null,
                required:false
            },
            price:{// 报价对象
                type: Object,
                default:null,
                required:false
            },
            radio:{// 把市场价格乘以倍数显示
                type: Number,
                default:1,
                required:false,
            },
            showAmount:{ //是否显示报价数量
                type: Boolean,
                default:true,
                required:false
            },
            mode:{//显示模式
                type: String,
                default:null,
                required:false
            },
            autoWatch:{ //自动订阅报价
                type: Boolean,
                default:false,
                required:false
            },
        },
        data:function(){
            return {
                watching:false, //是否正在订阅
                width: 130, //ToDo: 根据内容自动计算
                height: 22,
                //绘图的 canvas 对象
                "buyText":null,
                "askText":null,
                "processBar":null,
            }
        },
        mounted() {
            var stage = new Konva.Stage({
                container: this.$refs['container'],
                width: this.width,
                height: this.height
            });

            var layer = new Konva.Layer();
            var rect1 = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
                fill: '#23BE6d',
                //åshadowBlur: 5,
                cornerRadius: 3
            });
            // add the shape to the layer
            layer.add(rect1);

            var rect2 = new Konva.Rect({
                x: 0,
                y: 0,
                width: this.width/2,
                height: this.height,
                fill: '#328BED',
                cornerRadius: [3, 0, 0, 3] //左上，右上，右下，左下（左上角起顺时针）
            });
            layer.add(rect2);

            var processBarHeight = 2;
            var rect3 = new Konva.Rect({
                x: 0,
                y: this.height - processBarHeight,
                width: this.width,
                height: processBarHeight,
                fill: 'lightGray',
                cornerRadius: [0, 0, 5, 5]
            });
            layer.add(rect3);

            var processBarWidth = 89;

            this.processBar = new Konva.Rect({
                x: 0,
                y: this.height - processBarHeight,
                width: processBarWidth,
                height: processBarHeight,
                fill: '#ff6666',
                cornerRadius: [0, 0, 5, 5]
            });
            layer.add(this.processBar);

            this.buyText = new Konva.Text({
                x: 5,
                y: 5,
                width: this.width/2,
                text: null,
                fontSize: 15,
                fontFamily: 'Calibri',
                fill: 'white'
            });
            layer.add(this.buyText);

            this.askText = new Konva.Text({
                x: this.width/2,
                y: 5,
                width: this.width/2 - 5,
                text: null,
                fontSize: 15,
                align: 'right',
                fontFamily: 'Calibri',
                fill: 'white'
            });
            layer.add(this.askText);

            // add the layer to the stage
            stage.add(layer);

            //ToDo: 绘制时使用缓存
            this.fillData();
            this.watchMktData(this.contract_id);
        },
        beforeDestroy() {
            this.removeWatchMktData(this.contract_id);
        },
        computed:{
            combinePrice: function (){
                //console.log("run combinePrice");
                let price = null;
                if(this.price != null){
                    price = util.deepCopy(this.price);
                }else if(this.contract_id != null){
                    price = util.deepCopy(this.$store.state.marketLatestData[this.contract_id.toString()]);
                }

                if(price != null){
                    if(price.ask_price !== null && price.ask_price !== -1){
                        price.ask_price = price.ask_price * this.radio;
                    }

                    if(price.bid_price !== null && price.bid_price !== -1){
                        price.bid_price = price.bid_price * this.radio;
                    }
                }

                return price;
            },
            'buyTextString':function () {
                //console.log("run buyTextString");
                const data = this.combinePrice;
                if(data == null){
                    return null;
                }

                //Todo: 对数字作处理防止显示溢出
                let text = null;
                if(this.showAmount === true){
                    text = data.bid_price + "x" + data.bid_size;
                }else {
                    text = data.bid_price;
                }

                return text;
            },
            'askTextString':function () {
                //console.log("run askTextString");
                const data = this.combinePrice;
                if(data == null){
                    return null;
                }
                let text = null;
                if(this.showAmount === true){
                    text = data.ask_price + "x" + data.ask_size;
                }else {
                    text = data.ask_price;
                }

                return text;
            },
            'processBarRatio':function () {
                const data = this.combinePrice;
                if(data == null){
                    return 0;
                }

                //买卖价都不存在
                if((data.bid_size == null || data.bid_size <=0) && (data.ask_size ==  null || data.ask_size <= 0)){
                    return 0;
                }

                //只有买价
                if( (data.bid_size != null && data.bid_size >=0) && (data.ask_size == null || data.ask_size <=0)){
                    return 1;
                }

                //只有卖价
                if( (data.ask_size != null && data.ask_size >=0) && (data.bid_size == null || data.bid_size <=0)){
                    return 1;
                }

                return data.bid_size / (data.ask_size + data.bid_size);
            }
        },
        watch: {
            combinePrice: {
                deep: true,
                handler: function () {
                   this.fillData();
                }
            },
            contract_id:function (newValue, oldValue) {
                this.removeWatchMktData(oldValue);
                this.watchMktData(newValue);
            },
            autoWatch: function (){
                //console.log(`auto watch changed: ${this.autoWatch}`);
                if(this.contract_id != null){
                    if(this.autoWatch){
                        this.watchMktData(this.contract_id);
                    }else {
                        this.removeWatchMktData(this.contract_id);
                    }
                }
            }
        },
        methods:{
            fillData:function () {
                if(this.buyText != null){
                   this.buyText.text(this.buyTextString);
                }

                if(this.askText !=  null){
                    this.askText.text(this.askTextString);
                }

                if(this.processBar != null){
                    this.processBar.width(this.width * this.processBarRatio);
                }
            },
            watchMktData:function (contract_id){
                if(this.autoWatch && contract_id != null){
                    this.watching = true;
                    console.log(`watchMktData: contract_id=${this.contract_id}`);
                    serverMarketDataService.addContractWatchReason(contract_id, "WebPriceTag",  this._uid);
                }
            },
            removeWatchMktData:function (contract_id){
                if(this.watching === true && contract_id != null ){
                    this.watching = false;
                    console.log(`removeWatchMktData: contract_id=${contract_id}`);
                    serverMarketDataService.removeContractWatchReason(contract_id, "WebPriceTag",  this._uid);
                }
            }
        }
    }

</script>

<style scoped>

    .container {
        height: 22px;
        width: auto;
        padding: 0px;
        display: inline-block;
        margin-bottom: 0px;
        vertical-align: inherit;
    }

</style>
