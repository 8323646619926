<template>
    <div class="symbol_container">
        <template v-if="loading">
            <div class="spin_container">
                <Spin size="small"></Spin>
            </div>
        </template>
        <template v-else>
            <template v-if="combineContract!= null">
                <template v-if="combineContract.sec_type=='STK'">
                    <Tag class="sec_type_tag" v-bind:title="'contract_id:' + combineContract.id" color="success">STK</Tag>
                    <span class="symbol" >{{combineContract.symbol}}</span>
                </template>
                <template v-else-if="combineContract.sec_type=='OPT'">
                    <Tag class="sec_type_tag" v-bind:title="'contract_id:' + combineContract.id" color="primary">OPT</Tag>
                    <span class="symbol">{{combineContract.symbol}} </span>
                    <span class="opt">{{combineContract.option_right}}@{{combineContract.option_strike}} </span>
                    <span class="opt_date">{{moment(combineContract.last_trade_date, "YYYY-MM-DD").format("YYMMDD")}}</span>
                </template>
                <template v-else-if=" combineContract.sec_type=='BAG'">
                    <Tag class="sec_type_tag" v-bind:title="'contract_id:' + combineContract.id" color="default">BAG</Tag>
                    <span class="symbol">{{combineContract.symbol}} </span>
                    <div v-if="combineContract.combo_legs != null" style="margin-left: 15px">
                        <div v-for="v in combineContract.combo_legs" v-bind:key="v.contract_id">
                            <div class="amount_container">
                                <span v-if="v.action == 'BUY'">+</span>
                                <span v-if="v.action == 'SELL'">-</span>

                                <span> {{v.ratio}} </span>
                            </div>

                            <ContractSymbol  v-bind:contract_id="v.contract_id">
                            </ContractSymbol>
                        </div>
                    </div>
                </template>
                <template v-else-if="combineContract.sec_type=='BILL'">
                    <Tag class="sec_type_tag" v-bind:title="'contract_id:' + combineContract.id" color="default">BILL</Tag>
                    <span class="symbol">{{combineContract.symbol}} </span>
                    <span class="opt_date">{{moment(combineContract.last_trade_date, "YYYY-MM-DD").format("YYMMDD")}}</span>
                </template>
                <template v-else>
                    <Tag class="default">Unknown sec:{{JSON.stringify(combineContract)}} </Tag>
                </template>

                <!-- 关闭按钮 -->
                <template v-if="closable">
                    <Button size="small" class="vt_compact_btn" v-on:click="do_close(combineContract.id)"
                    >x</Button>
                </template>
            </template>
            <template v-else>
                <Tag class="sec_type_tag" color="default">NULL</Tag>
            </template>
        </template>
    </div>
</template>

<script>

    import util from "../../../util";
    import dataService from "../../../service/dataService";
    import moment from "moment";

    export default {
        name: 'ContractSymbol',
        props:{
            contract:{// contract 对象
                type: Object,
                default:null,
                required:false
            },
            contract_id:{
                type: Number,
                default:null,
                required:false
            },
            closable:{
                type: Boolean,
                default: false,
                required: false
            }
        },
        data:function () {
            return {
                moment,
                loading:false,//是否正在加载
                combineContract:null,
            };
        },
        mounted:async function(){
            //console.log("mounted", this.contract_id, this.contract);
            await this.update_combine_value();
        },
        watch:{
            'contract':async function(){
                await this.update_combine_value();
            },
            'contract_id':async function(){
                await this.update_combine_value();
            }
        },
        methods:{
            update_combine_value:async function () {
                this.loading = true;
                if(this.contract != null){
                    this.combineContract = util.deepCopy(this.contract);
                    //console.log("contractSymbol get contract by contract");
                }else if(this.contract_id != null) {
                    this.combineContract = await dataService.getContract(this.contract_id);
                    //console.log("contractSymbol get contract by contract_id");
                }else {
                    this.combineContract = null;
                }
                this.loading = false;
            },
            do_close:function (contract_id){
                console.log(`contractSymbol do_close: contract_id=${contract_id}`);
                this.$emit('onClose', contract_id);
            }
        }
    }

</script>

<style scoped src="@/style/compactTag.css" />
<style scoped>

    .symbol_container{
        display: inline-block;
        margin-top: 2px;
        margin-bottom: 2px;
        line-height: 20px;
    }

    .amount_container{
        display: inline-block;
        min-width: 38px;
        line-height: 20px;
    }

    .symbol {
        font-weight: bold;
        text-align:center;
        margin-right: 5px;
    }

    .opt_date{
        color: #999;
        margin-right: 3px;
    }
    

</style>
