<template>
    <div v-if="position != null && position.position != 0"
         v-bind:class="{ earn: position.unrealized_PNL >=0, loss: position.unrealized_PNL < 0}">
        <span>
            <template >
                <template v-if="position.unrealized_PNL >= 0">+</template>{{util.roundToNDecimal(position.unrealized_PNL/Math.abs(position.average_cost * position.position) * 100, 2)}}%
            </template>
        </span>
        <span >
            ( {{position.unrealized_PNL}}
        </span>
        <span class="currency">{{position.contract.currency}}</span> )

    </div>
</template>

<script>

import util from "@/util";

export default {
    name: "PositionNavTag",
    props:{
        position:{// 输入数据
            type: Object,
            default:null,
            required:false
        },
    },
    data:function (){
        return {
            util,
        }
    },
    computed:{
        isEarn:function (){
            return true;
        }
    }
}
</script>

<style scoped>
    .earn {
        color: red;
    }

    .loss {
        color: green;
    }

    .currency {
    }
</style>