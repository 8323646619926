
import util from "@/util";
import Konva from "konva";
import layoutUtil from "@/utils/layoutUtil";


//合约名称标准化
function getShortNameFromPosition(position, config) {

    var contract = position.contract;
    console.log("contract:",contract);
    var text = null;

    if(contract.sec_type == 'STK'){
        text = contract.symbol;
    }else if(contract.sec_type == "OPT"){
        text = contract.option_strike;
        text += contract.option_right.substr(0,1);

        if(config!=null && config.withPosition == true){
            text += " x " + position.position ;
        }

        if(config!=null && config.withDate == true){
            text += "\n" + contract.last_trade_date.substr(5,2) + "." + contract.last_trade_date.substr(8,2);
        }

    }else {
        text = contract.local_symbol;
    }

    console.log("text:",text);

    return text;
}


//对 positionGroup 进行计算，生成图形数据的逻辑
//data: List[VtCorePositionGroup]
class PositionGroupModel {
    constructor(data, container_size) {
        console.log("positionGroupModel init", data);

        //assert(data != null, "data must not null");
        //assert( typeof data == Object, "data must be object");

        this.data = data;
        this.container_size = container_size;
        this.baseLineHeight = this.container_size.height - 50;
        this.optLevel1LineHeight = this.baseLineHeight - 56; // 28 + 28
        this.optLevel2LineHeight = this.baseLineHeight - 84; /// 28 + 28 + 28
    }

    /*工具方法*/

    //矩形文本
    buildTextTag(text){
        var label = new Konva.Label({
        });

        label.add(
            new Konva.Tag({
                fill: 'yellow',
                stroke:'lightgray',
                strokeWidth:1
            })
        );

        label.add(
            new Konva.Text({
                text: text,
                fontSize: 12,
                align: 'left',
                fill: 'black'
            })
        );

        return label;
    }

    /* 计算方法 */

    // 计算底部横线位置 数据
    calcBaselineData() {
        return [0, this.baseLineHeight, this.container_size.width, this.baseLineHeight];
    }

    // 计算横线上，各个价格标记点，以及对应的（价格标签，数量标签，合约标签） 数据
    calcMarkDataList(){

        const positionListByPrice = new Map(); //把合约按价格汇总组合 [{'price': 12.5, 'positionList':[  vtCorePosition... ] },...]
        var priceList = [];// [12, 15, 18] 价格列表
        const optDateSet = new Set();  // 所有期权的到期时间字符串列表, 格式如 "2023-08-25"

        //加入基础合约最新价格
        const market_price = this.data.base.market_price;
        if(market_price != null){
            positionListByPrice.set(market_price, {'price':market_price, 'positionList': [this.data.base]});
        }

        //加入期权行权价，行权日期
        for( const option of this.data.opts){
            var price = option.contract.option_strike;
            var item = positionListByPrice.get(price);
            if( item == null){
                item = {'price':price, 'positionList':[]};
                positionListByPrice.set(price, item);
            }

            item.positionList.push(option);

            optDateSet.add(option.contract.last_trade_date);
        }

        //console.log('optDateSet:',optDateSet);

        priceList = Array.from(positionListByPrice.keys());
        priceList.sort(function (a, b) {
            return a - b;
        });

        //期权到期时间分级
        //共分为最多3级，最近的2个时间分别为 0,1 级，其他时间为 2 级
        // 期权日期到级别的映射 {'2023-08-18': 1, "2023-08-25":2, "2023-09-01":3,"2023-09-08":3}
        const optDateList = Array.from(optDateSet);
        optDateList.sort();
        console.log('optDateList:',optDateList);

        const optDateLevelMap = {};
        for(var i=0;i<optDateList.length;i++){
            const dateStr = optDateList[i];
            if(1 == 0 || i ==1){
                optDateLevelMap[dateStr] = i;
            }else {
                optDateLevelMap[dateStr] = 2;
            }
        }

        //console.log('priceList:',priceList);

        //标记点中的最高最低价
        var priceMax = null;
        var priceMin = null;

        //基线横轴两端对应的价格
        var leftPrice = null;
        var rightPrice = null;

        //计算基础横线，对应的价格范围
        if(priceList.length > 1){
            //存在多个价格点的情况
            priceMax = priceList[priceList.length-1];
            priceMin = priceList[0];

            //console.log('priceMin,priceMax:',priceMin, priceMax, typeof priceMin, typeof priceMax);

            var emptyPrice = (priceMax - priceMin) * 0.2;
            //console.log('emptyPrice:',emptyPrice);

            leftPrice = priceMin - emptyPrice; //空位置对应的价格
            rightPrice = priceMax + emptyPrice;

            if(leftPrice <0){
                leftPrice = 0;
            }

        }else {
            //单个价格的情况
            priceMax = priceList[0];
            priceMin = priceList[0];

            leftPrice = priceMin * 0.8;
            rightPrice = priceMax * 1.2;
        }

        console.log('price left,right:',leftPrice, rightPrice);

        //生成价格点
        var priceDataList = [];

        for(const price of priceList){

            console.log('build point for price:',price, typeof price);

            //价格点位置
            const x = ((price - leftPrice) / (rightPrice-leftPrice)) * this.container_size.width;

            //console.log(leftPrice, rightPrice, typeof leftPrice, typeof rightPrice);
            //console.log('x:',x);
            const y = this.baseLineHeight;

            var priceCountText = ''; //价格标签文本

            //console.log("positionListByPrice[price]", positionListByPrice.get(price));
            const positionList =  positionListByPrice.get(price).positionList;
            for( const position of positionList){
                if(priceCountText == ""){
                    priceCountText += position.position;
                }else {
                    priceCountText += "," + position.position;
                }
            }

            //合约标签
            const contractTagList = [];
            for(const position of positionList){
                const contractTagText = getShortNameFromPosition(position, {'withDate':true, 'withPosition':true});
                contractTagList.push(contractTagText);
            }

            //数量标签位置
            //价格标签位置
            priceDataList.push({
                'pricePoint':[x,y],
                'priceTag':price ,
                'countTag':priceCountText,
                'contractTagList':contractTagList,
            });
        }

        const ret = {
            'optDateLevelMap':optDateLevelMap,
            'priceDataList':priceDataList,
        };

        console.log('markDataList:',ret);
        return ret;
    }

    /************   构建对象    ************/

    //获取底部横线对象
    getBaselineSVGItem(){
        return new Konva.Line({
            points: this.calcBaselineData(),
            stroke: 'gray',
            strokeWidth: 2
        });
    }


    //获取标签和点对象
    getAllMarkSVGItem(){
        const markData = this.calcMarkDataList();
        const ret = {};
        const optDateLevelLineSVGItemList = [];
        const priceMarkSVGItemList = []; // [ [price1 items], [price2 items] ... ]

        ret['optDateLevelLineSVGItemList'] = optDateLevelLineSVGItemList;
        ret['priceMarkSVGItemList'] = priceMarkSVGItemList;

        //期权日期分隔线
        if(Object.values(markData.optDateLevelMap).includes(1)){
            optDateLevelLineSVGItemList.push(new Konva.Line({
                points:  [0, this.optLevel1LineHeight, this.container_size.width, this.optLevel1LineHeight],
                stroke: 'blue',
                strokeWidth: 1
            }));
        }

        if(Object.values(markData.optDateLevelMap).includes(2)){
            optDateLevelLineSVGItemList.push(new Konva.Line({
                points:  [0, this.optLevel2LineHeight, this.container_size.width, this.optLevel2LineHeight],
                stroke: 'blue',
                strokeWidth: 1
            }));
        }

        //逐个价格处理
        for(const markDataOfPrice of markData.priceDataList){

            const markSVGItemOfPrice = {};

            //横轴标记点
            const pricePointX = markDataOfPrice.pricePoint[0];
            const pricePointY = markDataOfPrice.pricePoint[1];

            const pricePoint = new Konva.Circle({
                x: pricePointX,
                y: pricePointY,
                radius: 3,
                fill: 'blue',
            });
            markSVGItemOfPrice['pricePoint'] = pricePoint;

            //横轴下方价格标签
            const priceTag = this.buildTextTag(markDataOfPrice.priceTag);
            priceTag.x(pricePointX);
            priceTag.y(pricePointY + 10);
            markSVGItemOfPrice['priceTag'] = priceTag;

            //横轴上方数量标签
            const countTag = this.buildTextTag(markDataOfPrice.countTag);
            countTag.x(pricePointX);
            countTag.y(pricePointY - 13);
            markSVGItemOfPrice['countTag'] = countTag;

            //上方合约标签（多个）
            const contractTagList = [];
            for(const itemData of markDataOfPrice.contractTagList ){

                const item = this.buildTextTag(itemData);
                item.x(pricePointX);
                item.y(pricePointY - 60);
                contractTagList.push(item);
            }
            markSVGItemOfPrice['contractTagList'] = contractTagList;

            priceMarkSVGItemList.push(markSVGItemOfPrice);
        }

        this.layoutMarkSVGItemList(priceMarkSVGItemList);
        return ret;
    }

    //对象自动布局
    layoutMarkSVGItemList(allMarkSVGItemList){

        var offsetLine =  0;

        //横线下方价格标签
        const spacePrice = new layoutUtil.Space();
        for(const markSVGItem of allMarkSVGItemList){
            var priceTagSVGItem = markSVGItem['priceTag'];

            var reactPrice = null;
            offsetLine = 0;
            while(true){
                reactPrice = new layoutUtil.React(priceTagSVGItem.getX(),priceTagSVGItem.getY(),priceTagSVGItem.width(),priceTagSVGItem.height());
                if(spacePrice.testOverlap(reactPrice) != true || offsetLine > 3 ){
                    break
                }

                offsetLine += 1;
                priceTagSVGItem.y( priceTagSVGItem.getY() + 13 )
            }

            spacePrice.add(reactPrice);
        }

        //横线上方数量标签
        const spaceCount = new layoutUtil.Space();
        for(const markSVGItem of allMarkSVGItemList){
            var countTagSVGItem = markSVGItem['countTag'];

            var reactCount = null;
            offsetLine = 0;
            while(true){
                reactCount = new layoutUtil.React(countTagSVGItem.getX(),countTagSVGItem.getY(),countTagSVGItem.width(),countTagSVGItem.height());
                if(spaceCount.testOverlap(reactCount) != true || offsetLine > 3 ){
                    break
                }

                offsetLine += 1;
                countTagSVGItem.y( countTagSVGItem.getY() - 13 )
            }

            spaceCount.add(reactCount);
        }


        //上方合约标签

    }

}
export default PositionGroupModel;