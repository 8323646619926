<template>
    <div class="marketData">
        <h3>合约组</h3>

        <Input v-model="create_group_name" placeholder="合约组名称" style="width: 300px; margin-right: 30px" />
        <Button type="primary" icon="md-add" v-on:click="group_create"
                v-bind:disabled="create_group_name==null || create_group_name==''"
        >添加</Button>

        <Tabs size="small" v-model="current_group_idx">
            <template v-for="(item) in group_records">
                <TabPane v-bind:key="item.id"
                         v-bind:label="item.name"
                         v-bind:icon="item.type === 'System'? 'md-desktop':'md-person' ">

                </TabPane>
            </template>
        </Tabs>

        <Divider orientation="left">属性</Divider>

        <Form v-bind:label-width="100"  style="max-width: 450px">
            <FormItem label="id" prop="id">
                <Input type="text" v-model="table_data.id"
                       v-bind:disabled="true"></Input>
            </FormItem>

            <FormItem label="名称" prop="name">
                <Input type="text" v-model="table_data.name"
                                   v-bind:disabled="table_data.type=='System'">
                </Input>
            </FormItem>
            <FormItem label="类型" prop="type">
                <RadioGroup v-model="table_data.type">
                    <Radio label="System" v-bind:disabled="true">系统</Radio>
                    <Radio label="User" v-bind:disabled="true">用户</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="备注" prop="remark">
                <Input type="text" v-model="table_data.remark"
                       v-bind:disabled="table_data.type=='System'">
                </Input>
            </FormItem>
            <FormItem label="订阅数据" prop="watch">
                <i-switch size="large" v-model="table_data.watch"
                          v-bind:disabled="table_data.type=='System'">
                    <span slot="open">On</span>
                    <span slot="close">Off</span>
                </i-switch>
            </FormItem>
            <FormItem>
                <Button type="primary"
                        v-bind:disabled="table_data_changed == false"
                        v-on:click="group_form_save">
                    保存
                </Button>

                <Button v-bind:disabled="table_data_changed == false"
                        v-on:click="group_form_reset"
                        style="margin-left: 8px">
                    还原
                </Button>
                <Button v-bind:disabled="table_data.type=='System'"
                        v-on:click="group_form_delete"
                        style="margin-left: 8px">
                    删除
                </Button>
            </FormItem>

        </Form>

        <Divider orientation="left">合约列表</Divider>

        <Button icon="md-add"
                style="margin-bottom: 12px"
                v-on:click="on_add_contract"
                v-bind:disabled="table_data.type=='System'"
        >添加合约</Button>

        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="contract_records"
               v-bind:class="{ empty: true }" border>
        </Table>

        <Modal v-model="showAddContractModal"
               title="向组中添加合约"
               v-bind:closable="false"
               footer-hide>
            <ContractSelector v-bind:contract_id.sync="contractId">
            </ContractSelector>

            <Divider style="margin: 10px 0 10px 0" ></Divider>

            <div style="text-align:right">
                <Button v-on:click="on_cancel_add_contract">Cancel</Button>
                <Button type="primary"
                        v-bind:disabled="contractId == null"
                        v-on:click="commit_add_contract"
                        style="margin-left: 8px">
                    Confirm
                </Button>
            </div>
        </Modal>

    </div>
</template>

<script>

    import ContractSymbol from "../common/display/ContractSymbol";
    import ContractSelector from "../common/editor/ContractSelector";
    import TableAction from "../common/TableAction";
    import vtradeAdapter from "../../adapter/vtradeAdapter";
    import util from "../../util";
    import constants from "../../constants";

    export default {
        name: 'group',
        components: {
            ContractSelector
        },
        data:function () {
            var that = this;
            return {
                //添加合约
                showAddContractModal:false, //显示添加合约模态框
                contractId: null, //合约选择器的值
                //创建合约组
                create_group_name:null,//要创建的合约组名称
                current_group_idx:0, //当前选中的 group idx
                contractSearchRet:[], //搜索到的合约
                table_data:{
                },
                group_records:[], //从服务端获取的 group 列表
                columns: [
                    {
                        title: '序号',
                        key: 'idx',
                        maxWidth:70
                    },
                    {
                        title: '合约',
                        key: 'contract',
                        render:function (h, params) {
                            return h(ContractSymbol,{
                                props:{
                                    contract:params.row,
                                }
                            });
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: function (h, params) {
                            return h(TableAction, {
                                props: {
                                    id: params.index,
                                    actionData: [
                                        {
                                            name:"删除",
                                            disabled:that.table_data.type=='System'
                                        },
                                    ]
                                },
                                on: {
                                    onClick:that.on_table_action,
                                }
                            });
                        }
                    }
                ],
            }
        },
        mounted: function () {
            this.getRecords();
        },
        methods:{
            getRecords:function () {
                console.log("get records");
                var that = this;
                vtradeAdapter.getContractGroupAll(function (data) {
                    console.log('data:' + data)
                    var records = [];
                    for(let i=0; i<data.length; i++){
                        var item = data[i];
                        console.log('item:', item);
                        item['idx'] = i;

                        records.push(item);
                    }

                    that.$data.group_records = records;
                });
            },
            group_create:function(){
                var that = this;
                util.show_confirm(
                    '创建合约组:' + that.create_group_name,
                    null,
                    function(){
                        vtradeAdapter.postContractGroupCreate(that.create_group_name, function () {
                            that.create_group_name = null;
                            that.getRecords();
                        });
                    },
                    null);
            },
            group_form_reset:function(){
                this.table_data = util.deepCopy(this.current_group);
            },
            group_form_save:function () {
                var that = this;
                util.show_confirm(
                    '修改合约组',
                    JSON.stringify(that.table_data, null, 4),
                    function(){
                        var data = {
                            name:that.table_data.name,
                            watch:that.table_data.watch,
                            remark:that.table_data.remark
                        };

                        vtradeAdapter.postContractGroupModify(that.table_data.id, data,function () {
                            console.log("postContractGroupModify success");
                            that.$Message.success('修改合约组成功');
                            that.getRecords();
                        });
                    },
                    null);
            },
            group_form_delete:function () {
                var that = this;
                util.show_confirm(
                    '删除合约组',
                    "name="+ that.table_data.name,
                    function(){
                        vtradeAdapter.postContractGroupDelete(that.table_data.id,  function () {
                            console.log("postContractGroupDelete success");
                            that.$Message.success('删除合约组成功');
                            that.getRecords();
                        })
                    },
                    null);
            },
            on_table_action:function(idx, actionName){
                console.log("on_table_action:",idx, actionName);
                if(actionName == "删除"){
                    var contract = this.contract_records[idx];
                    var that = this;
                    util.show_confirm(
                        '删除合约',
                        "合约 ="+ contract.symbol,
                        function(){
                            vtradeAdapter.postContractGroupModifyContract(that.table_data.id, [contract.id], constants.ModifyType.Del,function () {
                                console.log("postContractGroupModifyContract success");
                                that.$Message.success('删除合约成功');
                                that.getRecords();
                            })
                        },
                        null);
                }
            },
            on_add_contract:function () {
                //点击添加 contract 按钮
                this.showAddContractModal = true;
            },
            on_cancel_add_contract:function(){
                this.showAddContractModal=false
            },
            commit_add_contract:function () {
                var that = this;
                util.show_confirm(
                    '添加合约',
                    "合约 id="+ that.contractId,function(){
                     vtradeAdapter.postContractGroupModifyContract(that.table_data.id, [that.contractId], constants.ModifyType.Add, function () {
                        console.log("postContractGroupModifyContract success");
                        that.$Message.success('添加合约成功');
                        that.getRecords();
                        that.showAddContractModal = false;
                        that.contractId = null;
                    })
                })
            }
        },
        computed:{
            //从服务端获取到的当前 group 数据（包含 contract 列表）
            current_group:function () {
                if(this.group_records != null &&
                    this.current_group_idx != null &&
                    this.group_records.length > this.current_group_idx){

                    return this.group_records[this.current_group_idx];
                }

                return null;
            },
            //当前 contract 列表
            contract_records:function () {
                if(this.current_group == null){
                    return [];
                }

                //插入 idx
                var ret =[]
                for(var i=0; i<this.current_group['contracts'].length; i++){
                    var item = util.deepCopy(this.current_group['contracts'][i]);
                    item['idx'] = i;

                    ret.push(item);
                }

                return ret;
            },
            table_data_changed:function () {
                if(JSON.stringify(this.current_group) != JSON.stringify(this.table_data)){
                    return true;
                }

                return false;
            }
        },
        watch:{
            current_group:function () {
                this.group_form_reset();
            }
        }
    }

</script>

<style scoped src="../../style/noXBar.css" />


<style scoped>

    .marketData {
        margin-top: 20px;
        margin-right: 20px;
    }




    >>> .ivu-form-item {
        margin-bottom: 8px;
    }

</style>
