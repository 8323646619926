

//矩形
import PositionGroupModel from "@/model/PositionGroupModel";

function testOverlay(a, b) {

    const [ax, ay, aw, ah] = a.value;
    const [bx, by, bw, bh] = b.value;
    const aRight = ax + aw;
    const aBottom = ay + ah;
    const bRight = bx + bw;
    const bBottom = by + bh;

    // 判断矩形是否重叠
    if (ax < bRight && aRight > bx && ay < bBottom && aBottom > by) {
        return true;
    } else {
        return false;
    }
}

class React{
    constructor(x , y, width, height){
        this.value = [x,y,width,height];
    }
}


//重叠检测
class Space{
    constructor() {
        this.items = [];
    }

    clear(){
        this.items = [];
    }

    //加入一个矩形 react
    add(item){
        this.items.push(item);
    }

    //检测矩形和现有矩形，是否重叠
    testOverlap(a){
        for(const item of this.items){
            if(testOverlay(item, a)){
                return true;
            }
        }

        return false;
    }
}

export default {
    React,
    Space
};