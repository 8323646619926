import util from "../util";

var app = null;

function setApp(currentApp) {
    app = currentApp;
}

function readProfileMapFromStorage(){
    var profileStr = localStorage.getItem("vtProfile");
    var profileMap = JSON.parse(profileStr);

    return profileMap;
}

function readProfileMapFromVuex(){
    var profileMap = app.$store.state.config;
    return util.deepCopy(profileMap);
}

function saveProfileMapToStorage(profileMap){
    localStorage.setItem("vtProfile", JSON.stringify(profileMap));

    //save 时同步到 store
    app.$store.commit('updateConfig', profileMap);
}

function createProfile(name){
    var profileMap = readProfileMapFromVuex();
    profileMap['profiles'][name] = {
    };

    saveProfileMapToStorage(profileMap);
}

function removeProfile(name) {
    var profileMap = readProfileMapFromVuex();
    delete profileMap['profiles'][name];

    saveProfileMapToStorage(profileMap);
}

function initConfig(){
    console.log("initConfig");

    //init 时同步到 store
    var profileMapNow = readProfileMapFromStorage();
    //console.log("sync to vuex:",JSON.stringify(profileMapNow));
    app.$store.commit('updateConfig', profileMapNow);

    if(getAllProfileName().length == 0){
        console.log("no config profile existed, auto create it");

        var profileMap = {
            currentProfile: "default",
            profiles:{
                "default":{
                }
            }
        };

        saveProfileMapToStorage(profileMap);
    }
}

function getAllProfileName(){
    console.log("getProfiles");
    var profileMap = readProfileMapFromVuex();
    if(profileMap == null || profileMap['profiles'] == null){
        return [];
    }else {
        return Object.keys(profileMap['profiles']);
    }
}

function getCurrentProfileName(){
    console.log("getDefaultConfig");
    var profileMap = readProfileMapFromVuex();
    if(profileMap == null){
        return null;
    }

    return profileMap['currentProfile'];
}

function setCurrentProfileName(name){
    console.log("setDefaultConfig:" + name);
    var profileMap = readProfileMapFromVuex();
    profileMap['currentProfile'] = name;

    saveProfileMapToStorage(profileMap);
}

function getCurrentProfile() {
    var profileMap = readProfileMapFromVuex();
    var currentProfileName = profileMap['currentProfile'];

    return profileMap.profiles[currentProfileName];
}

function getProfile(name){
    console.log("getProfile:" + name);
    var profileMap = readProfileMapFromVuex();

    return profileMap['profiles'][name];
}

function setProfile(name, profile){
    console.log("setProfile:" + name, profile);
    var profileMap = readProfileMapFromVuex();
    profileMap['profiles'][name] = profile;

    saveProfileMapToStorage(profileMap);
}


export default {
    setApp,
    initConfig,
    readProfileMapFromVuex,
    readProfileMapFromStorage,
    createProfile,
    removeProfile,
    getAllProfileName,
    getCurrentProfileName,
    setCurrentProfileName,
    getCurrentProfile,
    setProfile,
    getProfile
}