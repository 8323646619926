<template>
    <div >
        <ConditionInfo v-bind:value="value">

        </ConditionInfo>
        <Button size="small" v-bind:disabled="editor_open"
                v-on:click="on_open_editor"
        >
            修改
        </Button>
        <div v-if="editor_open">
            <div class="editor_container">

                <Select v-model="oper" size="small" placeholder="运算符" class="editor_item">
                    <Option value=">" >></Option>
                    <Option value="<" >&lt;</Option>
                    <Option value=">=" >>=</Option>
                    <Option value="<=" >&lt;=</Option>
                    <Option value="in" >in</Option>
                    <Option value="not_in" >not_in</Option>
                </Select>

                <a-input-number size="small" v-model="oper_value_1" class="editor_item"
                             v-bind:min="0"
                             v-bind:step="1"
                >
                </a-input-number>

                <template v-if="['in','not_in'].includes(oper)">~
                </template>

                <a-input-number size="small" v-model="oper_value_2" class="editor_item"
                             v-show="['in','not_in'].includes(oper)"
                             v-bind:min="0"
                             v-bind:step="1"
                >
                </a-input-number>

            </div>

            <Button size="small" v-on:click="on_editor_cancel" style="margin-right: 5px"

            >取消</Button>

            <Button size="small" v-on:click="on_editor_commit" style="margin-right: 5px"
                    v-bind:disabled="can_commit == false"
            >提交</Button>
        </div>
    </div>
</template>

<script>

    import ConditionInfo from "./ConditionInfo";

    export default {
        name: 'conditionInfoEditor',
        props:{
            value:{// conditionActiveInfo 对象
                type:Object,
                default:null,
                required:false
            },
        },
        data:function(){
            return {
                editor_open:false, //编辑器是否被打开
                oper:null, //运算符
                oper_value_1:null, //第一个输入框的值
                oper_value_2:null, //第二个输入框的值
            };
        },
        components: {
            ConditionInfo
        },
        computed:{
            can_commit:function () {
                if(this.oper == null)
                    return false;

                if(['>', '>=', '<', '<='].includes(this.oper) && this.oper_value_1 != null){
                    return true;
                }else if(['in', 'not_in'].includes(this.oper) && this.oper_value_1 != null && this.oper_value_2 != null){
                    return true
                }

                return false;
            }
        },
        methods:{
            on_open_editor:function () {
                console.log(`conditionInfoEditor on_open_editor. this.value=${JSON.stringify(this.value)}`);
                //每次打开的时候，清除原始值，并且把当前的值填充到编辑器
                this.oper = this.value.operation;
                if(this.value.value instanceof Array){
                    this.oper_value_1 = this.value.value[0];
                    this.oper_value_2 = this.value.value[1];
                }else{
                    this.oper_value_1 = this.value.value;
                }

                this.editor_open = true;
            },
            on_editor_commit:function () {
                //点击提交按钮的时候，更改模型的值
                var newValue = {};
                newValue['operation'] = this.oper;
                if(['>', '>=', '<', '<='].includes(this.oper) && this.oper_value_1 != null){
                    newValue['value'] = this.oper_value_1;
                }else if(['in', 'not_in'].includes(this.oper) && this.oper_value_1 != null && this.oper_value_2 != null){
                    newValue['value'] = [this.oper_value_1, this.oper_value_2];
                }

                console.log(`conditionInfoEditor emit value:${JSON.stringify(newValue)}`);
                this.$emit('input', newValue);

                this.editor_open = false;
            },
            on_editor_cancel:function () {
                this.editor_open = false;
            }
        }
    }

</script>

<style scoped>

    .condition_tag{
        word-break: keep-all;
    }

    .editor_container{
        overflow: hidden;
        padding-top: 5px;
    }

    .editor_item{
        display: inline-block;
        margin-right: 5px;
        width: 90px;
    }

</style>
