import Vue from 'vue'

//前端消息总线服务

const all_receiver = new Map();//所有消息订阅者, key=handler, value=event_list

//前端消息列表
const EVENT_ACCOUNT_LOADED     = "ACCOUNT.LOADED";       // 前端载入账户信息
const EVENT_MESSAGE_BUS_WS_CONNECT     = "MESSAGE_BUS_WS.CONNECT";  // 前端 websocket 连接
const EVENT_MESSAGE_BUS_WS_DISCONNECT     = "MESSAGE_BUS_WS.DISCONNECT";  // 前端 websocket 断开连接
const EVENT_MARKET_DATA_WS_CONNECT     = "MARKET_DATA_WS.CONNECT";  // 前端 websocket 连接
const EVENT_MARKET_DATA_WS_DISCONNECT     = "MARKET_DATA_WS.DISCONNECT";  // 前端 websocket 断开连接


//后端消息列表同服务端定义，添加 SERVER. 前缀

function registerReceiver(handler, event_list) {
    console.log("registerReceiver:", handler.receiverName, event_list);

    var existed_event_list = all_receiver[handler];
    if(existed_event_list == null){
        existed_event_list = [];
        all_receiver.set(handler, existed_event_list);
    }

    for(var i=0; i<event_list.length; i++){
        var event = event_list[i];
        if(existed_event_list.indexOf(event) < 0){
            existed_event_list.push(event);
        }
    }

    console.log("registerReceiver end, all_receiver:",existed_event_list.length);
}

function removeReceiver(handler, event) {
    console.log("removeReceiver:",handler.receiverName, event);
    var event_list = all_receiver.get(handler);
    if(event_list == null){
        return;
    }

    var event_idx = event_list.indexOf(event);
    if(event_idx >= 0){
        event_idx.splice(event_idx,1);
    }

    if(event_list.length == 0 || event == null){
        console.log("remove receiver:",handler);
        all_receiver.remove(handler);
    }
}

function postEvent(event, key, value) {
    console.log("postEvent:",event,key,value);
    let all_receiver_keys = Array.from(all_receiver.keys());
    for(let i=0;i<all_receiver_keys.length;i++){
        let receiver = all_receiver_keys[i];
        let event_list = all_receiver.get(receiver);
        let match = false;

        console.log("test receiver:",receiver.receiverName, event_list);
        for(let j=0;j<event_list.length;j++){
            let subscribeEvent = event_list[j];
            if(event.indexOf(subscribeEvent) == 0){
                console.log("found match receiver:",receiver.receiverName);
                match = true;
                break;
            }
        }

        if(match == true){
            setTimeout(async function () {
                console.log("call receiver with event:", receiver.receiverName, event, key, value);
                await receiver(event, key, value);
            },0);
        }
    }
}

export default {
    registerReceiver,
    removeReceiver,
    postEvent,
    EVENT_ACCOUNT_LOADED,
    EVENT_MESSAGE_BUS_WS_CONNECT,
    EVENT_MESSAGE_BUS_WS_DISCONNECT,
    EVENT_MARKET_DATA_WS_CONNECT,
    EVENT_MARKET_DATA_WS_DISCONNECT,
}