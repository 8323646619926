<template>
    <div class="accountSummary">

        <Card style="height: 290px">
            <p slot="title">
                <Icon type="ios-film-outline"></Icon>
                账户详情
            </p>

            <div class="session1" v-on:animationend="handleAnimationEnd">
                <Row class="main_value_title">
                    <Col span="12">
                        净资产
                    </Col>
                    <Col span="12">
                        保证金占用
                    </Col>
                </Row>
                <Row class="main_value">
                    <Col span="12" v-bind:class="{ changed: changed }"
                         >
                        {{display.nav}} {{display.currency}}
                    </Col>
                    <Col span="12">
                        {{display.margin_used_percent}}%
                    </Col>
                </Row>
            </div>

            <div class="session2">
                <table  class="summary_table">
                    <tr>
                        <td style="width:15%">净清算价值</td>
                        <td style="width:55%">
                            <Progress :percent="parseFloat(display.nav_bar_percent)" :stroke-width="12" stroke-color="#2d8df0" hide-info />
                        </td>
                        <td class="summary_table_value" style="width:18%">{{display.nav}} </td>
                        <td class="summary_table_value_precent" >{{display.nav_percent}}%</td>
                    </tr>
                    <tr>
                        <td>现金</td>
                        <td>
                            <Progress :percent="parseFloat(display.cash_bar_percent)" :stroke-width="12" stroke-color="#2d8df0" hide-info/>
                        </td>
                        <td class="summary_table_value">{{display.cash}}</td>
                        <td class="summary_table_value_precent" style="width:10%">{{display.cash_percent}}%</td>
                    </tr>
                    <tr>
                        <td>保证金</td>
                        <td>
                            <Progress :percent="parseFloat(display.margin_bar_percent)" :stroke-width="12" stroke-color="#2d8df0" hide-info/>
                        </td>
                        <td class="summary_table_value">{{display.margin}}</td>
                        <td class="summary_table_value_precent" style="width:10%">{{display.margin_percent}}%</td>
                    </tr>
                    <tr>
                        <td>含贷款资产</td>
                        <td>
                            <Progress :percent="parseFloat(display.equity_bar_percent)" :stroke-width="12" stroke-color="#2d8df0" hide-info/>
                        </td>
                        <td class="summary_table_value">{{display.equity}}</td>
                        <td class="summary_table_value_precent" style="width:10%">{{display.equity_percent}}%</td>
                    </tr>
                </table>
            </div>

        </Card>
    </div>
</template>

<script>

import constants from '../../constants.js';
import eventBus from '../../service/messageBusService.js';

var AccountValueTag = constants.AccountValueTag;

export default {
    name: 'accountsummary',
    components: {
    },
    methods:{
        handleAnimationEnd:function () {
            console.log("handleAnimationEnd");
            this.$data['changed'] = false;
        }
    },
    data:function(){
        return {
            changed:false, //触发 css 动画
        };
    },
    computed:{
        display:function () {
            var ret = {
                "nav": "--",
                "currency": null,
                "cash":  0,
                "margin": 0,
                "equity": 0,
                "margin_used_percent":0,
                //显示数字的百分比
                "nav_percent": 30,
                "cash_percent": 30,
                "margin_percent": 30,
                "equity_percent": 30,
                //进度条百分比
                "nav_bar_percent": 30,
                "cash_bar_percent": 30,
                "margin_bar_percent": 30,
                "equity_bar_percent": 30,
            };
            var account_summary = this.$store.getters.account_summary;
            if(account_summary == null){
                return ret;
            }
            //计算 nav currency
            var account_value_nav = account_summary[AccountValueTag.NetLiquidation];
            var account_value_cash = account_summary[AccountValueTag.TotalCashBalance];
            var account_value_margin = account_summary[AccountValueTag.InitMarginReq];
            var account_value_equity = account_summary[AccountValueTag.EquityWithLoanValue];

            if(account_value_nav == null || account_value_cash == null || account_value_margin == null || account_value_equity == null){
                return ret;
            }

            ret.nav = (account_value_nav.value).toFixed(2);
            ret.currency = account_value_nav.currency;

            //计算各种资金
            ret.cash = (account_value_cash.value).toFixed(2);
            ret.margin = (account_value_margin.value).toFixed(2);
            ret.equity = (account_value_equity.value).toFixed(2);

            //计算比例
            ret.margin_used_percent = (100 * account_value_margin.value / account_value_equity.value).toFixed(2);
            ret.nav_percent = 100;
            ret.cash_percent = (100 * ret.cash/ret.nav).toFixed(2);
            ret.margin_percent = (100 * ret.margin/ret.nav).toFixed(2);
            ret.equity_percent = (100 * ret.equity/ret.nav).toFixed(2);

            ret.max_percent = Math.max(100.00, ret.nav_percent, ret.cash_percent, ret.margin_percent, ret.equity_percent);
            console.log("max_percent:", ret.max_percent);

            //进度条比例
            ret.nav_bar_percent = (ret.nav_percent * 100 / ret.max_percent).toFixed(2);
            ret.cash_bar_percent = (ret.cash_percent * 100 / ret.max_percent).toFixed(2);
            ret.margin_bar_percent = (ret.margin_percent * 100 / ret.max_percent).toFixed(2);
            ret.equity_bar_percent = (ret.equity_percent * 100 / ret.max_percent).toFixed(2);

            console.log("ret:",ret);

            return ret;
        },
        display_nav:function () {
            return this.display.nav + this.display.currency;
        }
    },
    watch:{
        display_nav:function () {
            console.log("display_nav changed");
            this.$data['changed'] = true;
        }
    }
}

</script>

<style scoped src="../../style/compactCard.css" />

<style scoped>
    .accountSummary {
        margin: 10px 5px 0px 10px;
    }

    .session1 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .summary_table {
        margin-top: 25px;
        border-collapse:collapse;
        width: 100%;
    }

    .summary_table td, .summary_table th {
        border: 1px solid #F0F0F0;
    }


    .summary_table tr {
        height: 30px;
    }

    .summary_table_value {
        color: gray;
        font-size: 1.5em;
        font-weight: bold;
        text-align: right;
        padding-right: 5px;
    }

    .summary_table_value_precent {
        color: #AAA;
        font-weight: bold;
    }

    .main_value_title {
        font-size: 1.5em;
        font-weight: bold;
        color: lightgray;
    }

    .main_value {
        font-size: 2em;
        font-weight: bold;
        color: gray;
    }

    /* 去掉进度条内置的背景阴影 */
    >>> .ivu-progress-inner {
         background-color: white !important;
    }

    .changed{
        animation: blink 1s 1;
    }

    @keyframes blink{
        50% {
            background-color: #FF0000;
        }
    }

</style>
