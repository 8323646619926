<template>
    <div class="marketData">
        <h3>条件监控</h3>

        <!-- 点击打开模态框 -->
        <Button icon="md-add"
                style="margin-bottom: 12px"
                v-on:click="on_create_record"
                v-bind:disabled="false"
        >添加条件</Button>

        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="records"
               v-bind:class="{ empty: true }" border>
        </Table>



        <Modal v-model="showEditorModal"
               v-bind:title="editorMode=='create' ? '添加条件':'修改条件'"
               v-bind:closable="false"
               footer-hide>

            <ConditionEditor  v-bind:mode="editorMode"
                              v-model="editorValue"
                              v-on:onSubmit="onEditorSubmit"
                              v-on:onCancel="onEditorCancel">
            </ConditionEditor>
        </Modal>

    </div>
</template>

<script>

    import ConditionEditor from '../common/editor/ConditionEditor';
    import BoolTag from "../common/display/BoolTag";
    import ConditionInfo from "../common/condition/ConditionInfo";
    import ConditionActiveInfo from "../common/condition/ConditionActiveInfo";
    import vtradeAdapter from "../../adapter/vtradeAdapter";
    import constants from "../../constants";
    import TableAction from "../common/TableAction";
    import util from "@/util";
    import ContractSymbol from "../common/display/ContractSymbol";

    export default {
        name: 'subscribe',
        components:{
            ConditionEditor,
            ConditionInfo,
            TableAction,
            ContractSymbol,
            BoolTag
        },
        data:function () {
            var that = this;
            return {
                showEditorModal:false, //显示添加提醒的模态框
                editorMode:'create',
                editorValue:null,
                records:[], //从服务端获取的 notice 列表
                columns: [
                    { title: 'id', key: 'id',
                        maxWidth:70},
                    { title: 'biz_id', key: 'biz_id'},
                    { title: 'enable', key: 'enable',
                        maxWidth:75,
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['enable'];

                            return h(BoolTag,{
                                props: {
                                    data:data
                                }
                            })
                        }
                    },
                    { title: 'name', key: 'name' },
                    { title: 'type', key: 'type'},
                    { title: 'contract', key: 'contract_id',
                        minWidth:75,
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['contract_id'];

                            return h(ContractSymbol,{
                                props: {
                                    contract_id:data
                                }
                            })
                        }
                    },
                    { title: 'notice_level', key: 'notice_level', minWidth:90},
                    { title: 'active_condition', key: 'active_condition',
                        minWidth:100,
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['active_condition'];

                            return h(ConditionActiveInfo,{
                                props: {
                                    value:data
                                }
                            })
                        },
                    },
                    { title: 'active', key: 'active',
                        maxWidth:75,
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['active'];

                            return h(BoolTag,{
                                props: {
                                    data:data
                                }
                            })
                        }
                    },
                    { title: 'info', key: 'info',
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['info'];

                            return h(ConditionInfo,{
                                props: {
                                    value:data
                                }
                            })
                        },
                    },
                    { title: 'match', key: 'match',
                        maxWidth:75,
                        render: function (h, params) {
                            var line = params.index;
                            var data = that.records[line]['match'];

                            return h(BoolTag,{
                                props: {
                                    data:data
                                }
                            })
                        }
                    },
                    { title: '操作', key: 'action',
                        render: function (h, params) {

                            console.log('this', this, this.records);
                            var line = params.index;
                            var data = that.records[line];

                            return h(TableAction,{
                                props:{
                                    id:line,
                                    actionData:[
                                        { name:"修改", disabled:false },
                                        { name:"删除", disabled:false }
                                    ]
                                },
                                on:{
                                    onClick:that.table_action,
                                }
                            });
                        }

                    }
                ]
            }
        },
        mounted: function () {
            this.getRecords();
        },
        methods: {
            on_create_record: function(){
                this.editorMode = 'create';
                this.showEditorModal = true;
            },
            getRecords:async function () {
                console.log("get records");
                const data = await vtradeAdapter.getConditionAll();

                console.log('data:' + data)
                var records = [];
                for(let i=0; i<data.length; i++){
                    var item = data[i];
                    console.log('item:', item);
                    item['idx'] = i;

                    records.push(item);
                }

                this.$data.records = records;
            },
            table_action:function (idx, actionName){
                console.log("receive table_action:",idx, name);
                var that = this;
                var line = this.records[idx];

                console.log("line:", JSON.stringify(line));

                if(actionName == "删除"){
                    util.show_confirm(
                        '删除条件:' + "[" + line['id'] + "] " + line['name'],
                        null,
                        async function(){
                            await vtradeAdapter.postConditionDelete(line['id']);

                            console.log("postConditionDelete success");
                            that.$Message.success('删除条件成功');
                            await that.getRecords();
                        },
                        null);
                }else if(actionName == "修改"){
                    this.editorMode = 'modify';
                    this.editorValue = line.id;
                    this.showEditorModal = true;
                }
            },
            onEditorSubmit:function (){
                console.log("onEditorSubmit");
                this.showEditorModal = false;
                this.getRecords();
            },
            onEditorCancel:function (){
                console.log("onEditorCancel");
                this.showEditorModal = false;
            },
        },
        computed:{

        }
    }

</script>

<style scoped src="../../style/noXBar.css" />


<style scoped>

    .marketData {
        margin-top: 20px;
        margin-right: 20px;
    }

    /*缩小表格间距*/
    >>> .ivu-table-cell{
        padding-left: 10px;
        padding-right: 10px;
    }

</style>
