<template>
    <div class="orderList">
        <Card>
            <p slot="title">订单列表</p>
            <p slot="extra">
                <Button size="small" v-on:click="onCreate">
                    创建订单
                </Button>
            </p>
            <Table size="small"
                   v-bind:columns="columns"
                   v-bind:data="records"
                   v-bind:class="{ empty: true }" border>
            </Table>
        </Card>

        <OrderEditor ref="orderEditor"
                     v-bind:order="editingOrder"
                     v-bind:commitToBackend="true"
                     v-on:onFinish="onEditFinish"
                     v-bind:mode="editorMode">
        </OrderEditor>
    </div>
</template>

<script>

import OrderAction from "../common/action/OrderAction";
import OrderEditor from "../common/editor/OrderEditor";
import ContractSymbol from "../common/display/ContractSymbol";
import constants from "../../constants";
import util from "../../util.js";
import vtradeAdapter from "../../adapter/vtradeAdapter";

export default {
    name: 'orderlist',
    components: {
        OrderAction,
        OrderEditor
    },
    data:function () {
        var that = this;
        return {
            showOrderEditor:false, //订单编辑的模态框是否显示
            editorMode:"show", //订单编辑器模式
            editingOrder:null,//当前正在被编辑的订单，填充数据结构防止渲染报错
            columns: [
                {
                    title: '编号',
                    key: 'unique_id',
                    width: 110,
                },
                {
                    title: '合约',
                    key: 'contract',
                    className: 'columns_symbol',
                    width: 300,
                    render:function (h, params) {
                        return h(ContractSymbol,{
                            props:{
                                contract:params.row.contract,
                            }
                        });
                    }
                },
                {
                    title: '方向',
                    key: 'action',
                    className: 'columns_action',
                    width: 100,
                },
                {
                    title: '条件',
                    key: 'condition'
                },
                {
                    title: '有效期',
                    key: 'tif',
                    width: 100,
                },
                {
                    title: '状态',
                    key: 'order_state',
                    width: 150,
                },
                {
                    title: '成交',
                    key: 'complete',
                    width: 100,
                },
                {
                    title: '附加信息',
                    key: 'addition',
                },
                {
                    title: '操作',
                    key: 'operation',
                    render:function (h, params) {
                        //console.log("render:", h, params);
                        var line = params.index;

                        var order = that.records[line];
                        if(order.order_state == constants.OrderState.Submitted
                            || order.order_state == constants.OrderState.PreSubmitted
                            || order.order_state == constants.OrderState.Inactive){
                            return h(OrderAction,{
                                props:{id:line},
                                on:{
                                    onShow:that.onShow,
                                    onModify:that.onModify,
                                    onCancel:that.onCancel,
                                    onCreateBotJobPriceImprove:that.onCreateBotJobPriceImprove,
                                }
                            });
                        }

                        return h();
                    }
                },
            ],
        }
    },
    methods:{
        onCreate:function(){
            //点击创建订单按钮
            console.log("create order");
            this.$data.editorMode="create";
            this.$data.editingOrder = null;

            var that = this;
            this.$nextTick(function () {
                //props 是加入队列中，延迟更新的
                that.$refs['orderEditor'].open();
            });
        },
        onShow:function(idx){
            //点击查看订单按钮
            console.log("onShow:", idx, this);
            this.launchOrderEditor(idx, "show")
        },
        onModify:function (idx) {
            //点击修改订单按钮
            console.log("onModify:", idx, this);
            this.launchOrderEditor(idx, "modify")
        },
        launchOrderEditor:function(idx, mode){
            //复制当前订单对象
            var currentOrder = this.$store.state.accountOrderList[idx];
            this.$data.editorMode = mode;

            var order_with_contract = util.deepCopy(currentOrder);

            delete order_with_contract['contract'];
            this.$data['editingOrder'] = order_with_contract;
            console.log("editingOrder:",this.$data['editingOrder']);

            var that = this;
            this.$nextTick(function () {
                //props 是加入队列中，延迟更新的
                that.$refs['orderEditor'].open();
            });

        },
        onCancel:function (idx) {
            //点击取消订单按钮
            console.log("onCancel:", idx);
            var currentOrder = this.$store.state.accountOrderList[idx];
            var that = this;

            util.show_confirm( //弹出确认
                '取消订单',
                null,
                function(){
                    console.log("confirm cancel order");

                    vtradeAdapter.cancelOrder(
                        that.$store.state.currentAccount,
                        currentOrder.unique_id,
                        function () {
                            console.log("cancel order success");
                            that.$Message.success('cancel order success');

                            this.showOrderEditor = false;
                        },
                        function () {
                            console.log("cancel order failed");
                        }
                    );
                },
            )
        },
        onCreateBotJobPriceImprove:function(idx){
            console.log("onCreateBotJobPriceImprove:", idx);

            var currentOrder = this.$store.state.accountOrderList[idx];
            var that = this;
            util.show_confirm( //弹出确认
                '为订单创建价格托管任务？',
                null,
                function(){
                    console.log("CreateBotJobPriceImprove create success");

                    vtradeAdapter.postCreatePriceImproveJobByOrder(
                        that.$store.state.currentAccount,
                        currentOrder.unique_id,
                        function (job) {
                            console.log("CreateBotJobPriceImprove create job success");
                            that.$Message.success({
                                'content': 'CreateBotJobPriceImprove create job success, now auto active',
                                'duration': 5
                            });

                            vtradeAdapter.postActiveBotJob(job.id, function () {
                                console.log("CreateBotJobPriceImprove active job success");
                                that.$Message.success({
                                    'content': 'CreateBotJobPriceImprove active job success',
                                    'duration': 5
                                });
                            })
                        },
                        function () {
                            console.log("CreateBotJobPriceImprove failed");
                        }
                    );
                },
            )
        },
        onEditFinish:function () {
        }
    },
    computed:{
        records:function () {
            var ret = [];
            var positions = this.$store.state.accountOrderList;
            for (var idx=0; idx < positions.length; idx++) {
                var item = positions[idx];
                var cell = {};
                console.log("order",item);

                cell['unique_id'] = item['unique_id'];
                cell['action'] = item['action'];
                cell['order_state'] = item['order_state'];
                cell['contract'] = item['contract'];
                cell['tif'] = item['tif'];
                cell['complete'] = item['filled'] + " / " + item['total_quantity'];

                var condition = "";
                if(item['order_type'] == 'LMT'){
                    if(item['contract'].sec_type == 'OPT'){
                        //期权
                    }

                    condition += "LMT @ " + item['lmt_price'];

                }
                cell['condition'] = condition;

                ret.push(cell);
            }

            return ret;
        }
    }
}

</script>

<style scoped src="../../style/compactCard.css" />
<style scoped src="../../style/noXBar.css" />


<style scoped>
    .orderList {
        margin: 10px;
    }




</style>
