<template>
    <div>
        <template v-for="(item, index) in data">
            <mktWatchReason v-bind:key="index" v-bind:data="item">
            </mktWatchReason>
        </template>
    </div>
</template>

<script>

    import mktWatchReason from "./MktWatchReasonView"

    export default {
        name: 'mktWatchReasonList',
        props: ['data'],
        components: {
            mktWatchReason
        }
    }

</script>

<style scoped>

</style>
