import axios from 'axios';
import util from '../util.js';
import cookies from 'browser-cookies';
import requestAdapter from './requestAdapter';
import configService from "../service/configService";


//把后端的接口封装成方法
//本模块无状态


function getBaseUrl() {
    var profile = configService.getCurrentProfile();
    var addr = profile['backendAddr'];
    if(addr == null)
        return "http://127.0.0.1:1080";

    return addr;
}

function getWsBaseUrl() {

    var baseUrl = getBaseUrl();
    var wsBaseUrl = null;

    if(baseUrl.startWith("http://")){
        wsBaseUrl = baseUrl.replace("http://","ws://");
    }else if(baseUrl.startWith("https://")){
        wsBaseUrl = baseUrl.replace("https://","wss://");
    }

    return wsBaseUrl;
}

function connectWs(wsUrl, openHandler, messageHandler, closeHandler) {

    var profile = configService.getCurrentProfile();

    var ws = new WebSocket(wsUrl);
    ws.onopen = function(evt) {  //绑定连接事件
        console.log("Connection open, send auth token ...");
        ws.send(JSON.stringify({"VtToken":profile['backendToken']}));

        if(openHandler != null){
            openHandler.call(this);
        }
    };

    ws.onmessage = function(evt) {//绑定收到消息事件
        //console.log( "Received Message string: " + evt.data);
        if(messageHandler != null){
            messageHandler.call(this, JSON.parse(evt.data));
        }
    };

    ws.onclose = function(evt) { //绑定关闭或断开连接事件
        console.log("Connection closed.");
        if(closeHandler != null){
            closeHandler.call(this);
        }
    };

    return ws;
}

function connectServerEventBusWs(openHandler, messageHandler, closeHandler) {

    let serverEventBusWsClient = null;
    if(serverEventBusWsClient != null && serverEventBusWsClient.closed ==false){
        console.log("ws already existed, ignore",serverEventBusWsClient);
        return;
    }

    var wsUrl = getWsBaseUrl() + '/messageBus/subscribeWs.json'
    serverEventBusWsClient = connectWs(wsUrl, openHandler, messageHandler, closeHandler);
    return serverEventBusWsClient;
}

function connectServerMarketDataWs(openHandler, messageHandler, closeHandler) {

    let serverMarketDataWsClient = null;
    if(serverMarketDataWsClient != null && serverMarketDataWsClient.closed == false){
        console.log("ws already existed, ignore",serverMarketDataWsClient);
        return;
    }

    var wsUrl = getWsBaseUrl() + '/marketData/subscribeWs.json'
    serverMarketDataWsClient = connectWs(wsUrl, openHandler, messageHandler, closeHandler);
    return serverMarketDataWsClient;
}

//获取系统状态
function getStatus(successHandler, failedHandler) {
    var url = getBaseUrl() + "/status.json";
    return requestAdapter.get( url, null, successHandler, failedHandler);
}

//获取账户列表
function getAccountList(successHandler, failedHandler) {
    var url = getBaseUrl() + "/account.json";
    return requestAdapter.get( url, null, successHandler, failedHandler);
}

//获取账户详情
function getAccountValues(account, successHandler, failedHandler) {
    var url = getBaseUrl() + "/account/" + account + "/detail.json";
    return requestAdapter.get( url, null, successHandler, failedHandler);
}

function getPositionList(account, successHandler, failedHandler) {
    var url = getBaseUrl() + "/account/" + account + "/portfolio.json";
    return requestAdapter.get( url, null, successHandler, failedHandler);
}

function getPositionGroup(account, successHandler, failedHandler) {
    var url = getBaseUrl() + "/account/" + account + "/portfolioGroup.json";
    return requestAdapter.get( url, null, successHandler, failedHandler);
}

function getOrderList(account, successHandler, failedHandler) {
    var url = getBaseUrl() + "/account/" + account + "/order.json";
    return requestAdapter.get( url, {'with_contract':true}, successHandler, failedHandler);
}

//订单操作
function placeOrder(account, order, successHandler, failedHandler) {
    var url = getBaseUrl() + "/order/place.json";
    return requestAdapter.post(url, {'account':account, 'order':JSON.stringify(order)}, successHandler, failedHandler);
}

function modifyOrder(account, unique_id, order, successHandler, failedHandler) {
    var url = getBaseUrl() + "/order/" + unique_id + "/modify.json";
    return requestAdapter.post(url, {'account':account, 'order':JSON.stringify(order)}, successHandler, failedHandler);
}

function cancelOrder(account, unique_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/order/" + unique_id + "/cancel.json";
    return requestAdapter.post(url, {'account':account}, successHandler, failedHandler);
}

//BotJob 相关接口

function getRunningBotJob(viewType, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/allRunning.json";
    return requestAdapter.get(url, {viewType}, successHandler, failedHandler);
}

//为订单创建价格托管任务
function postCreatePriceImproveJobByOrder(account, order_unique_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/createByOrder.json";
    return requestAdapter.post(url, {account, order_unique_id}, successHandler, failedHandler);
}

function postCreateBotJob(data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/create.json";
    return requestAdapter.post(url, data, successHandler, failedHandler);
}

function postModifyBotJob(job_id, data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/" + job_id + "/modify.json";
    return requestAdapter.requestWithConfig({method:"post", data:JSON.stringify(data), url, useJson:true}, successHandler, failedHandler);
}

function postActiveBotJob(job_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/" + job_id + "/active.json";
    return requestAdapter.post(url, {}, successHandler, failedHandler);
}

function postStopBotJob(job_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/algoBotJob/" + job_id + "/stop.json";
    return requestAdapter.post(url, {}, successHandler, failedHandler);
}

//查询合约
function searchContract(account, symbol, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contract/search.json";
    return requestAdapter.get(url, {'data_source':'driver','account':account, 'symbol':symbol, 'currency':'USD'}, successHandler, failedHandler);
}

function searchOption(base_contract_id, option_data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contract/searchOption.json";
    option_data['base_contract_id'] = base_contract_id;
    return requestAdapter.get(url, option_data, successHandler,  failedHandler);
}

function getContract(contract_id) {
    var url = getBaseUrl() + "/contract/" + contract_id + ".json";
    return requestAdapter.get(url);
}

//创建合约组
function postCreateBagContract(contract, successHandler, failedHandler){
    var url = getBaseUrl() + "/contract/createBagContract.json";
    return requestAdapter.requestWithConfig({method:"post", data:JSON.stringify(contract), url, useJson:true}, successHandler, failedHandler);
}

//市场数据订阅
function getMarketDataAllWatchContract(successHandler, failedHandler) {
    var url = getBaseUrl() + "/marketData/allWatchContract.json";
    return requestAdapter.get(url, null, successHandler, failedHandler);
}

//合约组
function getContractGroupAll(successHandler,  failedHandler) {
    var url = getBaseUrl() + "/contractGroup/all.json";
    return requestAdapter.get(url, {'with_contract':true}, successHandler, failedHandler);
}

function postContractGroupModify(group_id, data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contractGroup/" + group_id + "/modify.json";
    return requestAdapter.post(url, data, successHandler, failedHandler);
}

function postContractGroupModifyContract(group_id, contract_ids, action, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contractGroup/" + group_id + "/modifyContractId.json";
    return requestAdapter.post(url, {contract_ids:JSON.stringify(contract_ids), action}, successHandler, failedHandler);
}

function postContractGroupCreate(name, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contractGroup/create.json";
    return requestAdapter.post(url, {'name':name}, successHandler, failedHandler);
}

function postContractGroupDelete(group_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/contractGroup/" + group_id + "/delete.json";
    return requestAdapter.post(url, null, successHandler, failedHandler);
}

// 提醒
function getNotice(id, successHandler,  failedHandler) {
    var url = getBaseUrl() + "/notice/" + id + ".json";
    return requestAdapter.get(url, null, successHandler, failedHandler);
}

function getNoticeAll(successHandler,  failedHandler) {
    var url = getBaseUrl() + "/notice/all.json";
    return requestAdapter.get(url, null, successHandler, failedHandler);
}

function postNoticeModify(id, data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/notice/" + id + "/modify.json";
    return requestAdapter.post(url, data, successHandler, failedHandler);
}

function postNoticeCreate(data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/notice/create.json";
    return requestAdapter.post(url, data, successHandler, failedHandler);
}

function postNoticeDelete(id, successHandler,  failedHandler) {
    var url = getBaseUrl() + "/notice/" + id + "/delete.json";
    return requestAdapter.post(url, null, successHandler, failedHandler);
}

//条件
function getCondition(condition_id, successHandler, failedHandler) {
    var url = getBaseUrl() + "/condition/" + condition_id + ".json";
    return requestAdapter.get(url, null, successHandler, failedHandler);
}

function getConditionAll(successHandler,  failedHandler) {
    var url = getBaseUrl() + "/condition/all.json";
    return requestAdapter.get(url, null, successHandler, failedHandler);
}

function postConditionModify(id, data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/condition/" + id + "/modify.json";
    return requestAdapter.requestWithConfig({method:"post", data:JSON.stringify(data), url, useJson:true}, successHandler, failedHandler);
}

function postConditionCreate(data, successHandler, failedHandler) {
    var url = getBaseUrl() + "/condition/create.json";
    return requestAdapter.requestWithConfig({method:"post", data:JSON.stringify(data), url, useJson:true}, successHandler, failedHandler);
}

function postConditionDelete(id, successHandler,  failedHandler) {
    var url = getBaseUrl() + "/condition/" + id + "/delete.json";
    return requestAdapter.post(url, null, successHandler, failedHandler);
}

//市场行情
function getContractMarketDataSnapshotAll(account, successHandler, failedHandler) {
    var url = getBaseUrl() + "/marketData/contractSnapshotAll.json";
    return requestAdapter.get(url,{account}, successHandler, failedHandler);
}

//analyzer
function getOptionAnalyzerData(data, successHandler, failedHandler) {
    var url = getBaseUrl() +  "/analyze/option.json";
    return requestAdapter.get(url,  data, successHandler, failedHandler);
}

function getOptionListAnalyzerData(data, successHandler, failedHandler) {
    var url = getBaseUrl() +  "/analyze/optionList.json";
    return requestAdapter.get(url,  data, successHandler, failedHandler);
}

export default {
    connectServerEventBusWs,
    connectServerMarketDataWs,
    getStatus,
    getAccountList,
    getAccountValues,
    getPositionList,
    getPositionGroup,
    getOrderList,
    placeOrder,
    modifyOrder,
    cancelOrder,
    getRunningBotJob,
    postCreatePriceImproveJobByOrder,
    postCreateBotJob,
    postModifyBotJob,
    postActiveBotJob,
    postStopBotJob,
    searchContract,
    searchOption,
    getContract,
    postCreateBagContract,
    getMarketDataAllWatchContract,
    getContractGroupAll,
    postContractGroupCreate,
    postContractGroupModify,
    postContractGroupModifyContract,
    postContractGroupDelete,
    getContractMarketDataSnapshotAll,
    getNotice,
    getNoticeAll,
    postNoticeModify,
    postNoticeCreate,
    postNoticeDelete,
    getCondition,
    getConditionAll,
    postConditionModify,
    postConditionCreate,
    postConditionDelete,
    getOptionAnalyzerData,
    getOptionListAnalyzerData,
}