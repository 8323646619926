<template>
    <div>
        <Divider orientation="left">配置连接参数</Divider>


        <Row class="editor_line">
            <Col span="3" class="editor_line_title">
                后端服务器地址
            </Col>
            <Col span="6">
                <Input v-model="addr" placeholder="后端服务器地址" style="width: 300px; margin-right: 30px" />
            </Col>
        </Row>
        <Row class="editor_line">
            <Col span="3" class="editor_line_title">
                AccessToken
            </Col>
            <Col span="6">
                <Input v-model="token" placeholder="AccessToken" style="width: 300px; margin-right: 30px" />
            </Col>
        </Row>
        <Row class="editor_line">
            <Col span="3" class="editor_line_title">
                <br>
            </Col>
            <Col span="6">
                <Button type="primary"
                    v-bind:disabled="changed == false"
                    v-on:click="saveConfig">
                    保存配置
                </Button>
            </Col>
        </Row>

    </div>
</template>

<script>

import configService from "../../service/configService";
import util from "../../util";

export default {
    name: 'connect',
    components: {
    },
    data:function () {

        var profile = configService.getCurrentProfile();

        return {
            "addr": profile["backendAddr"],
            "token": profile["backendToken"]
        }
    },
    methods:{
        saveConfig: function(){
            var profileName = configService.getCurrentProfileName();
            var profile = configService.getProfile(profileName);
            profile['backendAddr'] = this.$data['addr'];
            profile['backendToken'] = this.$data['token'];

            util.show_confirm(
                '修改配置',
                JSON.stringify(profile, null, 4),
                function(){
                    configService.setProfile(profileName, profile);
                },
                null);

            console.log(JSON.stringify(profile, null, 4))
        }
    },
    computed:{
        changed: function () {
            var profile = configService.getCurrentProfile();

            if(profile['backendAddr'] == this.$data['addr']
                && profile['backendToken'] == this.$data['token']){
                return false;
            }

            return true;
        }
    }
}

</script>

<style scoped>

    .editor_line {
        margin-bottom: 20px;
    }

    .editor_line_title {
        margin-top: 6px;
        font-weight: bold;
    }

</style>
