<template>
    <div>
        {{showText}}
    </div>
</template>

<script>
    export default {
        name: "BotJobView.vue",
        props:{
            botJobId:{// 输入数据
                type: Number,
                default:null,
                required:false
            },
        },
        computed:{
            showText:function () {
                var jobData = this.$store.state.botJobData[this.botJobId];
                return jobData;
            }
        }
    }
</script>

<style scoped>

</style>