<template>
    <div style="display: inline-block">
        <Tooltip v-bind:content="JSON.stringify(value)" placement="top-start">
            <Tag class="condition_tag">{{displayText}}</Tag>
        </Tooltip>
    </div>
</template>

<script>

    export default {
        name: 'conditionInfo',
        props:{
            value:{// conditionActiveInfo 对象
                type:Object,
                default:null,
                required:false
            },
        },
        components: {
        },
        computed:{
            displayText:function () {

                if(this.value == null){
                    return "Invalid";
                }

                const operation = this.value['operation'];
                const value = this.value['value'];
                if( operation == '>' || operation == '<' || operation == ">=" || operation == "<="){
                    return operation + " " + value;
                }else if(operation == "in" || operation == "not_in"){
                    return operation + " [" + value[0] + "," + value[1] + "]" ;
                }

                return "NULL";
            }
        }
    }

</script>

<style scoped>

    .condition_tag{
        word-break: keep-all;
    }

</style>
