// 公有 lib
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import iView from 'iview';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import 'iview/dist/styles/iview.css';
import cookies from 'browser-cookies';
import $ from 'jquery';
import Konva from "konva";


// 自有 js 模块
import enhanced from './enhanced.js';
import vtradeAdapter from './adapter/vtradeAdapter.js';
import constants from './constants.js';

// service
import eventBusService from './service/messageBusService';
import dataService from './service/dataService';
import configService from './service/configService';
import serverEventBusService from "./service/serverMessageBusService";
import serverMarketDataService from "./service/serverMarketDataService";

// vue components
import TopNav from './components/TopNav.vue';
import HelloWorld from './components/HelloWorld.vue';
import Dashboard from  './components/Dashboard.vue';
import Config from './components/Config.vue';
import Analyze from './components/Analyze.vue';

//config
import ConfigProfile from './components/config/Profile';
import ConfigConnect from './components/config/Connect';

// dashboard
import DashboardAccountSummary from './components/Dashboard/AccountSummary.vue';
import DashboardOrderList from './components/Dashboard/OrderList.vue';
import DashboardPosition from './components/Dashboard/Position.vue';

// analyze
import AnalyzeMarketData from './components/Analyze/MarketData';
import AnalyzeSubscribe from './components/Analyze/Subscribe';
import AnalyzeCondition from './components/Analyze/Condition';
import AnalyzeGroup from './components/Analyze/Group';
import AnalyzeNotice from './components/Analyze/Notice';


window.paceOptions = {
  catchupTime: 100,
  minTime: 150,
  restartOnRequestAfter: -1,
  ajax :{
    trackMethods: ['GET','POST'],
  }
};

const helpers = {
    install (Vue, options) {
        Vue.prototype.$constants = constants; // we use $ because it's the Vue convention
    }
};

Vue.use(helpers);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(iView);
Vue.use(Antd);

Konva.pixelRatio = 2;


const router = new VueRouter({
    routes:[
        {path:"/dashboard",component:Dashboard},
        {path:"/analyze",component:Analyze, children:[
            {path:"marketData",component:AnalyzeMarketData},
            {path:"subscribe",component:AnalyzeSubscribe},
            {path:"group",component:AnalyzeGroup},
            {path:"condition",component:AnalyzeCondition},
            {path:"notice",component:AnalyzeNotice},
        ]},
        {path:"/config",component:Config, children:[
            {path:"profile",component:ConfigProfile},
            {path:"connect",component:ConfigConnect}
        ]},
    ]
});


const store = new Vuex.Store({
    state: {
        ready: false,
        config: null,
        serverMessageBusWsStatus: 'disconnected', //ws 连接状态
        serverMarketDataWsStatus: 'disconnected', //ws 连接状态
        backendStatus:{},//后端服务状态
        driverStatus: {},
        marketLatestData: {}, //市场行情
        botJobData:{}, // botJob 数据, key= str(job.id), value=job
        accountList:[],//账户列表
        currentAccount:null, //当前使用的账户,
        accountDetail:{},//账户详情
        accountPositionList:[],//账户头寸
        accountPositionGroup:[],//账户头寸（分组数据）
        accountOrderList:[],//账户订单

        //全局 Gui 控制
        botJobViewId:null, // 需要显示 BotJob 视图时，在这里写入 botJobId 就会触发模态框弹出 (由 dashboard 模块统一弹出)
        optionAnalyzerViewId:null, //需要显示 optionAnalyzer 时，写入 contractId 或者 true（仅弹出不设置合约）就会触发模态框弹出
    },
    mutations: {
        'updateDriverStatus':function (state, driverStatus) {
            Vue.set(state, 'driverStatus', driverStatus);
        },
        'updateAccountList':function (state, data) {
            Vue.set(state, 'accountList', data);
        },
        'updateBackendStatus':function (state, data) {
            console.log("updateBackendStatus", data);
            Vue.set(state, 'backendStatus', data);
        },
        'updateConfig':function (state, config) {
            console.log("updateConfig:",config);
            Vue.set(state, 'config', config);
        },
        'updateMarketLatestData':function (state, contractMarketData) {
            //console.log("updateMarketLatestData:", contractMarketData);
            Vue.set(state.marketLatestData, contractMarketData['id'], contractMarketData['data']);
        },
        'updateBotJobDataList':function (state, botJobDataList) {
            console.log("updateBotJobDataList:",botJobDataList);
            Vue.set(state, 'botJobData', botJobDataList);
        },
        'updateBotJobData':function (state, botJobData) {
            console.log("updateBotJobData:",botJobData);
            Vue.set(state.botJobData, String(botJobData['id']), botJobData);
        },
        'updateCurrentAccount':function (state, account) {
            state.currentAccount = account;
        },
        'updateServerMessageBusWsStatus':function (state, status) {
            console.log("updateServerMessageBusWsStatus",status);
            state.serverMessageBusWsStatus = status;
        },
        'updateServerMarketDataWsStatus':function (state, status) {
            console.log("updateServerMarketDataWsStatus",status);
            state.serverMarketDataWsStatus = status;
        },
        'updateAccountDetail':function (state, accountDetail) {
            console.log("updateAccountDetail",accountDetail);
            state.accountDetail = accountDetail;
        },
        'updateAccountPositionList':function (state, accountPositionList) {
            state.accountPositionList = accountPositionList;
        },
        'updateAccountPositionGroup':function (state, accountPositionGroup) {
            state.accountPositionGroup = accountPositionGroup;
        },
        'updateAccountOrderList':function (state, accountOrderList) {
            state.accountOrderList = accountOrderList;
        },
        'updateBotJobViewId':function (state, botJobViewId) {
            state.botJobViewId = botJobViewId;
        },
        'updateOptionAnalyzerViewId':function (state, optionContractId) {
            state.optionAnalyzerViewId = optionContractId;
        }
    },
    getters: {

        account_summary:function (state) {

            console.log("getter account_summary");
            var accountDetail = state.accountDetail;
            console.log("accountDetail:",accountDetail);

            if(accountDetail == null){
                return {};
            }

            var ret = {};
            var keys = Object.keys(accountDetail);
            console.log("keys:",keys);
            for(var i=0; i<keys.length; i++){
                var key = keys[i];
                console.log('key->',key);
                ret[key] = accountDetail[key];
            }

            return ret;
        }

    }
});


//事件驱动的逻辑控制
async function appEventHandler(event_type, value) {

    console.log("eventHandler:",event_type, value);
    if(event_type == eventBusService.EVENT_MESSAGE_BUS_WS_CONNECT){
        //message bus 连接之后，开始更新后端服务状态，账户信息
        await dataService.getBackendServiceStatus();
        await dataService.getAllDataOfAccount();

    }else if(event_type == eventBusService.EVENT_MESSAGE_BUS_WS_DISCONNECT){
        //发起重连
        console.warn("serverEventBus Websocket disconnect, will reconnect later");
        setTimeout(function () {
            serverEventBusService.startSubscribe();
        }, 3000);
    }else if(event_type == eventBusService.EVENT_MARKET_DATA_WS_DISCONNECT){
        console.warn("marketData Websocket disconnect, will reconnect later");
        setTimeout(function () {
            serverMarketDataService.startSubscribe();
        }, 3000);
    }

    console.log("eventHandler end");
}
appEventHandler.receiverName = "appEventReceiver";



const app = new Vue({
    el:'#app',
    router:router,
    store: store,
    components:{
        "topnav":TopNav,
        'accountsummary':DashboardAccountSummary,
        'orderlist':DashboardOrderList,
        'position':DashboardPosition,
        'marketdata':AnalyzeMarketData
    },
    data:{
        ready:false,
        user:null,
    },
    methods:{
        url_change : function( new_url ){
            if( this.$router.currentRoute.fullPath == new_url){
                return;
            }
            console.log('url_change',this.$router.currentRoute.fullPath, new_url);
            this.$router.push(new_url);
        },
    },
    created:function(){
        console.log("app created");

        serverMarketDataService.setApp(this);
        serverEventBusService.setApp(this);
        dataService.setApp(this);
        configService.setApp(this);

        configService.initConfig();
        console.log("current profileName:",configService.getCurrentProfileName());

        eventBusService.registerReceiver(appEventHandler, ['ACCOUNT.','MESSAGE_BUS_WS.','MARKET_DATA_WS.']);
    },
    mounted:async function () {
        console.log("app mounted, start with entry:",this.$route.path);
        if( this.$route.path == "/" ){
            let initPath = "/dashboard";
            console.log("auto push to :", initPath);
            await this.$router.push(initPath);
        }

        //启动后先获取基本信息
        await dataService.getBaseInfoFromBackend(this);

        //基本信息获取之后，连接 websocket，其他信息由 websocket 连接状态变化驱动获取
        // websocket 每次连接后会触发一次数据获取
        // 断开后会自动重连，然后再次触发数据获取
        serverEventBusService.startSubscribe();
        serverMarketDataService.startSubscribe();

    },
    computed:{
        nav_tab:function () {

            var path = this.$route.path;
            if(path.startsWith("/dashboard")){
                return 'dashboard';
            }else if(path.startsWith('/analyze')){
                return 'analyze';
            }else if(path.startsWith('/config')){
                return 'config';
            }

            return null;
        },
    },
    watch:{
        '$store.state.currentAccount':async function (res) {

            //右上角 account 变化时刷新数据
            console.log("currentAccount changed:",res);
            if(res == null){
                return;
            }

            await dataService.getAllDataOfAccount();
        }
    }
});

console.info("app",app);









