<template>
    <div>
        <Button size="small" class="action_btn" v-on:click="doShow">查看</Button>
        <Button size="small" class="action_btn" v-on:click="doModify">修改</Button>
        <Button size="small" class="action_btn" v-on:click="doCancel">取消</Button>
        <Button size="small" v-on:click="doCreateBotJobPriceImprove">价格托管</Button>
    </div>
</template>

<script>

export default {
    name: 'orderaction',
    components: {},
    props:['id'],
    methods: {
        doShow:function () {
            //console.log("emit onShow");
            this.$emit('onShow', this.id);
        },
        doModify:function () {
            //console.log("emit onModify");
            this.$emit('onModify', this.id);
        },
        doCancel:function () {
            //console.log("emit onCancel");
            this.$emit('onCancel', this.id);
        },
        doCreateBotJobPriceImprove:function () {
            this.$emit('onCreateBotJobPriceImprove', this.id);
        }
    }
}
</script>

<style scoped>

    .action_btn {
        margin-right: 5px;
    }
</style>
