<template>
    <div>
        <template v-for="(item, index) in actionData" >
            <Button size="small"
                    class="modify"
                    v-bind:key="index"
                    v-bind:disabled="item.disabled"
                    v-on:click="doClick(item.name)">
                {{ item.name }}
            </Button>
        </template>
    </div>
</template>

<script>

export default {
    name: 'tableaction',
    components: {},
    props:['id', 'actionData'],
    methods: {
        doClick:function (name) {
            console.log("tableAction emit:", this.id, name);
            this.$emit('onClick', this.id, name);
        },
    }
}
</script>

<style scoped>

.modify {
    margin-right: 5px;
}
</style>
