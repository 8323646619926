<template>
    <div>
        <span style="font-size: 1.3em; font-weight: bold">期权分析器</span>
        <Divider size="small" style="margin: 12px 0 12px 0 "></Divider>

        <table>
            <tr>
                <td style="vertical-align: top">
                    <span style="margin-right: 5px">期权合约:</span>
                </td>
                <td>
                    <ContractListEditor style="margin-top: -4px"
                                        ref="contractListEditor"
                                        v-model="contractIdList"
                                        v-bind:hide_add_btn="true"
                                        v-bind:lock_contract_type="constants.SecType.OPT">
                    </ContractListEditor>
                    <div>
                        <Button size="small" class="vt_compact_btn" v-on:click="onOpenAddContractSelector" >
                            添加合约
                        </Button>

                        <Button size="small" class="vt_compact_btn" v-on:click="getAnalyzeData"
                                v-bind:disabled="contractIdList.length == 0">
                            刷新数据
                        </Button>
                    </div>
                </td>
            </tr>
        </table>

        <div v-if="requestingAnalyzeData===true"
            style="width: 100%; text-align: center">
            <Spin size="small" style="display: inline-block"></Spin> 正在获取分析数据
        </div>

        <Divider orientation="left" size="small">期权数据</Divider>

        <div style="text-align: center; margin-bottom: 10px;margin-top: -5px" v-if="optionAnalyzeData.option_data.length > 0">
            <RadioGroup v-model="selectedContractId" type="button" size="small">
                <Radio v-for="option_data_item in optionAnalyzeData.option_data"
                       v-bind:key="option_data_item.option_contract.id"
                       v-bind:label="option_data_item.option_contract.id"

                > {{ util.get_option_short_name(option_data_item.option_contract,{include_symbol:false}) }} </Radio>

            </RadioGroup>
        </div>

        <table class="info_table" >
            <tr>
                <td>
                    <span class="table_tag">期权报价:</span>
                    <template v-if="selectedContractData != null">
                        <PriceTag v-bind:price="selectedContractData.option_mkt_data">
                        </PriceTag>
                    </template>
                </td>
                <td>
                    <span class="table_tag">基础合约报价:</span>
                    <PriceTag v-bind:price="optionAnalyzeData.base_contract_mkt_data">
                    </PriceTag>
                </td>
            </tr>
        </table>

        <table class="info_table" >
            <tr>
                <td><span class="table_tag">基础合约价格:</span>{{selectedContractData.option_attr.assert_price}}</td>
                <td><span class="table_tag">期权价格:</span>{{selectedContractData.option_attr.option_price}}</td>
                <td><span class="table_tag">行权价:</span>{{selectedContractData.option_attr.strike}}
                    <template v-if="selectedContractData.option_attr.strike != null && selectedContractData.option_attr.assert_price != null">
                        <template v-if="selectedContractData.option_attr.strike >= selectedContractData.option_attr.assert_price">
                            (+{{  util.roundToNDecimal((selectedContractData.option_attr.strike/selectedContractData.option_attr.assert_price - 1) * 100, 2)}}%)
                        </template>
                        <template v-else>
                            (-{{  util.roundToNDecimal((1 - selectedContractData.option_attr.strike/selectedContractData.option_attr.assert_price) * 100, 2)}}%)
                        </template>

                    </template>
                </td>
                <td><span class="table_tag">波动率(IV):</span>{{util.roundToNDecimal(selectedContractData.option_attr.sigma, 3)}}</td>
            </tr>
            <tr >
                <td><span class="table_tag">delta:</span>{{util.roundToNDecimal(selectedContractData.option_attr.delta,  3)}}</td>
                <td><span class="table_tag">gamma:</span>{{util.roundToNDecimal(selectedContractData.option_attr.gamma,  3)}}</td>
                <td><span class="table_tag">vega:</span>{{util.roundToNDecimal(selectedContractData.option_attr.vega,  3)}}</td>
                <td><span class="table_tag">theta:</span>{{util.roundToNDecimal(selectedContractData.option_attr.theta,  3)}}</td>
            </tr>
            <tr >
                <td><span class="table_tag">剩余时间(天):</span>{{util.roundToNDecimal(selectedContractData.option_attr.time_in_days,  3)}}</td>
                <td><span class="table_tag">今日剩余(比例):</span>{{util.roundToNDecimal(selectedContractData.option_attr.today_remain,  3)}}</td>
                <td><span class="table_tag">今日剩余时间价值:</span>{{util.roundToNDecimal(selectedContractData.option_attr.today_remain_time_value,  3)}}</td>
                <td><span class="table_tag">无风险利率:</span>{{util.roundToNDecimal(selectedContractData.option_attr.risk_free_rate,  3)}}</td>
            </tr>
        </table>

        <Divider orientation="left" size="small">变化趋势</Divider>

        <div style="margin-bottom: 5px">
            期权对齐到原点
            <i-Switch size="small" v-model="optionTrendFitToZero"/>
        </div>

        <table width="100%">
            <tr>
                <th style="width: 50%"> 期权价格（标的不变）:</th>
                <th>标的价格（期权不变）:</th>
            </tr>
            <tr>
                <th>
                    <Canvas class="grid_container" ref="trend_option_price" >
                        {{JSON.stringify(optionAnalyzeData.option_price_trend,null, 2)}}
                    </Canvas>
                </th>
                <th>
                    <Canvas class="grid_container" ref="trend_assert_price">
                        {{JSON.stringify(optionAnalyzeData.option_price_trend,null, 2)}}
                    </Canvas>
                </th>
            </tr>
        </table>

        <!--
        <hr>
        {{JSON.stringify(optionAnalyzeData)}}
        -->

    </div>
</template>

<script>

import Chart from 'chart.js/auto';

import ContractSelector from "@/components/common/editor/ContractSelector.vue";
import ContractListEditor from "@/components/common/editor/ContractListEditor.vue";
import vtradeAdapter from "@/adapter/vtradeAdapter";
import PriceTag from "@/components/common/PriceTag.vue";
import util from "@/util";
import constants from "@/constants";

/* 工作流程是把用户选择的合约id，存储在 contractIdList
   点击刷新数据按钮时，获取服务端分析结果到 optionAnalyzeData，驱动绘制图表
* */

export default {
    name: "OptionAnalyzer",
    components:{PriceTag, ContractSelector,ContractListEditor},
    props:{
        optionContractIdList:{
            type: Array, //预设的 optionContractIdList
            default: null,
            required: false
        }
    },
    data:function (){
        return {
            util,
            constants,
            optionTrendFitToZero:false, //显示配置: 期权价格是否对齐到原点
            contractIdList:[],
            selectedContractId:null, // 详情 tab 中当前选中的期权的 id
            requestingAnalyzeData:false, // 是否正在请求数据
            optionAnalyzeData:{ //服务端返回的期权分析结果，写入到这里，自动触发图表渲染
                option_contract:{},
                base_contract:{},
                option_data:[],
            },
            optionTrendChart:null,
            assertTrendChart:null,
        }
    },
    mounted() {
        console.log(`OptionAnalyzer mounted: optionContractIdList=${ JSON.stringify(this.optionContractIdList) }`);

        this.contractIdList = this.optionContractIdList;
        if(this.contractIdList != null){
            this.getAnalyzeData();
        }

        //注入 demo 数据
        /*
        this.optionAnalyzeData = {
            "base_contract": {
                "addition_info": {
                    "FromBroker": "IB",
                    "IBBrokerContractId": 4815747
                },
                "base_contract_id": null,
                "combo_legs": null,
                "created_at": "2024-05-04 01:41:18.536000",
                "currency": "USD",
                "id": 194,
                "last_trade_date": null,
                "local_symbol": "NVDA",
                "market": "US",
                "name": null,
                "option_multiplier": null,
                "option_right": null,
                "option_strike": null,
                "primary_exchange": "NASDAQ",
                "sec_type": "STK",
                "symbol": "NVDA",
                "updated_at": "2024-05-04 01:41:18.536000"
            },
            "base_contract_mkt_data": {
                "ask_price": 886.45,
                "ask_size": 500,
                "bid_price": 886.28,
                "bid_size": 200,
                "close_price": 858.17,
                "halted": true,
                "last_price": 886.22,
                "last_size": 100,
                "last_timestamp": "2024-05-04 07:59:50.000000",
                "market_data_type": "Frozen",
                "open_price": null,
                "tick_size": 0.01,
                "volume": 39834900
            },
            "option_data": [
                {
                    "assert_price_trend": [
                        886.3917447488669,
                        891.2172764975694,
                        896.160410738181,
                        901.2289018016422,
                        906.4314489623303,
                        911.7778727698321,
                        917.2793370985157,
                        922.9486324816821,
                        928.8005430131366,
                        934.8523293964965,
                        941.1243769168889,
                        947.6410832978755,
                        954.432105057729,
                        961.5341560892119,
                        968.9936854019301,
                        976.8710017295072,
                        985.2536708953867,
                        994.2330900726362,
                        1003.9861891163786,
                        1014.6864705250015,
                        1024.075
                    ],
                    "option_attr": {
                        "assert_price": 886.365,
                        "delta": 0.2775839122183098,
                        "gamma": 0.002190116873929805,
                        "intrinsic_value": 0,
                        "option_price": 24.075,
                        "option_right": "CALL",
                        "risk_free_rate": 0.05,
                        "sigma": 0.6236543502724275,
                        "strike": 1000,
                        "theta": -0.9471702339602663,
                        "time": 0.0766614593731542,
                        "time_in_days": 20,
                        "time_value": 24.075,
                        "today_remain": 1,
                        "today_remain_time_value": -1.333138473171747,
                        "vega": 0.8226470169098147
                    },
                    "option_contract": {
                        "addition_info": {
                            "FromBroker": "IB",
                            "IBBrokerContractId": 696728403
                        },
                        "base_contract_id": 194,
                        "combo_legs": null,
                        "created_at": "2024-05-04 01:41:27.731000",
                        "currency": "USD",
                        "id": 202,
                        "last_trade_date": "2024-05-31",
                        "local_symbol": "NVDA  240531C01000000",
                        "market": "US",
                        "name": null,
                        "option_multiplier": 100,
                        "option_right": "CALL",
                        "option_strike": 1000,
                        "primary_exchange": null,
                        "sec_type": "OPT",
                        "symbol": "NVDA",
                        "updated_at": "2024-05-04 01:41:27.731000"
                    },
                    "option_mkt_data": {
                        "ask_price": 24.4,
                        "ask_size": 100,
                        "bid_price": 23.75,
                        "bid_size": 63,
                        "close_price": 18.48,
                        "halted": true,
                        "last_price": 24,
                        "last_size": 1,
                        "last_timestamp": "2024-05-04 03:57:02.000000",
                        "market_data_type": "Frozen",
                        "open_price": null,
                        "tick_size": 0.01,
                        "volume": 58400
                    },
                    "option_price_trend": [
                        24.075000000000003,
                        22.741861526828252,
                        21.392678489446645,
                        20.027411234632606,
                        18.646177021202327,
                        17.24931396742698,
                        15.837471185462402,
                        14.411737357477882,
                        12.973826702530928,
                        11.52635223931101,
                        10.073234593946088,
                        8.620326043927086,
                        7.176384607149878,
                        5.7546313374187354,
                        4.3752999945179205,
                        3.0698898141899327,
                        1.888240056273509,
                        0.9093197561510094,
                        0.24964932938387732,
                        0.009060786704321073,
                        0
                    ],
                    "time_point_days": [
                        20,
                        19,
                        18,
                        17,
                        16,
                        15,
                        14,
                        13,
                        12,
                        11,
                        10,
                        9,
                        8,
                        7,
                        6,
                        5,
                        4,
                        3,
                        2,
                        1,
                        0
                    ],
                    "time_point_labels": [
                        0,
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20
                    ]
                },
                {
                    "assert_price_trend": [
                        886.3650098880311,
                        892.6099865786609,
                        899.0141093380587,
                        905.6439179637791,
                        912.521924089904,
                        919.6749202226217,
                        927.1352691452057,
                        934.9427543128478,
                        943.1473265621657,
                        951.8133501002903,
                        961.0264987277841,
                        970.9056606467922,
                        981.6465880323553,
                        993.4596922451982,
                        1006.8699925961562,
                        1020.375
                    ],
                    "option_attr": {
                        "assert_price": 886.365,
                        "delta": 0.25706718085668756,
                        "gamma": 0.002263944674631314,
                        "intrinsic_value": 0,
                        "option_price": 20.375,
                        "option_right": "CALL",
                        "risk_free_rate": 0.05,
                        "sigma": 0.6701702702298507,
                        "strike": 1000,
                        "theta": -1.122727165418005,
                        "time": 0.057496094529865656,
                        "time_in_days": 15,
                        "time_value": 20.375,
                        "today_remain": 1,
                        "today_remain_time_value": -1.5814867749486297,
                        "vega": 0.6853533039203228
                    },
                    "option_contract": {
                        "addition_info": {
                            "FromBroker": "IB",
                            "IBBrokerContractId": 695540624
                        },
                        "base_contract_id": 194,
                        "combo_legs": null,
                        "created_at": "2024-05-05 09:12:59.505000",
                        "currency": "USD",
                        "id": 233,
                        "last_trade_date": "2024-05-24",
                        "local_symbol": "NVDA  240524C01000000",
                        "market": "US",
                        "name": null,
                        "option_multiplier": 100,
                        "option_right": "CALL",
                        "option_strike": 1000,
                        "primary_exchange": null,
                        "sec_type": "OPT",
                        "symbol": "NVDA",
                        "updated_at": "2024-05-05 09:12:59.505000"
                    },
                    "option_mkt_data": {
                        "ask_price": 20.75,
                        "ask_size": 136,
                        "bid_price": 20,
                        "bid_size": 251,
                        "close_price": 15.55,
                        "halted": true,
                        "last_price": null,
                        "last_size": null,
                        "last_timestamp": null,
                        "market_data_type": "Frozen",
                        "open_price": null,
                        "tick_size": 0.01,
                        "volume": 263300
                    },
                    "option_price_trend": [
                        20.375000000000004,
                        18.79351322505137,
                        17.190947812105737,
                        15.568239336725226,
                        13.927102451883568,
                        12.270425065544154,
                        10.60288582487175,
                        8.931945682377858,
                        7.269485700918566,
                        5.63459780389539,
                        4.058493808096869,
                        2.5933541263976028,
                        1.328055158155209,
                        0.41048380934492723,
                        0.020961832650884405,
                        0
                    ],
                    "time_point_days": [
                        15,
                        14,
                        13,
                        12,
                        11,
                        10,
                        9,
                        8,
                        7,
                        6,
                        5,
                        4,
                        3,
                        2,
                        1,
                        0
                    ],
                    "time_point_labels": [
                        0,
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15
                    ]
                },
                {
                    "assert_price_trend": [
                        886.3654112760303,
                        891.4262547501191,
                        896.6290117330387,
                        901.9851148430928,
                        907.5077226081527,
                        913.2121132905659,
                        919.1161980581953,
                        925.259115201972,
                        931.6189859479803,
                        938.2622144064931,
                        945.2238779798964,
                        952.5466094552513,
                        960.2826411765303,
                        968.4672215759701,
                        976.9961075067476,
                        983.05
                    ],
                    "option_attr": {
                        "assert_price": 886.365,
                        "delta": 0.3689760052154571,
                        "gamma": 0.0026556516216307002,
                        "intrinsic_value": 0,
                        "option_price": 33.05,
                        "option_right": "CALL",
                        "risk_free_rate": 0.05,
                        "sigma": 0.6683452266362951,
                        "strike": 950,
                        "theta": -1.3169332930170083,
                        "time": 0.057496094529865656,
                        "time_in_days": 15,
                        "time_value": 33.05,
                        "today_remain": 1,
                        "today_remain_time_value": -1.8672205965541089,
                        "vega": 0.8017435891487267
                    },
                    "option_contract": {
                        "addition_info": {
                            "FromBroker": "IB",
                            "IBBrokerContractId": 695068879
                        },
                        "base_contract_id": 194,
                        "combo_legs": null,
                        "created_at": "2024-05-05 15:59:40.891000",
                        "currency": "USD",
                        "id": 234,
                        "last_trade_date": "2024-05-24",
                        "local_symbol": "NVDA  240524C00950000",
                        "market": "US",
                        "name": null,
                        "option_multiplier": 100,
                        "option_right": "CALL",
                        "option_strike": 950,
                        "primary_exchange": null,
                        "sec_type": "OPT",
                        "symbol": "NVDA",
                        "updated_at": "2024-05-05 15:59:40.891000"
                    },
                    "option_mkt_data": {
                        "ask_price": 33.45,
                        "ask_size": 47,
                        "bid_price": 32.65,
                        "bid_size": 99,
                        "close_price": 25.48,
                        "halted": true,
                        "last_price": null,
                        "last_size": null,
                        "last_timestamp": null,
                        "market_data_type": "Frozen",
                        "open_price": null,
                        "tick_size": 0.01,
                        "volume": 156700
                    },
                    "option_price_trend": [
                        33.050000000000004,
                        31.18277940344589,
                        29.263343166024782,
                        27.287160915456628,
                        25.24907453786909,
                        23.143202763077756,
                        20.962851788844773,
                        18.700467008289394,
                        16.34771624444662,
                        13.8959399728403,
                        11.337611749122408,
                        8.67071610173547,
                        5.9124404105777275,
                        3.1478477103515883,
                        0.7448823303114657,
                        0
                    ],
                    "time_point_days": [
                        15,
                        14,
                        13,
                        12,
                        11,
                        10,
                        9,
                        8,
                        7,
                        6,
                        5,
                        4,
                        3,
                        2,
                        1,
                        0
                    ],
                    "time_point_labels": [
                        0,
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15
                    ]
                }
            ]
        };


         */

    },
    methods:{
        onOpenAddContractSelector:function (){
            console.log(`onOpenAddContractSelector`);
            this.$refs.contractListEditor.openAddContractSelector();
        },
        getAnalyzeData:async function (){
            console.log(`getAnalyzeData, contractIdList=${this.contractIdList}`);

            const data = {
                option_contract_ids:JSON.stringify(this.contractIdList),
                with_option_price_trend:true,
                with_assert_price_trend:true,
            };

            this.requestingAnalyzeData = true;

            try {
                const ret = await vtradeAdapter.getOptionListAnalyzerData(data);
                this.optionAnalyzeData = ret;
                console.log(`optionAnalyzeData:${JSON.stringify(ret)}`);

                //自动选中第一个期权
                this.selectedContractId = ret.option_data[0].option_contract.id;

            }catch (e){
                console.log(`requestingAnalyzeData failed`,  e);
            }

            this.requestingAnalyzeData = false;
        },
    },
    computed:{
        selectedContractData: function (){

            const selectedContractId = this.selectedContractId;

            for(let idx in this.optionAnalyzeData.option_data){
                const option_data_item = this.optionAnalyzeData.option_data[idx];
                if(selectedContractId === option_data_item.option_contract.id ){
                    return option_data_item;
                }

            }

            return {'option_attr':{}};
        },
        chartDataSet:function(){
            console.log("recompute chartDataSet");

            // 用于渲染图表的数据。由 optionAnalyzeData 和显示配置（optionTrendFitToZero）计算得到
            /* ret = {
                optionTrendDataSet:[],
                assertTrendDataSet:[],
            }; */

            const optionTrendDataSet = [];
            const assertTrendDataSet = [];

            for(let idx in this.optionAnalyzeData.option_data){
                const option_data_item = this.optionAnalyzeData.option_data[idx];
                const short_name = util.get_option_short_name(option_data_item.option_contract,{include_symbol:false});

                //处理小数位数过长
                if(option_data_item.time_point_days != null && option_data_item.time_point_days.length > 0){
                    option_data_item.time_point_days[0] = util.roundToNDecimal(option_data_item.time_point_days[0], 2);
                    option_data_item.time_point_labels[0] = util.roundToNDecimal(option_data_item.time_point_labels[0], 2);
                }

                console.log(`${short_name} option_data_item.time_point_day`,option_data_item.time_point_days);
                console.log(`${short_name} option_data_item.time_point_labels`,option_data_item.time_point_labels);

                const pointsOptionTrend = [];
                const pointsAssertTrend = [];

                let offset = 0;
                if(this.optionTrendFitToZero === true){
                    //期权当前价格对齐到原点
                    offset = option_data_item.option_price_trend[0];
                }

                for(let idx in option_data_item.time_point_labels){
                    //console.log("idx",idx);
                    const x = option_data_item.time_point_labels[idx];
                    const y1 = option_data_item.option_price_trend[idx] - offset;
                    pointsOptionTrend.push({x, y:y1});

                    const y2 = option_data_item.assert_price_trend[idx];
                    pointsAssertTrend.push({x, y:y2});
                }

                console.log(`${short_name}: dataOptionTrend`,pointsOptionTrend);

                optionTrendDataSet.push({
                    label: short_name,
                    data: pointsOptionTrend
                });

                assertTrendDataSet.push({
                    label: `assert of ${short_name}`,
                    data: pointsAssertTrend,
                });

            }

            return {
                optionTrendDataSet: optionTrendDataSet,
                assertTrendDataSet: assertTrendDataSet,
            };
        },
    },
    watch:{
        contractIdList:function (){
            //合约变化后，清空上一次的分析结果
            //this.optionAnalyzeData = {option_attr:{}};
            //this.getAnalyzeData();
        },
        chartDataSet:function (){
            //数据变动情况下，重新绘制图表
            console.log("chartDataSet changed, redraw chart");

            if(this.optionTrendChart != null){
                this.optionTrendChart.destroy();
            }

            if(this.assertTrendChart != null){
                this.assertTrendChart.destroy();
            }

            const chartOptions = {
                parsing: {
                    xAxisKey: 'x',
                    yAxisKey: 'y'
                },
                scales: {
                    x: {
                        type: 'linear',
                    }
                }
            };

            // 绘制图表
            this.optionTrendChart = new Chart(this.$refs.trend_option_price, {
                type: 'line',
                data: {
                    datasets: this.chartDataSet.optionTrendDataSet,
                },
                options: chartOptions,
            });

            this.assertTrendChart = new Chart(this.$refs.trend_assert_price, {
                type: 'line',
                data: {
                    datasets: this.chartDataSet.assertTrendDataSet,
                },
                options: chartOptions,
            });

        }
    },
    beforeDestroy() {
        console.log("OptionAnalyzer beforeDestroy");
    },
}
</script>

<style scoped>

.info_table {
    width:100%;
}

.info_table td {
}

.grid_container {
    width: 350px;
}

.table_tag {
    color: gray;
    margin-right: 5px;
}

</style>