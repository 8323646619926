<template>
    <div class="container" ref="container">
        <Tag color="primary" v-if="data===true" >{{data}}</Tag>
        <Tag color="default" v-else >{{data}}</Tag>
    </div>
</template>

<script>

export default {
    name: 'empty',
    props:{
        data:{// 输入数据
            type: Boolean,
            default:null,
            required:false
        },
    },
    components: {
    }
}

</script>

<style scoped>
    .container {
        padding: 0;
        display: inline-block;
    }

</style>
