<template>
    <div>
        <nav class="navbar navbar-default">
            <div class="container-fluid" id="nav-container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <div class="navbar-brand" v-on:click="go_home">
                        <strong>VTrade WebUI</strong>
                    </div>

                    <Poptip class="statusTagContainer" title="系统状态" content="content" placement="bottom" trigger="hover" >
                            <Tag v-bind:color="statusLevel" class="statusTag" v-if="true">{{statusText}}</Tag>
                            <div class="api" slot="content">
                                <BackendStatusView></BackendStatusView>
                            </div>
                    </Poptip>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                    <ul class="nav navbar-nav navbar-right">
                        <li>
                            <a  class="nav_item">
                                <Poptip title="工具入口" content="content" placement="bottom" trigger="hover" >
                                    <a-icon type="appstore"/>
                                    <div slot="content">
                                        <Button size="small" v-on:click="open_option_analyzer_view">期权分析器</Button>
                                    </div>
                                </Poptip>
                            </a>
                        </li>
                        <li v-bind:class="{ active: nav_tab == 'dashboard' }"><a v-on:click="url_change('/dashboard')" class="nav_item">控制台</a></li>
                        <li v-bind:class="{ active: nav_tab == 'analyze' }"><a v-on:click="url_change('/analyze/marketData')" class="nav_item">服务</a></li>
                        <li v-bind:class="{ active: nav_tab == 'config' }"><a v-on:click="url_change('/config/profile')" class="nav_item">配置</a></li>

                        <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                <template v-if="$store.state.currentAccount == null">
                                    请选择账户
                                </template>
                                <template v-else>
                                    {{$store.state.currentAccount}}
                                </template>
                                <span class="caret"></span>
                            </a>
                            <ul class="dropdown-menu">
                                <li v-for="item in $store.state.accountList" v-bind:key="item.account">
                                    <a v-on:click="change_account(item.account)">{{item.account}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div><!-- /.navbar-collapse -->
            </div><!-- /.container-fluid -->
        </nav>
    </div>
</template>
<script>

import BackendStatusView from "./common/display/BackendStatusView";


export default {
    name: "topnav",
    components: {BackendStatusView},
    props:['nav_tab'],
    comments:[BackendStatusView],
    data:function () {
        return {
        };
    },
    methods:{
        url_change : function( new_url ){
            if( this.$router.currentRoute.fullPath == new_url){
                return;
            }

            console.log('url_change',this.$router.currentRoute.fullPath, new_url);
            this.$router.push(new_url);
        },
        go_home : function(){
            console.log("vue instance:",this);
            this.url_change('/dashboard');
        },
        change_account: function (account) {
            this.$store.commit('updateCurrentAccount', account);
        },
        open_option_analyzer_view:function (){
            if(this.$store.state.optionAnalyzerViewId == null){
                this.$store.commit('updateOptionAnalyzerViewId', true);
            }
        }
    },
    computed:{
        "statusInfo":function () {
            var eventWebScoketStatus = this.$store.state.serverMessageBusWsStatus;
            if( eventWebScoketStatus == "disconnected") {
                return ["offline", "error"];
            }else if(eventWebScoketStatus == "connecting"){
                return ["connecting","error"];
            }

            if( this.$store.state.serverMarketDataWsStatus != "connected"){
                return ["Limited", "warning"]
            }

            if( this.$store.state.backendStatus['all_fine'] != true){
                return ["Limited", "warning"]
            }

            return ["Ready", "success"];
        },
        "statusText":function () {
            return this.statusInfo[0]
        },
        "statusLevel":function () {
            return this.statusInfo[1];
        },
    }
};

</script>

<style scoped src="bootstrap/dist/css/bootstrap.css" />

<style scoped>

    #nav-container {
        margin-left: 50px;
        margin-right: 50px;
        min-width: 800px;
    }

    .navbar-default {
        margin-bottom: 0px;
    }

    .nav_item {
        font-size: 1.3em;
    }

    .navbar-brand :hover{
        cursor:pointer
    }

    .statusTagContainer{
        margin-top: 13px;
        float: left;
    }

    .statusTag{
        margin:auto;

        height: 16px !important;
        line-height: 16px !important;
    }

    >>> .statusTag * {
        font-weight: bold;
        height: 16px !important;
        line-height: 16px !important;
    }

</style>

