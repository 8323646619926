<template>
    <div class="dashboard">
        <Layout>
            <Sider hide-trigger v-bind:style="{background: '#fff', 'min-width':'240px'}">
                <Menu  active-name="profile" v-on:on-select="menu_select">
                    <MenuGroup title="全局配置">
                        <MenuItem name="profile">
                            <Icon type="md-chatbubbles" />
                            配置文件
                        </MenuItem>
                    </MenuGroup>
                    <MenuGroup title="服务器配置">
                        <MenuItem name="connect">
                            <Icon type="md-chatbubbles" />
                            连接参数
                        </MenuItem>
                    </MenuGroup>
                </Menu>
            </Sider>
            <Layout v-bind:style="{padding: '0 24px 24px', background: '#fff'}">
                <Content >
                    <router-view ></router-view>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>

import ConfigProfile from "./config/Profile";
import ConfigConnect from "./config/Connect";

export default {
    name: 'dashboard',
    components: {
        ConfigProfile,
        ConfigConnect
    },
    methods:{
        url_change(value){
            this.$root.url_change(value);
        },
        menu_select(value){
            console.log("menu_select:", value);
            var url = "/config/" + value;
            this.url_change(url);
        }
    }
}

</script>

<style scoped>


</style>
