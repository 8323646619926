<template>
        <div style="display: inline-block">
            <Tag color="default" v-if="value==null || value.length == 0">NULL</Tag>

            <div  v-for="(contractId, idx) in value" v-bind:key="contractId" class="ContractSymbolContainer">
                <ContractSymbol v-on:onClose="removeContractId" closable
                                v-bind:contract_id="contractId"
                >
                </ContractSymbol>
                <template v-if="idx != value.length-1">, </template>
            </div>

            <ContractSelector ref="contractSelector"
                              v-bind:lock_contract_type="lock_contract_type"
                              v-bind:contract_id.sync="selectorContractId"
                              v-bind:hide_symbol="true"
                              v-bind:hide_entry_btn="hide_add_btn"
                              v-bind:entry_btn_text="'添加'"
            ></ContractSelector>
        </div>
</template>

<script>

import ContractSelector from "@/components/common/editor/ContractSelector.vue";
import ContractSymbol from "@/components/common/display/ContractSymbol.vue";

export default {
    name: "ContractListEditor",
    components: {
        ContractSelector,
        ContractSymbol
    },
    props:{
        value:{
            type: Array,
            default: null,
            required: false
        },
        lock_contract_type:{
            //从外部指定允许选择的合约类型，指定了之后，无法从 ui 上修改
            // 需要为 Constants.SecType.STK 或者 Constants.SecType.OPT
            type:String,
            default: null,
            require: false,
        },
        hide_add_btn:{
            //隐藏添加按钮
            type: Boolean,
            default: false,
            required: false
        }
    },
    data:function (){
        return {
            selectorContractId:null,
        }
    },
    mounted() {
        console.log('ContractListEditor mounted');
    },
    methods:{
        openAddContractSelector:function (){
            //打开添加合约的选择器，提供给外部调用
            console.log("contractListEditor openSelector");
            this.$refs.contractSelector.on_open_editor();
        },
        removeContractId:function (contract_id){
            console.log(`removeContractId: contract_id=${contract_id}`);

            const newValue = [...this.value];
            newValue.splice(newValue.indexOf(contract_id), 1);

            this.$emit('input', newValue);
        }
    },
    watch:{
        selectorContractId:function (newValue){
            if(newValue != null){
                //选择合约之后，如果在列表中不存在，那么添加到列表
                if(this.value != null && this.value.indexOf(newValue) < 0){
                    this.value.push(newValue);
                }
                this.selectorContractId = null; //清除编辑器的值
            }
        }
    },
    beforeDestroy() {
        console.log('ContractListEditor beforeDestroy');
    }
}
</script>

<style scoped>
    .ContractSymbolContainer {
        display: inline-block;
        margin-right: 5px;
    }
</style>