<template>
    <div>
        <div v-if="loading">
            <spin></spin>
        </div>

        <Form v-if="loading==false" v-bind:label-width="90"  style="max-width: 450px">
            <FormItem label="id" v-if="mode != 'create'">
                <InputNumber size="small" v-model="form_data.id"
                             v-bind:disabled="true"
                             v-bind:min="0"
                             v-bind:step="1"
                             style="width: 100%">
                </InputNumber>
            </FormItem>

            <FormItem label="业务标识">
                <Input v-model="form_data.biz_id" size="small"></Input>
            </FormItem>

            <FormItem label="标题">
                <Input v-model="form_data.title" size="small"></Input>
            </FormItem>

            <FormItem label="消息">
                <Input v-model="form_data.message" size="small"></Input>
            </FormItem>

            <FormItem label="级别">
                <RadioGroup size="small" v-model="form_data.level" type="button">
                    <Radio v-bind:label="constants.NoticeLevel.Slight"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeLevel.Slight">
                        <span>Slight</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Normal"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeLevel.Normal">
                        <span>Normal</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Important"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeLevel.Important">
                        <span>Important</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Emergency"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeLevel.Emergency">
                        <span>Emergency</span>
                    </Radio>
                </RadioGroup>
            </FormItem>

            <FormItem label="状态">
                <RadioGroup size="small" v-model="form_data.status" type="button">
                    <Radio v-bind:label="constants.NoticeStatus.Init"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeStatus.Init">
                        <span>Init</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeStatus.Sent"
                         v-bind:disabled="disabled && form_data.level != constants.NoticeStatus.Processing">
                    <span>Sent</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeStatus.Processing"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeStatus.Processing">
                        <span>Processing</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeStatus.Confirmed"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeStatus.Confirmed">
                        <span>Confirmed</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeStatus.Canceled"
                           v-bind:disabled="disabled && form_data.level != constants.NoticeStatus.Canceled">
                        <span>Canceled</span>
                    </Radio>
                </RadioGroup>
            </FormItem>

            <FormItem label="已触发次数" v-if="mode!='create'">
                <InputNumber size="small" v-model="form_data.count"
                             v-bind:min="0"
                             v-bind:step="1"
                             style="width: 100%">
                </InputNumber>
            </FormItem>

        </Form>

        <Divider style="margin: 10px 0 10px 0 "></Divider>

        <div style="text-align:right">
            <!-- 点击关闭模态框 -->
            <Button v-on:click="on_cancel"
            >Cancel</Button>
            <Button type="primary"
                    v-bind:disabled="canSubmit == false"
                    v-bind:loading="submitting"
                    v-on:click="on_model_submit"
                    style="margin-left: 8px">

                Confirm
            </Button>
        </div>

        <!--
        workmode:{{mode}}
        <hr>
        from:{{form_data}}
        <hr>
        value:{{value}}
        -->
    </div>
</template>

<script>

    import ContractSymbol from "../display/ContractSymbol";
    import constants from '../../../constants.js';
    import util from "../../../util";
    import _ from 'underscore';
    import vtradeAdapter from "@/adapter/vtradeAdapter";

    function getDefaultValue() {
        return {//编辑中的合约对象
            biz_id:null,
            title:null,
            message:null,
            level:"Slight",
            status:"Init",
            count:0
        }
    }

    export default {
        name: 'NoticeEditor',
        props:{
            value:{// notice 对象 id
                type:Number,
                default:null,
                required:false
            },
            disabled:{ //是否仅展示合约，禁用编辑
                type:Boolean,
                default: false,
                require:false
            },
            mode:{
                type: String,
                require: true
                //编辑器工作模式
                // create:创建, id/count 字段隐藏
                // modify:修改订单，id 字段只读
            }
        },
        components: {
            ContractSymbol
        },
        data:function () {
            return {
                constants,
                form_data: getDefaultValue(),//编辑中的合约对象
                loading:false, //是否正在查询对象
                submitting:false, //是否正在提交
            }
        },
        mounted: function() {
            console.log("NoticeEditor mounted");
        },
        methods:{
            on_model_submit:async function () {
                if(this.mode == 'create'){
                    console.log("NoticeEditor submit create model, model value=", this.form_data);
                    this.submitting = true;
                    await vtradeAdapter.postNoticeCreate(this.form_data);
                    this.submitting = false;
                    console.log("postNoticeCreate success");
                    this.$Message.success('添加Notice成功');
                    this.$emit('onSubmit');
                }else if(this.mode == "modify"){
                    console.log("NoticeEditor submit modify model, model value=", this.form_data);
                    this.submitting = true;
                    await vtradeAdapter.postNoticeModify(this.form_data['id'], this.form_data);
                    this.submitting = false;
                    console.log("postNoticeModify success");
                    this.$Message.success('修改Notice成功');
                    this.$emit('onSubmit');
                }
            },
            on_cancel:  function (){
                this.$emit('onCancel');
            }
        },
        computed:{
            canSubmit:function () {
                if(this.form_data != null && this.form_data.level != null && this.form_data.status != null)
                    return true;

                return false;
            }
        },
        watch:{
            value:async function (newValue, oldValue) {
                //输入的 noticeId 变动时，查询数据填充到表单
                console.log(`NoticeEditor v-model value changed: ${newValue}`, newValue);
                this.form_data = getDefaultValue();
                if(this.value == null){
                    console.log("NoticeEditor mount with null, use default data update value");
                    return;
                }

                //获取 notice 填充到表单
                this.loading = true;
                const notice = await vtradeAdapter.getNotice(this.value);
                console.log(`notice: ${JSON.stringify(notice)}`)
                this.form_data = notice;
                this.loading = false;
            }
        }
    }

</script>

<style scoped src="@/style/compactForm.css" />
<style scoped>

    .selector_div {
        border: 1px solid;
        border-color: #2d8cee;
        border-radius:5px;
    }

</style>
