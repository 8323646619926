<template>
    <div>
        <div class="editor_main_title">
            任务编辑器
            <span class="editor_sub_title">{{subTitle}}</span>
        </div>

        <Divider style="margin: 12px 0 12px 0 "/>

        <!--
        <span>mode={{mode}}</span>
        <div>
            {{data}}
        </div>
        -->

        <Form v-bind:label-width="100"  style="max-width: 450px">
            <FormItem label="任务ID" v-if="editingData.id != null">
                {{editingData.id}}
            </FormItem>

            <FormItem label="任务类型">
                <RadioGroup size="small" v-model="editingData.type" type="button">
                    <Radio v-bind:label="constants.AlgoBotJobType.PriceImprove" v-bind:disabled="mode=='view'"
                    >
                        <span>PriceImprove</span>
                    </Radio>
                    <Radio v-bind:label="constants.AlgoBotJobType.PositionAdjust" v-bind:disabled="mode=='view'">
                        <span>PositionAdjust</span>
                    </Radio>
                </RadioGroup>
            </FormItem>

            <Divider orientation="left" size="small">配置</Divider>

            <FormItem label="账户">
                <Input size="small" v-model="editingData.config.account" disabled/>
            </FormItem>

            <FormItem label="前置条件">
                <DependsListEditor v-model="editingData.depends" v-bind:disabled="mode=='view'">
                </DependsListEditor>
            </FormItem>

            <!--  ToDo: 需要一个 order 控件，condition 控件，类似 contractSymbol  -->

            <!--配置信息： PriceImprove 任务类型-->
            <template v-if="editingData.type === constants.AlgoBotJobType.PriceImprove">
                <FormItem label="最长运行秒数">
                    <InputNumber size="small" v-model="editingData.config.max_exec_time"
                                 v-bind:min="3"
                                 v-bind:step="1"
                                 v-bind:disabled="mode=='view'"
                                 style="width: 100%">
                    </InputNumber>
                </FormItem>

                <FormItem label="允许价格范围">
                    <a-input-number size="small" v-model="editingData.config.min_price"
                                    v-bind:disabled="mode=='view'"
                                    v-bind:step="1">
                    </a-input-number>
                    <span> &lt;= price &lt;= </span>
                    <a-input-number size="small" v-model="editingData.config.max_price"
                                    v-bind:disabled="mode=='view'"
                                    v-bind:step="1">
                    </a-input-number>

                </FormItem>

                <FormItem label="关联订单">
                    <Input size="small" v-model="editingData.config.order_unique_id"
                           v-bind:disabled="mode=='view'"
                    />
                </FormItem>

            </template>

            <!--配置信息： PositionAdjust 任务类型-->
            <template v-else-if="editingData.type === constants.AlgoBotJobType.PositionAdjust">
                <FormItem label="合约">
                    <ContractSelector v-bind:contract_id.sync="editingData.config.contract_id"
                                      v-bind:disabled="mode=='view'">
                    </ContractSelector>
                </FormItem>
                <FormItem label="目标类型">
                    <RadioGroup size="small" v-model="editingData.config.adjust_type" type="button">
                        <Radio label="Absolute" v-bind:disabled="mode=='view'"
                        >
                            <span>绝对值</span>
                        </Radio>
                        <Radio label="Relative" v-bind:disabled="mode=='view'">
                            <span>变化值</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>

                <FormItem label="数量">
                    <a-input-number size="small" v-model="editingData.config.amount"
                                    v-bind:disabled="mode=='view'"
                                    v-bind:step="1">
                    </a-input-number>
                </FormItem>
            </template>

            <!-- 运行时信息 -->
            <Divider orientation="left" size="small">运行时信息</Divider>
            <template v-if="mode != 'create' && mode != 'modify'">
                <template v-if="editingData.type === constants.AlgoBotJobType.PriceImprove">
                    <FormItem label="开始时间">
                        {{editingData.exec_info.exec_start}}
                    </FormItem>
                    <FormItem label="步数">
                        {{editingData.exec_info.price_change_step}}
                    </FormItem>
                    <FormItem label="折扣系数">
                        {{editingData.exec_info.discount_radio_now}}
                        <template v-if="editingData.exec_info.discount_radio_modifying != null">
                            -> {{editingData.exec_info.discount_radio_modifying}}
                        </template>
                    </FormItem>
                    <FormItem label="价格">
                        {{editingData.exec_info.price_now}}
                        <template v-if="editingData.exec_info.price_modifying != null">
                            -> {{editingData.exec_info.price_modifying}}
                        </template>
                    </FormItem>
                </template>
                <template v-else-if="editingData.type === constants.AlgoBotJobType.PositionAdjust">

                </template>
            </template>

            <FormItem label="状态">
                <!--状态只能看不能修改-->
                <Select size="small" v-model="editingData.status" style="width:200px" v-bind:disabled="true">
                    <Option v-for="item in [constants.BotJobStatus.Init,
                                            constants.BotJobStatus.Pending,
                                            constants.BotJobStatus.Running,
                                            constants.BotJobStatus.WaitingSubJob,
                                            constants.BotJobStatus.Succeed,
                                            constants.BotJobStatus.Failed,
                                            constants.BotJobStatus.HandUp]"
                            v-bind:value="item" v-bind:key="item">{{ item }}</Option>
                </Select>
            </FormItem>

            <FormItem label="创建时间">
                {{editingData.created_at}}
            </FormItem>

            <FormItem label="更新时间">
                {{editingData.updated_at}}
            </FormItem>

            <FormItem label="失败信息" v-if="editingData.fail_msg != null">
                {{editingData.fail_msg}}
            </FormItem>

            <template v-if="mode == 'create' || mode == 'modify' ">

                <Divider size="small" style="margin: 12px 0"/>

                <ButtonGroup style="margin-left: 100px">
                    <Button type="primary"
                            ghost
                            size="small"
                            v-bind:loading="loading=='submit'"
                            v-on:click="doSubmit">
                        提交任务
                    </Button>
                    <Button v-if="mode=='view'"
                            type="error"
                            ghost
                            size="small"
                            v-bind:loading="loading=='cancel'"
                            v-on:click="doStop">
                        取消任务
                    </Button>
                </ButtonGroup>

                <Button v-if="mode != 'view'"
                        size="small"
                        style="float: right"
                        v-bind:disabled="loading!=null"
                        v-on:click="cancelEdit">
                    放弃编辑
                </Button>
            </template>

        </Form>
    </div>
</template>

<script>
    import constants from "../../../constants";
    import ContractSymbol from "../display/ContractSymbol";
    import ContractSelector from "./ContractSelector";
    import DependsListEditor from "./DependsListEditor";
    import util from "@/util";
    import vtradeAdapter from "@/adapter/vtradeAdapter";

    /*
    * 数据流动:
    *   view & modify 模式下:
    *       1，data(也就是jobId) 改变后，会自动更新到 editingData，驱动显示
    *       2，提交时，从 editingData 直接提交到服务器。服务器更新后同步到前端
    *
    *   create 模式下:
    *       1, 忽略输入 data(也就是jobId) 变动
    *       2, 跟踪 $store.state.currentAccount 到 editingData
    *       3, 提交时，从 editingData 直接提交到服务器
    * */

    export default {
        name: "BotJobEditor",
        components: {ContractSymbol,DependsListEditor,ContractSelector},
        props:{
            mode:{ //模式 view, create, modify
                type: String,
                default: "view",
                required:true
            },
            botJobId:{
                type: Number, //要编辑的 botJob ID
                default: null,
                required: false
            }
        },
        data:function () {
            return {
                constants:constants,
                loading:null, //是否正在 加载/提交
                editingData:{
                    config:{},
                    exec_info:{},
                }, //正在编辑的数据(和界面绑定)
            }
        },
        methods:{
            resetForm:function (){
                //根据输入(mode,data)来构建表单内容
                if(this.mode == "create"){
                    this.editingData = {
                        config:{
                            account:this.$store.state.currentAccount,
                        },
                        exec_info:{},
                        status: constants.AlgoBotJobStatus.Init,
                    }
                }else if(this.mode == "view" || this.mode == "modify"){
                    let botJobId = this.botJobId;
                    this.editingData = this.$store.state.botJobData[botJobId];
                }else {
                    console.error("unknown mode:",this.mode);
                }
            },
            doVerify:function () {
                //验证数据, 返回错误信息
                if(this.editingData.type == null){
                    return "job type must not null";
                }else if( this.editingData.type == constants.AlgoBotJobType.PriceImprove){
                    if(this.editingData.config.order_unique_id == null){
                        return "订单不能为空";
                    }
                }else if(this.editingData.type == constants.AlgoBotJobType.PositionAdjust){
                    if(this.editingData.config.adjust_type == null){
                        return "adjust_type 不能为空";
                    }

                    if(this.editingData.config.amount == null){
                        return "amount 不能为空";
                    }
                }
            },
            doSubmit:function () {
                let that = this;
                let errMsg = this.doVerify();
                if( errMsg != null){
                    console.log(`verify failed, ignore, errMsg=${errMsg}`);
                    util.show_notice("warning","表单验证失败: " + errMsg);
                    return;
                }

                if(this.mode === "create"){
                    util.show_confirm( //弹出确认
                        '创建 BotJob',
                        null,
                        function(){
                            console.log("confirm 创建 botJob");

                            vtradeAdapter.postCreateBotJob({
                                    account:that.editingData.account,
                                    type:that.editingData.type,
                                    config:JSON.stringify(that.editingData.config),
                                    status:that.editingData.status,
                                    depends:JSON.stringify(that.editingData.depends),
                                }, function () {
                                    console.log("创建 botJob success");
                                    that.$Message.success('create botJob success');
                                    that.$emit('onSubmit');
                                }
                            );
                        },
                    );
                }else if(this.mode === "modify"){
                    util.show_confirm( //弹出确认
                        '修改 BotJob',
                        null,
                        function(){
                            console.log("confirm 修改 botJob");

                            vtradeAdapter.postModifyBotJob(that.editingData.id,that.editingData, function () {
                                    console.log("修改 botJob success");
                                    that.$Message.success('modify botJob success');
                                    that.$emit('onSubmit');
                                }
                            );
                        },
                    );
                }
            },
            doStop:function () {
                let that = this;
                let jobId = this.editingData.id;
                util.show_confirm( //弹出确认
                    '取消 BotJob',
                    null,
                    function(){
                        console.log("confirm stop botJob");

                        vtradeAdapter.postStopBotJob(jobId, function () {
                                console.log("stop botJob success");
                                that.$Message.success('stop botJob success');
                            },
                            function () {
                                console.log("stop botJob failed");
                            }
                        );
                    },
                );
            },
            cancelEdit:function () {
                console.log("cancelEdit");
                this.resetForm();
                this.$emit('onCancel');
            },
        },
        computed:{
            subTitle:function () {
                if(this.mode == "view")
                    return "查看任务";
                else if(this.mode == "create")
                    return "创建任务";
                else if(this.mode == "modify")
                    return "修改任务";
                else
                    return "unknown";
            },
        },
        mounted:function(){
            console.log("botJobEditor mounted");
            this.resetForm();
        },
        watch:{
            botJobId: function (value) {
                console.log("botJobEditor data changed");
                this.resetForm();
            },
            mode: function () {
                console.log("botJobEditor mode changed");
                this.resetForm();
            },
            "$store.state.currentAccount": function (value) {
                if(this.mode === 'create'){
                    //仅创建模式时，才需要跟踪 account
                    console.log("botJobEditor $store.state.currentAccount changed, update editing data");
                    this.editingData.config.account = value;
                }
            },
            "$data.editingData.type": function (value){
                //配置各个类型任务的默认值，在切换任务类型时写入
                console.log(`editingData.type changed to ${value}`);
                if(this.mode === 'create'){
                    if(this.editingData.type === constants.AlgoBotJobType.PriceImprove){
                        this.editingData.config = {'account':this.$store.state.currentAccount, 'max_exec_time': 100};
                    }else if(this.editingData.type === constants.AlgoBotJobType.PositionAdjust){
                        this.editingData.config = {'account':this.$store.state.currentAccount, 'amount': 0};
                    }
                }
            },
        }
    }
</script>


<style scoped src="@/style/compactForm.css" />

<style scoped>
    .editor_main_title {
        font-size: 1.3em;
        font-weight: bold;
    }

    .editor_sub_title {
        margin-top: 6px;
        font-size: 0.8em;
        font-weight: bold;
        color: #999;
        float: right;
    }



</style>