<template>
    <div>
        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="records"
               v-bind:class="{ empty: true }" border>
        </Table>
    </div>
</template>

<script>

import ContractSymbol from "../../common/display/ContractSymbol";
import PositionNavTag from "@/components/Dashboard/Position/PositionNavTag.vue";
import TableAction from "@/components/common/TableAction.vue";
import Constants from "@/constants";

export default {
    name: 'positionlistview',
    components: {
        PositionNavTag,
        TableAction,
    },
    props:{
        hideZeroPosition:{
            type: Boolean,
            default:null,
            required:false
        },
    },
    data:function () {
        const that = this;

        return {
            columns: [
                {
                    title: '合约',
                    key: 'contract',
                    minWidth: 150,
                    render:function (h, params) {
                        return h(ContractSymbol,{
                            props:{
                                contract:params.row.contract,
                            }
                        });
                    }
                },
                {
                    title: '仓位/市值',
                    key: 'position_market_value'
                },
                {
                    title: '现价/成本',
                    key: 'price_cost'
                },
                {
                    title: '浮动盈亏',
                    key: 'unrealized_PNL',
                    render:function (h, params) {
                        return h(PositionNavTag, {
                            props:{
                                position:params.row
                            }
                        });
                    },
                },
                {
                    title: '附加信息',
                    key: 'addition',
                },
                {
                    title: '操作',
                    key: 'operation',
                    render:function (h, params){
                        var line = params.index;
                        var data = params.row;

                        const actionData = [
                            { name:"交易", disabled:false},
                        ];

                        if(data.contract.sec_type == Constants.SecType.OPT){
                            actionData.push({ name:"分析", disabled:false });
                        }

                        return h(TableAction,{
                            props:{
                                id:line,
                                actionData: actionData,
                            },
                            on:{
                                onClick:that.onTableAction,
                            }
                        });
                    }
                },
            ],
        }
    },
    computed:{
        records: function () {
            var ret = [];
            var positions = this.$store.state.accountPositionList;
            for (var idx=0; idx < positions.length; idx++) {
                var item = positions[idx];
                var cell = {};
                console.log("position:",item);

                if(item.contract.sec_type == "CASH"){
                    console.log("ignore cash position");
                    continue
                }

                cell['contract'] = item['contract'];
                cell['position'] = item['position'];// + " / " + item['market_value'] + " " + item['contract'].currency;
                cell['unrealized_PNL'] = item['unrealized_PNL']; //+ " " + item['contract'].currency;

                cell['market_value'] = item.market_value;
                cell['average_cost'] = item.average_cost;

                //自定义字段
                cell['position_market_value'] = item['position'] + " / " + item['market_value'] + " " + item['contract'].currency;
                cell['price_cost'] = (item['market_value']/item['position']).toFixed(2) + " / " + item['average_cost'].toFixed(2);

                if(this.hideZeroPosition === true && cell.position === 0){
                    continue;
                }

                ret.push(cell);
            }

            return ret;
        }
    },
    methods:{
        onTableAction:function (idx, action){
            console.log(`onTableAction: ${idx}, ${action}`);
            const data = this.records[idx];

            if(action == "分析"){
                this.$store.commit('updateOptionAnalyzerViewId', data.contract.id);
            }
        }
    }
}

</script>


<style scoped src="../../../style/noXBar.css" />

<style scoped>



</style>
