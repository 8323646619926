<template>
    <div class="marketData">
        <h3>市场数据</h3>

        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="records"
               v-bind:class="{ empty: true }" border>
        </Table>
        
    </div>
</template>

<script>

    import dataService from '../../service/dataService.js';
    import PriceTag from "../common/PriceTag";
    import ContractSymbol from "@/components/common/display/ContractSymbol.vue";

    export default {
        name: 'MarketData',
        components: {
            ContractSymbol,
            PriceTag
        },
        data:function () {
            return {
                columns: [
                    {
                        title: '合约',
                        key: 'code',
                        render: function (h, params){
                            const data = params.row;
                            return h(ContractSymbol, {
                                props: {
                                    contract_id:data.contract_id,
                                }
                            });
                        }
                    },
                    {
                        title: '价格',
                        key: 'price',
                        render: function (h, params) {
                            const data = params.row;
                            return h(PriceTag,{
                                props: {
                                    contract_id:data.contract_id,
                                }
                            })
                        }
                    },
                    {
                        title: '涨跌幅',
                        key: 'changeRate'
                    },
                    {
                        title: '订阅状态',
                        key: 'subscribeStatus'
                    },
                    {
                        title: '操作',
                        key: 'action'
                    }
                ],
            }
        },
        mounted: function () {
            dataService.getContractMarketDataSnapshotAllFromBackend();
        },
        computed:{
            records:function () {
                var ret = [];
                var keys = Object.keys(this.$store.state.marketLatestData);
                for(var i=0;i<keys.length;i++){

                    var item = {};
                    item['contract_id'] = parseInt(keys[i]);

                    ret.push(item);
                }

                return ret;
            }
        }
    }

</script>

<style scoped src="../../style/noXBar.css" />


<style scoped>

    .marketData {
        margin-top: 20px;
        margin-right: 20px;
    }



</style>
