<template>
    <div class="marketData">
        <h3>数据订阅</h3>

        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="records"
               v-bind:class="{ empty: true }" border>
        </Table>
        
    </div>
</template>

<script>

    import dataService from '../../service/dataService.js';
    import vtradeAdapter from "../../adapter/vtradeAdapter.js";

    //组件
    import contractSymbol from "../common/display/ContractSymbol";
    import mktWatchReasonList from "../common/display/MktWatchReasonListView"
    import eventBus from "@/service/messageBusService";

    const EVENT_MARKET_DATA_CONTRACT_IDS = "SERVER.MARKET.DATA.CONTRACT_IDS";

    export default {
        name: 'subscribe',
        components: {
        },
        data:function () {
            return {
                messageBusReceiver:null,
                columns: [
                    {
                        title: '序号',
                        key: 'idx',
                        maxWidth:70
                    },
                    {
                        title: '合约',
                        key: 'contract',
                        width: 300,
                        render:function (h, params) {
                            return h(contractSymbol,{
                                props:{
                                    contract:params.row.contract,
                                }
                            });
                        }
                    },
                    {
                        title: '订阅来源',
                        key: 'reasons',
                        render:function (h, params) {
                            return h(mktWatchReasonList,{
                                props:{
                                    data:params.row.reasons,
                                }
                            });
                        }
                    },
                    {
                        title: '订阅状态',
                        key: 'status'
                    }
                ],
                records:[]
            }
        },
        mounted: function () {
            this.getRecords();

            const that = this;

            this.messageBusReceiver = function (event, key, value) {
                console.log("subscribe component handle event:",event, key, value);
                if(event == EVENT_MARKET_DATA_CONTRACT_IDS){
                    that.getRecords();
                }
            }
            this.messageBusReceiver.receiverName = "subscribe component";

            //订阅消息
            eventBus.registerReceiver(this.messageBusReceiver, [EVENT_MARKET_DATA_CONTRACT_IDS]);
        },
        beforeDestroy() {
            //取消订阅
            eventBus.removeReceiver(this.messageBusReceiver, [EVENT_MARKET_DATA_CONTRACT_IDS]);
        },
        methods:{
            getRecords:function () {
                console.log("get records");
                var that = this;
                vtradeAdapter.getMarketDataAllWatchContract(function (data) {
                    console.log('data:' + data)
                    var records = [];
                    for(let i=0; i<data.length; i++){
                        var item = data[i];
                        console.log('item:', item);
                        item['idx'] = i;
                        if(item.success == true){
                            item['status'] = 'success';
                        }else {
                            item['status'] = 'failed:' + item.error_msg;
                        }

                        records.push(item);
                    }

                    that.$data.records = records;
                });
            }
        },
        computed:{
        }
    }

</script>

<style scoped src="../../style/noXBar.css" />

<style scoped>

    .marketData {
        margin-top: 20px;
        margin-right: 20px;
    }


</style>
