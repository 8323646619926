<template>
    <div style="width: 300px">
        <div>
            <Row>

                <Col span="5">
                    前端
                </Col>
                <Col span="12">
                    <div>
                        <span>EventWebSocket</span>
                    </div>
                </Col>
                <Col span="7">
                    <span>
                        <Tag v-bind:color="getColorFromConnectStatus($store.state.serverMessageBusWsStatus)"
                             class="statusTag">{{$store.state.serverMessageBusWsStatus}}</Tag>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span="12" offset="5">
                    <span>MarketWebSocket</span>
                </Col>
                <Col span="7">
                    <span>
                        <Tag v-bind:color="getColorFromConnectStatus($store.state.serverMarketDataWsStatus)"
                             class="statusTag">{{$store.state.serverMarketDataWsStatus}}</Tag>
                    </span>
                </Col>
            </Row>
        </div>

        <div>
            <template v-for="serviceName in backendServiceNameList">
                <Row v-bind:key="serviceName">
                    <Col span="5" v-if="serviceName == backendServiceNameList[0]">
                        后端
                    </Col>
                    <Col span="12" v-bind:offset="serviceName == backendServiceNameList[0]? 0:5">
                        <span>{{serviceName}}</span>
                    </Col>
                    <Col span="7">

                        <Poptip class="statusTagContainer" v-bind:title="serviceName + '服务状态'" content="content" placement="right" trigger="hover" v-bind:transfer="true" >
                            <Tag v-bind:color="getColorFromBackend($store.state.backendStatus['service'][serviceName])" class="statusTag">
                                {{$store.state.backendStatus['service'][serviceName]['fine'] ? "fine":"error"}}
                            </Tag>
                            <div slot="content">
                                <pre>{{JSON.stringify($store.state.backendStatus['service'][serviceName],null,2)}}</pre>
                            </div>
                        </Poptip>
                    </Col>

                </Row>
            </template>
        </div>

    </div>
</template>

<script>

    export default {
        name: 'BackendStatusView',
        components: {
        },
        methods:{
            'getColorFromConnectStatus':function (status) {
                if(status == "connected"){
                    return "success";
                }else if(status == "connecting"){
                    return "warning";
                }else {
                    return "error";
                }
            },
            'getColorFromBackend':function (backendStatus) {

                var fine = backendStatus['fine'];
                var error = backendStatus['error'];

                if(fine == false){
                    return "error";
                }

                if(error != null){
                    return "warning";
                }

                return "success";
            },
        },
        computed:{
            'backendServiceNameList':function () {
                var  backendStatus = this.$store.state.backendStatus;
                if( backendStatus == null || backendStatus['service'] == null){
                    return [];
                }

                return Object.keys(this.$store.state.backendStatus['service']);
            }
        }
    }

</script>

<style scoped>

    .statusTag{
        margin:auto;

        height: 16px !important;
        line-height: 16px !important;
    }

    >>> .statusTag * {
        font-weight: bold;
        height: 16px !important;
        line-height: 16px !important;
    }

</style>
