<template>
    <div >

        <Divider plain orientation="left">管理配置文件</Divider>

        <Table border
               v-bind:columns="columns"
               v-bind:data="profileTableData"
               v-bind:class="{ empty: true }"
               style="width: 600px; margin-bottom: 20px">
        </Table>

        <Divider orientation="left">创建配置文件</Divider>

        <Input v-model="profileName" placeholder="配置文件名称" style="width: 300px; margin-right: 30px" />
        <Button type="primary" icon="md-add" v-on:click="create_profile"
            v-bind:disabled="profileName==null || profileName==''"
        >添加</Button>

    </div>
</template>

<script>

import TableAction from "@/components/common/TableAction";
import TextView from "../common/display/TextView";

import configService from "@/service/configService";
import util from "@/util";

export default {
    name: 'profile',
    components: {
        TableAction
    },
    data:function (){
        var that = this;

        return {
            profileName:null,
            selectProfile:configService.getCurrentProfileName(),
            columns: [
                {
                    title: '配置文件',
                    key: 'name'
                },
                {
                    title: '状态',
                    key: 'status'
                },
                {
                    title: '操作',
                    key: 'action',
                    render:function (h, params) {
                        var line = params.index;
                        var profileData = that.profileTableData[line];

                        return h(TableAction,{
                            props:{
                                id:line,
                                actionData:[
                                    {
                                        name:"使用",
                                        disabled:profileData['status'] == '使用中'?true:false
                                    },
                                    {
                                        name:"删除",
                                        disabled:profileData['status'] == '使用中'?true:false
                                    },
                                    {
                                        name:"查看"
                                    }
                                ]
                            },
                            on:{
                                onClick:that.table_action,
                            }
                        });
                    }
                }
            ],
        }
    },
    methods:{
        create_profile:function (){
            var name = this.$data['profileName'];
            if(name == null || name == ""){
                util.show_notice("warning","配置文件名称不能为空");
            }else {
                configService.createProfile(name);
            }
        },
        table_action:function (idx, actionName){
            console.log("get table_action:",idx, name);
            var line = this.profileTableData[idx];

            if(actionName == "使用"){

                util.show_confirm(
                    '应用配置文件:' + line['name'],
                    null,
                    function(){
                        configService.setCurrentProfileName(line['name']);
                    },
                    null);

            }else if(actionName == "删除"){
                util.show_confirm(
                    '删除配置文件:' + line['name'],
                    null,
                    function(){
                        configService.removeProfile(line['name']);
                    },
                    null);
            }else if(actionName == "查看"){
                this.$Modal.info({
                    title: "配置文件:" + line['name'],
                    width: "500px",
                    render:function (h, params) {
                        return h(TextView,{
                            props:{
                                text:JSON.stringify(configService.getProfile(line['name']), null, 4),
                            }
                        });
                    },
                });
            }
        }
    },
    computed:{
        profileTableData:function (){
            var ret = [];
            var profileMap = configService.readProfileMapFromVuex();
            var currentProfileName = profileMap['currentProfile'];
            var profiles = profileMap['profiles'];
            console.log("profileMap:",JSON.stringify(profileMap));

            for(var key in profiles){
                var value = {};
                if(key == currentProfileName){
                    value['status'] = "使用中";
                }else {
                    value['status'] = "";
                }
                value['name'] = key;
                ret.push(value);
            }

            return ret;
        }
    }
}

</script>

<style scoped src="../../style/noXBar.css" />
<style scoped>


</style>
