import vtradeAdapter from "../adapter/vtradeAdapter.js";
import serverEventService from "./serverMessageBusService.js";
import eventBus from './messageBusService.js';
import util from "@/util";

var app = null;

var SERVER_EVENT_DRIVER_CONNECTED         = "SERVER.DRIVER.CONNECTED";           // 交易驱动 已连接
var SERVER_EVENT_DRIVER_DISCONNECTED      = "SERVER.DRIVER.DISCONNECTED";        // 交易驱动 连接断开
var SERVER_EVENT_DRIVER_READY             = "SERVER.DRIVER.READY";               // 交易驱动 就绪，可以接收 API 调用(已经同步完成账户，持仓，订单等信息)

var SERVER_EVENT_ACCOUNT_VALUE_UPDATE     = "SERVER.ACCOUNT.VALUE.UPDATE";       // 账户属性值更新
var SERVER_EVENT_ACCOUNT_PORTFOLIO_UPDATE = "SERVER.ACCOUNT.PORTFOLIO.UPDATE";   // 账户头寸更新
var SERVER_EVENT_ACCOUNT_ORDER_UPDATE     = "SERVER.ACCOUNT.ORDER.UPDATE";       // 账户订单更新

var SERVER_EVENT_MARKET_COMMON = "SERVER.MARKET.DATA.COMMON"; //市场数据更新
var SERVER_EVENT_MARKET_TICK = "SERVER.MARKET.DATA.TICK"; // 市场数据更新

var SERVER_EVENT_BOT_JOB_UPDATE = "SERVER.BOT.JOB.UPDATE"; // BotJob 状态更新

//合约数据的缓存, contract_id -> contract 对象
var contractCache = {};

function setApp(currentApp) {
    app = currentApp;

    //订阅消息
    eventBus.registerReceiver(handle_event, ["SERVER.DRIVER.","SERVER.ACCOUNT.","SERVER.MARKET.","SERVER.BOT.JOB.UPDATE"]);
}

function getApp() {
    return app;
}

function handle_event(event, key, value) {
    console.log("dataService handle event:",event, key, value);
    if(event == SERVER_EVENT_ACCOUNT_VALUE_UPDATE){
        getAccountSummaryFromBackend();
    }else if(event == SERVER_EVENT_ACCOUNT_PORTFOLIO_UPDATE){
        getPositionListFromBackend();
    }else if(event == SERVER_EVENT_ACCOUNT_ORDER_UPDATE){
        getOrderListFromBackend();
    }else if(event == SERVER_EVENT_BOT_JOB_UPDATE){
        console.log("update botJob: " + key);
        //直接更新缓存
        app.$store.commit('updateBotJobData', value);
    }
}
handle_event.receiverName = "dataServiceReceiver";


//从服务端获取信息
//启动后只需要运行一次
async function getBaseInfoFromBackend(app) {

    console.log("getBaseInfoFromBackend");

    //获取账户列表
    const accountListRet = await vtradeAdapter.getAccountList();
    console.log("getAccountList success, ret:", accountListRet);
    app.$store.commit('updateAccountList', accountListRet);

    if(accountListRet != null && accountListRet.length > 0){
        var currentAccount = accountListRet[0]['account'];
        app.$store.commit('updateCurrentAccount', currentAccount);
        console.log("auto set currentAccount:", currentAccount);

        eventBus.postEvent(eventBus.EVENT_ACCOUNT_LOADED);
    }


}

async function getBackendServiceStatus() {
    console.log("getBackendServiceStatus");

    //获取后端服务状态
    const backendStatusRet = await vtradeAdapter.getStatus();

    console.log("getBackendStatus, ret:", backendStatusRet);
    app.$store.commit('updateBackendStatus', backendStatusRet);
}

async function getAllDataOfAccount() {

    const currentAccount = app.$store.state.currentAccount;
    console.log("getAllDataOfAccount:", currentAccount);

    if(currentAccount == null){
        console.log("currentAccount is null, ignore");
    }

    //检查 driver 状态
    if(app.$store.state.backendStatus['service'] == null){
        console.log('backendStatus is null, ignore');
        return;
    }

    var driverStatus = app.$store.state.backendStatus['service']['account']['detail'][app.$store.state.currentAccount];
    if(driverStatus == null || driverStatus['status']['run_status'] != 'WORKING'){
        console.log("driverStatus not WORKING, ignore");
        return;
    }

    //获取当前账户的所有数据
    await Promise.all([
        getAccountSummaryFromBackend(),
        getPositionListFromBackend(),
        getOrderListFromBackend(),
        getBotJobListFromBackend("active"),
    ]);
}

async function getAccountSummaryFromBackend() {
    console.log("getAccountSummaryFromBackend");
    const ret = await vtradeAdapter.getAccountValues(app.$store.state.currentAccount);

    console.log('accountSummary', ret);
    app.$store.commit('updateAccountDetail', ret);
}

async function getPositionListFromBackend() {
    console.log("getPositionListFromBackend");

    //原始列表数据
    const ret = await vtradeAdapter.getPositionList(app.$store.state.currentAccount);

    console.log('positionList', ret);
    app.$store.commit('updateAccountPositionList', ret);

    //卡片视图数据
    const ret2 = await vtradeAdapter.getPositionGroup(app.$store.state.currentAccount);

    console.log('positionGroup', ret);
    app.$store.commit('updateAccountPositionGroup', ret2);
}

async function getOrderListFromBackend() {
    console.log("getOrderListFromBackend");
    const ret = await vtradeAdapter.getOrderList(app.$store.state.currentAccount);

    console.log('orderList', ret);
    app.$store.commit('updateAccountOrderList', ret);
}

async function getBotJobListFromBackend(viewType) {
    console.log(`getBotJobListFromBackend:${viewType}`);

    var ret = await vtradeAdapter.getRunningBotJob(viewType);

    //数组转成字典
    var job_map = {};
    for(var i=0; i<ret.length; i++){
        job_map[ret[i].id] = ret[i];
    }

    app.$store.commit('updateBotJobDataList', job_map);
}

function getContractMarketDataSnapshotAllFromBackend() {
    console.log("getContractMarketDataSnapshotAllFromBackend");
    vtradeAdapter.getContractMarketDataSnapshotAll(null, function (ret) {
        console.log('market data snapshot all:', ret);
        var keys = Object.keys(ret);
        for(var i=0;i<keys.length;i++){
            var key = keys[i];
            app.$store.commit('updateMarketLatestData', {
                "id": key,
                "data": ret[key]
            });
        }
    },null);

}


async function getContract(contractId) {
    var cachedItem = contractCache[contractId];
    if( cachedItem != null){
        return util.deepCopy(cachedItem);
    }

    var item = await vtradeAdapter.getContract(contractId);
    contractCache[contractId] = item;

    return util.deepCopy(item);
}

async function getCondition(conditionId) {
    var item = await vtradeAdapter.getCondition(conditionId);
    return util.deepCopy(item);
}



//从 vuex 获取数据

function getPosition(contract_id) {
    console.log(`getPosition: contract_id=${contract_id}`)
    const accountPositionList = app.$store.state.accountPositionList;
    console.log('accountPositionList:',accountPositionList);

    if(accountPositionList == null)
        return null;

    for(let idx in accountPositionList){
        const item = accountPositionList[idx];
        if(item.contract.id == contract_id){
            return item.position;
        }
    }

    return 0;
}

//通过 orderId 获取订单
function getOrderById(orderId) {

}

export default {
    setApp,
    getApp,
    getBaseInfoFromBackend,
    getAllDataOfAccount,
    getBackendServiceStatus,
    getAccountSummaryFromBackend,
    getPositionListFromBackend,
    getOrderListFromBackend,
    getContractMarketDataSnapshotAllFromBackend,
    getContract,
    getCondition,
    getPosition,
    getBotJobListFromBackend,
};