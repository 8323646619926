<template>
    <div>
        <Button size="small" class="action_btn" v-on:click="doShow">查看</Button>
        <Button size="small" class="action_btn" v-on:click="doActive"
                v-if="status == 'Init' " >激活
        </Button>

        <Button size="small" class="action_btn" v-on:click="doModify"
                v-if="status == 'Init' ">
            修改
        </Button>
        <template v-if="status != 'Succeed' && status != 'Failed' ">
            <Button size="small" class="action_btn" v-on:click="doStop">取消</Button>
        </template>
    </div>
</template>

<script>

export default {
    name: 'botJobAction',
    components: {},
    props:['id','status'],
    methods: {
        doShow:function () {
            //console.log("emit onShow");
            this.$emit('onShow', this.id);
        },
        doModify:function () {
            //console.log("emit onModify");
            this.$emit('onModify', this.id);
        },
        doActive:function (){
            //console.log("emit onActive");
            this.$emit('onActive', this.id);
        },
        doStop:function () {
            //console.log("emit onCancel");
            this.$emit('onStop', this.id);
        },
    }
}
</script>

<style scoped>

    .action_btn {
        margin-right: 5px;
    }
</style>
