<template>
    <div>

        <Modal v-model="showOrderEditor"
               v-bind:closable="false"
               footer-hide>

            <div class="editor_main_title">
                订单编辑器
                <span class="editor_sub_title">{{subTitle}}</span>
            </div>

            <Divider style="margin: 12px 0 12px 0 "/>

            <Form v-bind:label-width="100" >

                <FormItem label="订单编号" v-if="mode != 'create'">
                    {{form_data.unique_id}}
                </FormItem>

                <FormItem label="合约">
                    <BagContractSelector v-model="form_data.contract_id"
                                      v-bind:showBorder="true"
                                      v-bind:disabled="loading!=null || mode=='modify' || mode=='show'"
                    >

                    </BagContractSelector>
                </FormItem>

                <FormItem label="方向">
                    <RadioGroup size="small" v-model="form_data.action" type="button">
                        <Radio v-bind:label="constants.OrderAction.BUY"
                               v-bind:disabled="(form_data != null && form_data.action != constants.OrderAction.BUY) && (loading != null || mode == 'show' || mode=='modify')">
                            <span>买入</span>
                        </Radio>
                        <Radio v-bind:label="constants.OrderAction.SELL"
                               v-bind:disabled="(form_data != null && form_data.action != constants.OrderAction.SELL) && (loading != null || mode == 'show' || mode=='modify')">
                            <span>卖出</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>

                <FormItem label="订单类型">
                    <RadioGroup size="small" v-model="form_data.order_type" type="button">
                        <Radio v-bind:label="constants.OrderType.LMT"
                               v-bind:disabled="(form_data != null && form_data.order_type != constants.OrderType.LMT) && (loading != null || mode == 'show' || mode=='modify') ">
                            <span>限价单</span>
                        </Radio>
                        <Radio v-bind:label="constants.OrderType.MKT"
                               v-bind:disabled="(form_data != null && form_data.order_type != constants.OrderType.MKT) && (loading != null || mode == 'show' || mode=='modify')">
                            <span>市价单</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>

                <FormItem label="有效期" >
                    <RadioGroup size="small" v-model="form_data.tif" type="button">
                        <Radio v-bind:label="constants.OrderTif.DAY"
                               v-bind:disabled="(form_data != null && form_data.tif != constants.OrderTif.DAY) && (loading != null || mode == 'show' || mode=='modify' )">
                            <span>当日</span>
                        </Radio>
                        <Radio v-bind:label="constants.OrderTif.GTC"
                               v-bind:disabled="(form_data != null && form_data.tif != constants.OrderTif.GTC) && (loading != null || mode == 'show' || mode=='modify' )">
                            <span>撤销前</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>

                <FormItem label="盘前后时段" >
                    <i-switch v-model="form_data.outside_rth"/>
                </FormItem>

                <FormItem label="价格" >
                    <InputNumber size="small" v-model="form_data.lmt_price"
                                 v-bind:disabled="loading != null || mode == 'show'"
                                 v-bind:min="0"
                                 v-bind:step="0.05"
                                 style="width: 120px">
                    </InputNumber>
                </FormItem>

                <FormItem label="数量" >
                    <InputNumber size="small" v-model="form_data.total_quantity"
                                 v-bind:disabled="loading != null || mode == 'show'"
                                 style="width: 120px">
                    </InputNumber>
                </FormItem>

                <FormItem label="状态" >
                    {{form_data != null? form_data.order_state:null}}
                </FormItem>

                <Divider orientation="left" size="small">附加信息</Divider>

                <FormItem label="市场价格" >
                    <div class="priceContainer">
                        <!--因为 priceTag 是 inline-block, 按行基线对齐，基线下会出现一个半行距的高度，这里按中间来对齐 -->
                        <PriceTag v-if="form_data.contract_id != null"
                                  v-bind:autoWatch="showOrderEditor === true"
                                  v-bind:contract_id="form_data.contract_id">
                        </PriceTag>

                        <template v-if="form_data.contract_id != null && form_data.total_quantity != null">
                            <span style=""> x {{form_data.total_quantity}} = </span>
                            <PriceTag v-bind:autoWatch="false"
                                      v-bind:contract_id="form_data.contract_id"
                                      v-bind:radio="form_data.total_quantity"
                                      v-bind:show-amount="false"></PriceTag>
                        </template>

                    </div>
                </FormItem>

                <FormItem label="已持有数量" >
                    <template v-if="positionAmount != null">
                        {{positionAmount}}
                    </template>
                </FormItem>
            </Form>

            <template v-if="mode=='create' || mode=='modify'">
                <Divider size="small" style="margin: 12px 0"/>
                <Row class="editor_line" >
                    <Col span="6">
                        <br>
                    </Col>
                    <Col span="13" class="order_status">
                        <ButtonGroup>
                            <Button type="primary"
                                    ghost
                                    size="small"
                                    v-bind:disabled="canSubmit != true || loading != null"
                                    v-bind:loading="loading=='submit'"
                                    v-on:click="submitOrder">
                                提交订单
                            </Button>
                            <Button v-if="mode=='modify'"
                                    type="error"
                                    ghost
                                    size="small"
                                    v-bind:disabled="canSubmit != true || loading != null"
                                    v-bind:loading="loading=='cancel'"
                                    v-on:click="cancelOrder">
                                取消订单
                            </Button>
                        </ButtonGroup>

                        <Button v-if="mode=='create' || mode=='modify'"
                                size="small"
                                style="float: right"
                                v-bind:disabled="loading!=null"
                                v-on:click="cancelEdit">
                            放弃编辑
                        </Button>
                    </Col>
                </Row>
            </template>
            <!--

            order={{order}}

            <hr>
            form_data={{form_data}}

            -->
        </Modal>
    </div>
</template>

<script>

/*
订单编辑器组件

外部通过 model 进行绑定
event:
onCancelEdit
onFinish 流程结束,包括提交订单，取消订单，放弃修改
*/

import _ from 'underscore';


import ContractSelector from './ContractSelector';
import ContractSymbol from '../display/ContractSymbol';
import PriceTag from "@/components/common/PriceTag.vue";
import BagContractSelector from "@/components/common/editor/BagContractSelector.vue";
import constants from '../../../constants.js';
import vtradeAdapter from "../../../adapter/vtradeAdapter.js";
import util from "../../../util.js";
import dataService from "@/service/dataService";
import serverMarketDataService from "@/service/serverMarketDataService";



export default {
    name: 'ordereditor',
    components: {
        ContractSelector,
        ContractSymbol,
        BagContractSelector,
        PriceTag,
    },
    props: {
        'order':{
            type:Object,
        }, //输入 editor 的原始订单数据，create 模式下可为空
        'contract':{
            type:Object,
        },//order 对应的 contract，非 create 模式从外面传入，create 模式为空
        'mode':String, //编辑器工作模式 create:创建订单, modify:修改订单, show:展示订单，不允许修改
        'commitToBackend':Boolean,//由编辑器提交修改到服务器
    },
    data:function(){

        //从原始订单数据 value 对象，转换成一个可以绑定编辑框 modal 的数据
        return {
            constants:constants,
            showOrderEditor:false,//模态框是否显示
            loading:null,//提交订单为 submit, 取消订单: cancel
            form_data:{
                contract_id: null, //合约 id
                unique_id: null, //唯一订单号
                order_type: constants.OrderType.LMT,
                total_quantity: null,
                tif: constants.OrderTif.GTC,
                outside_rth: false,
                lmt_price: null,
                action:null,
            },
        };
    },
    mounted: function(){
        console.log('OrderEditor mount');
    },
    methods:{
        open:function(){
            console.log("orderEditor open, props=", this.order);
            // 从 props 读取数据到 form_data

            if(this.order != null){
                console.log('props not null, update contractSelectorValue and form_data')
                this.form_data = util.deepCopy(this.order);
            }else {
                this.form_data = {
                    //iView 控件的 bug，这两个 key 不写，input 控件就清空不了
                    "lmt_price": null,
                    "total_quantity": null
                };
            }

            this.showOrderEditor = true;
        },
        submitOrder:function(){
            var that = this;
            console.log("submitOrder");

            if(this.commitToBackend == true){
                console.log("do submit order:",this.mode);
                var successHandler = function () {
                    console.log("submit order success");
                    that.loading = null;
                    that.$Message.success('submit order success');
                    if(that.mode == 'modify'){
                        that.$emit("onSubmitModify");
                    }else if(that.mode == 'create'){
                        that.$emit("onSubmitPlace");
                    }
                    that.$emit('onFinish');
                    that.showOrderEditor = false;
                };

                var failedHandler = function (ret) {
                    console.log("submit order failed");
                    that.loading = null;
                };

                if(that.mode == 'modify'){
                    util.show_confirm( //弹出确认
                        '修改订单',
                        null,
                        function(){
                            that.loading = 'submit';
                            vtradeAdapter.modifyOrder(
                                that.$store.state.currentAccount,
                                that.form_data.unique_id,
                                util.deepCopy(that.form_data),
                                successHandler,
                                failedHandler,
                            );
                        },
                        null);
                }else if(that.mode == 'create'){

                    util.show_confirm(
                        '创建订单',
                        null,
                        function(){
                            that.loading = 'submit';
                            vtradeAdapter.placeOrder(
                                that.$store.state.currentAccount,
                                util.deepCopy(that.form_data),
                                successHandler,
                                failedHandler,
                            )
                        },
                        null);
                }
            }else {
                if(that.mode == 'modify'){
                    this.$emit("onSubmitModify");
                }else if(that.mode == 'create'){
                    this.$emit("onSubmitPlace");
                }

                this.$emit('onFinish');
                this.showOrderEditor = false;
            }
        },
        cancelOrder:function(){
            var that = this;
            console.log("cancelOrder");
            that.loading = 'cancel';
            if(this.commitToBackend == true){

                vtradeAdapter.cancelOrder(
                    this.$store.state.currentAccount,
                    this.order.unique_id,
                    function () {
                        console.log("cancel order success");
                        that.loading = null;
                        that.$Message.success('cancel order success');
                        that.$emit("onCancelOrder");
                        that.$emit('onFinish');
                        this.showOrderEditor = false;
                    },
                    function () {
                        console.log("cancel order failed");
                        that.loading = null;
                    }
                );
            }else {
                this.$emit("onCancelOrder");
                this.$emit('onFinish');
                this.showOrderEditor = false;
            }
        },
        cancelEdit:function () {
            console.log("cancelEdit");
            this.$emit('onCancelEdit');
            this.$emit('onFinish');
            this.showOrderEditor = false;
        },
    },
    computed:{
        subTitle:function () {
            console.log("this.mode:",this.mode);
            if(this.mode == 'create')
                return "创建订单";

            if(this.mode == 'modify')
                return "修改订单";

            if(this.mode == 'show')
                return "查看订单";

            return null;
        },
        canSubmit:function () {
            return true;
        },
        positionAmount:function (){
            //已持有数量
            const contract_id = this.form_data.contract_id;
            if(contract_id == null)
                return null;

            return dataService.getPosition(contract_id)
        },
        mktPrice:function (){
            //合约市场价格

            return null;
        },
    },
    created:function(){
        console.log("orderEditor created");
    },
    watch:{
        contractSelectorValue:function () {

            //合约选择器选中合约的 id
            if(this.contractSelectorValue != null){
                this.form_data.contract_id = this.contractSelectorValue.id;
            }else {
                this.form_data.contract_id = null;
            }
        }
    }
}

</script>


<style scoped src="@/style/compactForm.css" />
<style scoped>

.editor_main_title {
    font-size: 1.3em;
    font-weight: bold;
}

.editor_sub_title {
    margin-top: 6px;
    font-size: 0.8em;
    font-weight: bold;
    color: #999;
    float: right;
}

.editor_line {
    height: 24px;
    margin-bottom: 5px;
}

.order_id {
    font-weight: bold;
    color: #666;
}

.order_status {
    font-weight: bold;
    color: #666;
}

.priceContainer {
    line-height: 30px;
    font-size: 15px;
    vertical-align:middle
}

</style>
