import { render, staticRenderFns } from "./TableAction.vue?vue&type=template&id=479630ce&scoped=true&"
import script from "./TableAction.vue?vue&type=script&lang=js&"
export * from "./TableAction.vue?vue&type=script&lang=js&"
import style0 from "./TableAction.vue?vue&type=style&index=0&id=479630ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479630ce",
  null
  
)

export default component.exports