<template>
    <div class="dashboard">
        <Row>
            <Col span="12">
                <Accountsummary></Accountsummary>
            </Col>
            <Col span="12">
                <EventList></EventList>
            </Col>
        </Row>
        <Position></Position>
        <Orderlist></Orderlist>

        <Modal v-model="showBotJobViewModal"
               v-bind:closable="false"
               v-on:on-visible-change="onBotJobViewVisibleChange"
               footer-hide>

            <BotJobEditor mode="view"
                          v-if="showBotJobId != null"
                          v-bind:botJobId="showBotJobId">
            </BotJobEditor>
        </Modal>

        <Modal v-model="showToolViewModal"
               v-bind:closable="true"
               v-on:on-visible-change="onOptionViewVisibleChange"
               v-bind:width="800"
               footer-hide>

            <OptionAnalyzer v-if="showToolViewModal" v-bind:option-contract-id-list="optionContractIdList">

            </OptionAnalyzer>
        </Modal>
    </div>
</template>

<script>

import Accountsummary from "./Dashboard/AccountSummary";
import Position from "./Dashboard/Position";
import Orderlist from "./Dashboard/OrderList";
import EventList from "./Dashboard/BotJobList";
import BotJobView from "./common/display/BotJobView";
import BotJobEditor from "./common/editor/BotJobEditor";
import OptionAnalyzer from "@/components/Tool/OptionAnalyzer.vue";

export default {
    name: 'dashboard',
    data:function(){
        return {
            //bot job view
            showBotJobViewModal:false,
            showBotJobId:null,
            botJobData: null,
            //tool view
            showToolViewModal:false,

            optionContractIdList:null, // 要分析的期权列表
        }
    },
    components: {
        Orderlist,
        Position,
        Accountsummary,
        EventList,
        BotJobView,
        BotJobEditor,
        OptionAnalyzer,
    },
    methods:{
        onBotJobViewVisibleChange:function (visable) {
            console.log('onBotJobViewVisibleChange:', visable);
            if(visable == false){
                this.$data.showBotJobId = null;
                this.$store.commit('updateBotJobViewId', null);
            }
        },
        onOptionViewVisibleChange:function (visable){
            console.log('onOptionViewVisibleChange:', visable);
            if(visable == false){
                this.$data.showToolViewModal = false;
                this.$store.commit('updateOptionAnalyzerViewId', null);
            }
        },
    },
    watch:{
        '$store.state.botJobViewId':function (botJobId) {
            if(botJobId == null){
                return;
            }

            console.log("show botJobId", botJobId);
            this.$data.showBotJobId = botJobId;
            this.$data.showBotJobViewModal = true;
        },
        '$store.state.optionAnalyzerViewId':function (optionContractId) {
            console.log("show optionAnalyzerView", optionContractId);
            if(optionContractId == null){
                return;
            }else if(optionContractId !== true){
                this.optionContractIdList = [optionContractId];
            }else if(optionContractId === true){
                this.optionContractIdList = null;
            }

            this.$data.showToolViewModal = true;
        }
    }
}

</script>

<style scoped>

.dashboard {
    width: 100%;
}

</style>
