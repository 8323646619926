<template>
    <div class="botJobList">

        <Card style="height: 290px">
            <p slot="title">自动化任务</p>
            <p slot="extra" class="title_extra">
                <Button size="small" v-on:click="onCreate" style="margin-right: 5px">
                    创建任务
                </Button>

                <RadioGroup v-model="jobListType" type="button" size="small">
                    <Radio label="active">活跃任务</Radio>
                    <Radio label="all">全部任务</Radio>
                </RadioGroup>
            </p>

            <Table size="small"
                   v-bind:columns="columns"
                   v-bind:data="records"
                   v-bind:class="{ empty: true }"
                   v-bind:max-height="235"
                   border>
            </Table>

            <template v-if="totalRecordsCnt > pageSize">
                <div class="footer">
                    <Page v-bind:total="totalRecordsCnt"
                          v-bind:page-size="pageSize"
                          v-bind:current.sync="currentPage"
                          size="small" simple show-total />
                </div>
            </template>
        </Card>

        <Modal v-model="showBotJobViewEditorModal"
               v-bind:closable="false"
               v-on:on-visible-change="onModalVisibleChange"
               footer-hide>

            <BotJobEditor ref="botJobEditor"
                          v-bind:mode="botJobEditorMode"
                          v-bind:botJobId="botJobEditorJobId"
                          v-on:onCancal="closeModal"
                          v-on:onSubmit="closeModal">
            </BotJobEditor>
        </Modal>
    </div>
</template>

<script>

import constants from "../../constants";
import BotJobAction from "../common/action/BotJobAction";
import BotJobEditor from "../common/editor/BotJobEditor";
import util from "../../util";
import vtradeAdapter from "../../adapter/vtradeAdapter";
import dataService from "../../service/dataService";
import DependsListEditor from "@/components/common/editor/DependsListEditor.vue";

export default {
    name: 'botJobList',
    components: {
        BotJobAction,
        BotJobEditor,
    },
    data:function () {
        var that = this;
        return {
            columns: [
                {
                    title: '编号',
                    key: 'id',
                    width: 65,
                },
                {
                    title: '类型',
                    key: 'type',
                    width: 130,
                },
                {
                    title: '依赖',
                    key: 'depends',
                    render:function (h, params){
                      return h(DependsListEditor, {
                        props: {
                          value:params.row.depends,
                          disable:true,
                          showEditorBtn:false,
                        }
                      });
                    }
                },
                {
                    title: '状态',
                    key: 'status',
                },
                {
                    title: '操作',
                    key: 'operation',
                    width: 220,
                    render:function (h, params) {
                        //console.log("render:", h, params);
                        var botJob = params.row;
                        return h(BotJobAction,{
                            props:{
                                id:botJob.id,
                                status:botJob.status
                            },
                            on:{
                                onShow:that.onShow,
                                onModify:that.onModify,
                                onActive:that.onActive,
                                onStop:that.onStop,
                            }
                        });
                    }
                },
            ],
            jobListType: "active",
            //表格分页
            currentPage:1,
            pageSize:5,
            showBotJobViewEditorModal:false, //是否显示订单创建/修改订单的编辑器模态框
            botJobEditorMode:'create', // 编辑器的工作模式, create/modify
            botJobEditorJobId:null, //编辑的 jobId
        }
    },
    methods:{
        onCreate:function(){
            console.log("create botJob");
            this.botJobEditorMode = 'create';
            this.botJobEditorJobId = null;
            this.showBotJobViewEditorModal = true;
            //this.$refs.botJobEditor.resetForm(); //打开编辑器时清空表单
        },
        onShow:function(id){
            //点击查看 botJob 按钮
            console.log("onShow:", id);
            this.$store.commit('updateBotJobViewId', id);
        },
        onModify:function(id){
            //点击修改 botJob 按钮
            console.log("onShow:", id);
            this.botJobEditorMode = 'modify';
            this.botJobEditorJobId = id;
            this.showBotJobViewEditorModal = true;

        },
        onActive:function (id){
            console.log("onActive:", id);
            var that = this;

            util.show_confirm( //弹出确认
                '激活 BotJob',
                null,
                function(){
                    console.log("confirm active botJob");

                    vtradeAdapter.postActiveBotJob(id, function () {
                            console.log("active botJob success");
                            that.$Message.success('active botJob success');
                        },
                        function () {
                            console.log("stop botJob failed");
                        }
                    );
                },
            );
        },
        onStop:function (id) {
            //点击取消 botJob 按钮
            console.log("onStop:", id);
            var that = this;

            util.show_confirm( //弹出确认
                '取消 BotJob',
                null,
                function(){
                    console.log("confirm stop botJob");

                    vtradeAdapter.postStopBotJob(id, function () {
                            console.log("stop botJob success");
                            that.$Message.success('stop botJob success');
                        },
                        function () {
                            console.log("stop botJob failed");
                        }
                    );
                },
            );
        },
        onModalVisibleChange:function () {
            console.log("onModalVisibleChange");
        },
        closeModal:function (){
            console.log("closeModal");
            this.showBotJobViewEditorModal = false;
        }
    },
    watch:{
        jobListType:function (val) {
            console.log("jobListType change to:", val);
            dataService.getBotJobListFromBackend(val);

        }
    },
    computed:{
        totalRecordsCnt:function(){
            let data = this.$store.state.botJobData;
            if(data == null)
                return 0;

            let total = Object.keys(data).length;
            //console.log("total:",total);
            return total;
        },
        records:function () {
            var ret = [];
            var botJobData = this.$store.state.botJobData;

            if(botJobData == null){
                return [];
            }

            let keys = Object.keys(botJobData);
            keys.sort(function (a, b) {
                return parseInt(b) - parseInt(a);
            });

            let startIdx = this.pageSize * (this.currentPage-1);
            let endIdx = Math.min(startIdx + this.pageSize -1, keys.length-1);

            for(var idx = startIdx; idx<=endIdx; idx ++){
                let key = keys[idx];
                console.log( key,botJobData[key])

                var item = botJobData[key];
                ret.push(item);
            }

            return ret;
        }
    }
}

</script>

<style scoped src="../../style/compactCard.css" />
<style scoped src="../../style/noXBar.css" />

<style scoped>


    .botJobList {
        margin: 10px 10px 0px 5px;
    }

    .title_extra{
        top: 5px;
    }

    .footer {
        float: right;
        margin-top: 5px;
        margin-bottom: -5px;
    }

    /*压缩表格行高度，原本为 40*/
    >>> .ivu-table-small td {
        height: 35px;
    }





</style>
