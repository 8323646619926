<template>
    <div class="marketData">
        <h3>提醒</h3>

        <!-- 点击打开模态框 -->
        <Button icon="md-add"
                style="margin-bottom: 12px"
                v-on:click="on_create_notice"
                v-bind:disabled="false"
        >添加提醒</Button>

        <Table size="small"
               v-bind:columns="columns"
               v-bind:data="records"
               v-bind:class="{ empty: true }" border>
        </Table>

        <Modal v-model="showEditorModal"
               v-bind:title="editorMode=='create' ? '添加提醒':'修改提醒'"
               v-bind:closable="false"
               footer-hide>
            <NoticeEditor  v-bind:mode="editorMode"
                           v-model="editorValue"
                           v-on:onSubmit="onEditorSubmit"
                           v-on:onCancel="onEditorCancel">
            </NoticeEditor>
        </Modal>
    </div>
</template>

<script>

    import NoticeEditor from '../common/editor/NoticeEditor';
    import vtradeAdapter from "../../adapter/vtradeAdapter";
    import constants from "../../constants";
    import TableAction from "../common/TableAction";
    import util from "@/util";

    export default {
        name: 'subscribe',
        components:{
            NoticeEditor,
            TableAction
        },
        data:function () {
            var that = this;
            return {
                showEditorModal:false, //显示添加提醒的模态框
                editorMode:'create',
                editorValue:null,
                records:[], //从服务端获取的 notice 列表
                columns: [
                    { title: 'id', key: 'id'},
                    { title: 'biz_id', key: 'biz_id'},
                    { title: 'title', key: 'title' },
                    { title: 'message', key: 'message'},
                    { title: 'level', key: 'level'},
                    { title: 'status', key: 'status'},
                    { title: 'count', key: 'count'},
                    { title: '操作', key: 'action',
                        render: function (h, params) {

                            console.log('this', this, this.records);
                            var line = params.index;
                            var data = that.records[line];

                            return h(TableAction,{
                                props:{
                                    id:line,
                                    actionData:[
                                        { name:"修改", disabled:false },
                                        { name:"删除", disabled:false }
                                    ]
                                },
                                on:{
                                    onClick:that.table_action,
                                }
                            });
                        }

                    }
                ]
             }
        },
        mounted: function () {
            this.getRecords();
        },
        methods: {
            on_create_notice: function(){
                this.editorMode = 'create';
                this.showEditorModal = true;
            },
            getRecords:async function () {
                console.log("get records");
                const data = await vtradeAdapter.getNoticeAll();

                console.log('data:' + data)
                var records = [];
                for(let i=0; i<data.length; i++){
                    var item = data[i];
                    console.log('item:', item);
                    item['idx'] = i;

                    records.push(item);
                }

                this.$data.records = records;
            },
            table_action:function (idx, actionName){
                console.log("receive table_action:",idx, name);
                var that = this;
                var line = this.records[idx];

                console.log("line:", JSON.stringify(line));

                if(actionName == "删除"){
                    util.show_confirm(
                        '删除提醒:' + "[" + line['id'] + "] " + line['title'],
                        null,
                        async function(){
                            await vtradeAdapter.postNoticeDelete(line['id']);

                            console.log("postNoticeDelete success");
                            that.$Message.success('删除提醒成功');
                            await that.getRecords();
                        },
                        null);
                }else if(actionName == "修改"){
                    this.editorMode = 'modify';
                    this.editorValue = line.id;
                    this.showEditorModal = true;
                }
            },
            onEditorSubmit:function (){
                console.log("onEditorSubmit");
                this.showEditorModal = false;
                this.getRecords();
            },
            onEditorCancel:function (){
                console.log("onEditorCancel");
                this.showEditorModal = false;
            },
        },
        computed:{

        }
    }

</script>

<style scoped src="../../style/noXBar.css" />


<style scoped>

    .marketData {
        margin-top: 20px;
        margin-right: 20px;
    }

</style>
