import vtradeAdapter from '../adapter/vtradeAdapter';
import messageBusService from './messageBusService.js';

//处理前端 event 和后端 event 的桥接，把服务端的消息转发到前端消息总线

var app = null;

var subscribe_event = ['DRIVER.', 'ACCOUNT.', 'MARKET.', 'BOT.JOB.UPDATE'];//订阅的消息类型
var wsClient = null;

function startSubscribe() {
    console.log("startSubscribe server event bus");

    app.$store.commit('updateServerMessageBusWsStatus', 'connecting');

    wsClient = vtradeAdapter.connectServerEventBusWs(function () {
        app.$store.commit('updateServerMessageBusWsStatus', 'connected');

        console.log("send subscribe cmd:", subscribe_event);
        var command = {"type":"subscribe", "value": subscribe_event};
        this.send(JSON.stringify(command));

        messageBusService.postEvent(messageBusService.EVENT_MESSAGE_BUS_WS_CONNECT);

    },function (eventData) {
        console.log("eventData:",eventData);
        var eventType = eventData['type'];
        var eventKey = eventData['key'];
        var value = eventData['value'];
        messageBusService.postEvent("SERVER." + eventType, eventKey, value); //转发服务端消息，加 SERVER. 前缀

    },function () {
        app.$store.commit('updateServerMessageBusWsStatus', 'disconnected');
        messageBusService.postEvent(messageBusService.EVENT_MESSAGE_BUS_WS_DISCONNECT);
    });

}

function stopSubscribe() {
    console.log("stop subscribe");
}

function setApp(item) {
    app = item;
}

export default {
    setApp,
    startSubscribe,
    stopSubscribe
}