<template>

    <div style="display: inline-block">
        <template v-if="loading">
          <div class="spin_container">
            <Spin size="small"></Spin>
          </div>
        </template>
        <template v-else>
            <template v-if="combineData != null">
                <Tag class="sec_type_tag" color="default">Condition:{{combineData.id}}:{{combineData.name}}</Tag>
            </template>
            <template v-else>
                <Tag class="sec_type_tag" color="default">NULL</Tag>
            </template>
        </template>
    </div>
    
</template>

<script>
    import util from "../../../util";
    import dataService from "../../../service/dataService";

    export default {
        name: "ConditionSymbol",
        props:{
            condition:{// condition 对象
                type: Object,
                default:null,
                required:false
            },
            condition_id:{
                type: Number,
                default:null,
                required:false
            },
        },
        data:function () {
            return {
                'loading':false,//是否正在加载
                'combineData':null,
            };
        },
        mounted:async function(){
            //console.log("mounted", this.contract_id, this.contract);
            await this.update_combine_value();
        },
        watch:{
            'condition':async function(){
                await this.update_combine_value();
            },
            'condition_id':async function(){
                await this.update_combine_value();
            }
        },
        methods:{
            update_combine_value:async function () {
                this.loading = true;
                if(this.condition != null){
                    this.combineData = util.deepCopy(this.condition);
                    //console.log("contractSymbol get contract by contract");
                }else if(this.condition_id != null) {
                    this.combineData = await dataService.getCondition(this.condition_id);
                    //console.log("contractSymbol get contract by contract_id");
                }else {
                    this.combineData = null;
                }
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

.spin_container{
  height: 32px;
  padding-top: 10px;
}

</style>