<template>
    <div>
        <template v-if="data.reason_ref == null">
            <Tag>{{data.reason_type}}</Tag>
        </template>
        <template v-else>
            <Tag>{{data.reason_type}}:{{data.reason_ref}}</Tag>
        </template>
    </div>
</template>

<script>

    export default {
        name: 'mktWatchReason',
        props: ['data'],
        components: {
        }
    }

</script>

<style scoped>

</style>
