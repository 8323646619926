<template>
    <div>

        <div v-if="loading">
            <spin></spin>
        </div>

        <Form v-if="loading==false" v-bind:label-width="100"  style="max-width: 450px">

            <FormItem label="id" v-if="mode != 'create'">
                <InputNumber size="small" v-model="form_data.id"
                             v-bind:disabled="true"
                             v-bind:min="0"
                             v-bind:step="1"
                             style="width: 100%">
                </InputNumber>
            </FormItem>

            <FormItem label="业务标识">
                <Input v-model="form_data.biz_id" size="small"></Input>
            </FormItem>

            <FormItem label="名称">
                <Input v-model="form_data.name" size="small"></Input>
            </FormItem>

            <FormItem label="启用" >
                <i-switch size="large" v-model="form_data.enable">
                    <span slot="open">On</span>
                    <span slot="close">Off</span>
                </i-switch>
            </FormItem>

            <FormItem label="类型">
                <RadioGroup size="small" v-model="form_data.type" type="button">
                    <Radio v-bind:label="constants.VtConditionType.Price"
                           v-bind:disabled="disabled && form_data.type != constants.VtConditionType.Price">
                        <span>Price</span>
                    </Radio>
                    <Radio v-bind:label="constants.VtConditionType.Margin"
                           v-bind:disabled="disabled && form_data.type != constants.VtConditionType.Margin">
                        <span>Margin</span>
                    </Radio>

                </RadioGroup>
            </FormItem>

            <FormItem label="合约" v-if="form_data.type == constants.VtConditionType.Price">
                <ContractSelector v-bind:contract_id.sync="form_data.contract_id">
                </ContractSelector>
            </FormItem>

            <FormItem label="激活条件">
                <ConditionActiveInfoEditor v-model="form_data.active_condition">
                </ConditionActiveInfoEditor>
            </FormItem>

            <FormItem label="条件">
                <ConditionInfoEditor v-model="form_data.info">
                </ConditionInfoEditor>
            </FormItem>

            <FormItem label="提醒级别">
                <RadioGroup size="small" v-model="form_data.notice_level" type="button">
                    <Radio v-bind:label="constants.NoticeLevel.Slight"
                           v-bind:disabled="disabled && form_data.notice_level != constants.NoticeLevel.Slight">
                        <span>Slight</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Normal"
                           v-bind:disabled="disabled && form_data.notice_level != constants.NoticeLevel.Normal">
                        <span>Normal</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Important"
                           v-bind:disabled="disabled && form_data.notice_level != constants.NoticeLevel.Important">
                        <span>Important</span>
                    </Radio>
                    <Radio v-bind:label="constants.NoticeLevel.Emergency"
                           v-bind:disabled="disabled && form_data.notice_level != constants.NoticeLevel.Emergency">
                        <span>Emergency</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
        </Form>

        <Divider style="margin: 10px 0 10px 0 "></Divider>

        <div style="text-align:right">
            <!-- 点击关闭模态框 -->
            <Button v-on:click="on_cancel"
            >Cancel</Button>
            <Button type="primary"
                    v-bind:disabled="canSubmit == false"
                    v-bind:loading="submitting"
                    v-on:click="on_model_submit"
                    style="margin-left: 8px">
                Confirm
            </Button>
        </div>

        <!--
        workmode:{{mode}}
        <hr>
        from:{{form_data}}
        <hr>
        value:{{value}}
        -->
    </div>
</template>

<script>

    import ContractSymbol from "../display/ContractSymbol";
    import ConditionInfoEditor from "../condition/ConditionInfoEditor";
    import ConditionActiveInfoEditor from "../condition/ConditionActiveInfoEditor";
    import ContractSelector from "./ContractSelector";
    import constants from '../../../constants.js';
    import vtradeAdapter from "@/adapter/vtradeAdapter";

    function getDefaultValue() {
        return {//编辑中的合约对象
            biz_id:null,
            enable:false,
            name:null,
            type: constants.VtConditionType.Price,
            contract_id:null,
            notice_level: constants.NoticeLevel.Important,
            active:false,
            match:false,
            active_condition:{},
            info:{}
        }
    }

    export default {
        name: 'ConditionEditor',
        components: {
            ContractSymbol,
            ConditionInfoEditor,
            ConditionActiveInfoEditor,
            ContractSelector,
        },
        props:{
            value:{// condition 对象Id
                type:Number,
                default:null,
                required:false
            },
            disabled:{ //是否仅展示合约，禁用编辑
                type:Boolean,
                default: false,
                require:false
            },
            mode:{
                type: String,
                require: true
                //编辑器工作模式
                // create:创建, id/count 字段隐藏
                // modify:修改 condition，id 字段只读
            },
            showEditorModal:{
                type:Boolean,
                default: false,
                require:false
            },
        },
        data:function () {
            return {
                constants,
                showModal:true,
                form_data: getDefaultValue(),//编辑中的合约对象
                loading:false, //是否正在查询 condition 对象
                submitting:false, //是否正在提交
            }
        },
        mounted: function() {
            console.log("ConditionEditor mounted");
        },
        methods:{
            open:async function (){
                console.log("open conditionEditor");
            },
            on_model_submit:async function () {
                if(this.mode == 'create'){
                    console.log(`conditionEditor submit create model, condition model value=${JSON.stringify(this.form_data)}`);
                    this.submitting = true;
                    await vtradeAdapter.postConditionCreate(this.form_data);
                    this.submitting = false;
                    console.log("postConditionCreate success");
                    this.$Message.success('添加条件成功');
                    this.$emit('onSubmit');
                }else if(this.mode == "modify"){
                    console.log(`conditionEditor submit modify model, condition model value=${JSON.stringify(this.form_data)}`);
                    this.submitting = true;
                    await vtradeAdapter.postConditionModify(this.form_data['id'], this.form_data);
                    this.submitting = false;
                    console.log("postConditionModify success");
                    this.$Message.success('修改条件成功');
                    this.$emit('onSubmit');
                }
            },
            on_cancel:  function (){
                this.$emit('onCancel');
            },
        },
        computed:{
            canSubmit:function () {
                if(this.form_data.name != null)
                    return true;

                return false;
            }
        },
        watch:{
            value:async function (newValue) {
                //输入的 conditionId 变动时，查询数据填充到表单
                console.log(`ConditionEditor v-model value changed: ${newValue}`);

                this.form_data = getDefaultValue();
                if(newValue == null){
                    console.log("conditionId:null, ignore");
                    return;
                }

                //获取 condition 填充到表单
                this.loading = true;
                const condition = await vtradeAdapter.getCondition(this.value);
                console.log(`condition: ${JSON.stringify(condition)}`)
                this.form_data = condition;
                this.loading = false;
            }
        }
    }


</script>

<style scoped src="@/style/compactForm.css" />
<style scoped>

    .selector_div {
        border: 1px solid;
        border-color: #2d8cee;
        border-radius:5px;
    }

</style>
