<template>
    <div>
        <div ref="formContainerDiv">

            <Form v-bind:label-width="100"  style="max-width: 450px">

                <FormItem label="依赖类型" prop="sec_type">
                    <RadioGroup size="small" v-model="dependType" type="button">
                        <Radio v-bind:label="'CONDITION'">
                            <span>条件</span>
                        </Radio>

                        <Radio v-bind:label="'JOB'" disabled>
                            <span>任务</span>
                        </Radio>

                    </RadioGroup>
                </FormItem>

                <FormItem label="条件" prop="sec_type">
                    <a-select
                        v-bind:value="value"
                        show-search
                        placeholder="input name or id to search"
                        v-bind:allowClear="true"
                        v-bind:filter-option="false"
                        v-bind:not-found-content="conditionFetching == true ? undefined:'无匹配对象'"
                        v-bind:getPopupContainer="getPopupContainer"
                        v-on:search="onQueryStringChange"
                        v-on:change="onConditionSelectChange"
                        size="small"
                    >

                        <!--上面 select 中，not_found_content 为 undefined 的时候，才会显示 slot 的内容，用于区别是没找到还是在搜索中的两种情况-->
                        <a-select-option v-for="item in conditions" :key="util.build_depend_str('CONDITION', item.id)"
                                         v-bind:getPopupContainer="getPopupContainer"
                        >
                            CONDITION:{{ item.id }}:{{item.name}}
                        </a-select-option>
                    </a-select>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
import _ from 'underscore';
import vtradeAdapter from "@/adapter/vtradeAdapter";
import util from "@/util";

var searchConditionWrapper = _.debounce(vtradeAdapter.getConditionAll, 1500);//限制 1.2s 最多触发一次

/*
选择一个 depend 的组件，绑定值为 depend 字符串值
 */

export default {
    name: "DependSelector",
    props:{
        value:{// depend 字符串值, 如: "CONDITION:ID"
            type: String,
            default:null,
            required:false
        },
    },
    data:function (){
        return {
            util,
            dependType:'CONDITION', //依赖类型 CONDITION/JOB
            //防止重复搜索
            lastConditionSearchIdx:1, //本次请求序号
            lastConditionSearchStr:null, //最后一次搜索字符串

            conditionFetching:false, //是否正在请求

            conditions:[], // 搜索到 condition 列表
        };
    },
    methods:{
        getPopupContainer: function(){
            //解决下拉框被弹窗覆盖的问题
            var item=this.$refs.formContainerDiv.parentNode;
            console.info('getPopupContainer:',item);
            return item;
        },
        onQueryStringChange:function(queryStr){
            //每次合约输入框文字变化时调用
            this.lastConditionSearchIdx += 1;
            var currentQueryIdx = this.lastConditionSearchIdx;
            console.log("on-query-change:",queryStr, this.lastConditionSearchIdx, currentQueryIdx);

            if(queryStr == null || queryStr === "" || queryStr.toUpperCase() === this.lastConditionSearchStr){
                console.log("queryStr not changed, ignore", this.lastConditionSearchStr);
                return;
            }

            this.lastConditionSearchStr = queryStr.toUpperCase();
            this.$emit('input', null); //清除之前的值

            var that = this;
            this.conditions = [];

            console.log("search condition with queryStr:" + queryStr);
            this.conditionFetching = true;

            searchConditionWrapper(
                function (ret) {
                    console.log("search ret:",ret, currentQueryIdx);
                    if(currentQueryIdx !== that.lastConditionSearchIdx){
                        console.log('not latest query response, ignore');
                        return;
                    }

                    that.conditions = ret;
                    that.conditionFetching = false;
                },function () {
                    that.conditionFetching = false;
                }
            );
        },
        onConditionSelectChange:function (key){
            // value 为选项的 key
            console.log('onConditionSelectChange',key);

            //let value = this.conditions.find(element => util.build_depend_str("CONDITION",element.id) == key);
            //console.log('onConditionSelectChange value:',value);
            //this.aSelectorValue = key;
            this.$emit('input', key);
        }
    }
}
</script>

<style scoped src="@/style/compactForm.css" />
<style scoped>

</style>