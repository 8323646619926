<template>
    <div>
        <ContractSymbol v-bind:contract_id="value">
        </ContractSymbol>
        <Button size="small" v-bind:disabled="editor_open || disabled"
                v-on:click="on_open_editor"
        >
            修改
        </Button>
        <div v-if="editor_open">

            <Form v-bind:label-width="60"  style="max-width: 450px">

                <FormItem label="合约组合" >
                    <i-switch v-model="bag_mode" @on-change="on_bag_mode_change" >
                        <span slot="open">On</span>
                        <span slot="close">Off</span>
                    </i-switch>
                </FormItem>
                <FormItem label="合约" v-if="bag_mode===false">
                    <ContractSelector v-bind:contract_id.sync="editing_contract_id"></ContractSelector>
                </FormItem>

                <FormItem label="合约列表" v-if="bag_mode===true" prop="">
                    <div style="margin-left: 60px">
                        <template v-if="editing_list != null && editing_list.length > 0">
                            <div v-for="(item, index) in editing_list" v-bind:key="index" >
                                <a-input-number id="inputNumber" v-model="item.amount" :step="1" size="small"
                                                style="width: 50px; margin-right: 2px" />

                                X
                                <ContractSymbol v-bind:contract_id="item.contract_id"></ContractSymbol>

                                <Button size="small" v-on:click="on_remove(item)" class="vt_compact_btn">移除</Button>
                            </div>
                        </template>
                        <template v-else >
                            <Tag v-cloak class="sec_type_tag" color="default">NULL</Tag>
                        </template>
                    </div>
                </FormItem>

                <template v-if="bag_mode===true">
                    <Divider style="margin: 2px 0 2px 0 "></Divider>
                    <FormItem label="选择合约" >
                        <div style="margin-left: 60px">
                            <ContractSelector v-bind:contract_id.sync="wait_add_contract_id"></ContractSelector>
                            <Button size="small" v-on:click="on_add_contract" style="margin-right: 5px"
                                    v-bind:disabled="can_add_to_bag == false"
                                    class="vt_compact_btn"
                            >添加</Button>
                        </div>
                    </FormItem>
                </template>

                <Divider size="small" style="margin: 5px 0 5px 0 "></Divider>

                <FormItem label=" " >
                    <Button size="small" v-on:click="on_editor_commit" style="margin-right: 5px"
                            v-bind:disabled="can_commit == false"
                    >确定</Button>

                    <Button size="small" v-on:click="on_editor_cancel" style="margin-right: 5px"

                    >取消</Button>
                </FormItem>
            </Form>
        </div>

        <!--
        editing_contract_id:{{editing_contract_id}}
        <br>
        editing_list:{{editing_list}}
        <br>
        input_contract:{{input_contract}}
        -->
    </div>
</template>

<script>
/*
* 支持多个合约的选择器
*/

import ContractSelector from "@/components/common/editor/ContractSelector.vue";
import ContractSymbol from "@/components/common/display/ContractSymbol.vue";
import constants from "@/constants";
import vtradeAdapter from "@/adapter/vtradeAdapter";
import util from "@/util";
import Constants from "@/constants";

export default {
    name: "BagContractSelector",
    components:{
        ContractSelector,
        ContractSymbol,
    },
    props: {
        value:{ // contract id
            type: Number,
            default:null,
            required:false
        },
        disabled:{ //是否仅展示合约，禁用编辑
            type:Boolean,
            default: false,
            require:false
        },
    },
    data:function (){
        return {
            constants,
            loading:false, //正在拉取合约
            editor_open:false, //编辑器是否被打开
            bag_mode:false, // 组合合约模式
            input_contract:null, //输入的 contract（根据 contract_id 拉取到）
            editing_contract_id:null,//非组合模式下的合约
            editing_list:[],//组合模式下的合约 [{"contract_id":12}]
            wait_add_contract_id:null,//组合模式下等待添加的 contract_id
        }
    },
    methods:{
        on_open_editor:async function (){
            console.log("on_open_editor");
            this.editor_open = true;

            //把 value 更新到 data
            if(this.value == null){
                this.input_contract = null;
                this.bag_mode = false;
                this.editing_list = [];
            }else{
                this.loading = true;
                const contract = await vtradeAdapter.getContract(this.value);
                console.log(`contract: ${JSON.stringify(contract)}`)
                this.input_contract = contract;
                this.loading = false;
                this.editing_list = [];
                if(this.input_contract.sec_type === constants.SecType.BAG){
                    this.bag_mode = true;
                    //填充 legs
                    for(const idx in contract.combo_legs){
                        const item = contract.combo_legs[idx];
                        this.editing_list.push({
                            contract_id:item.contract_id,
                            amount:item.action === constants.OrderAction.BUY ? item.ratio : -1 * item.ratio
                        });
                    }
                }else {
                    this.bag_mode = false;
                }
            }
        },
        on_editor_commit:async function (){
            console.log("on_editor_commit");
            if(this.bag_mode === true){
                //注册合约组合
                const bagContract = {
                    id:null,
                    sec_type:Constants.SecType.BAG,
                    combo_legs:[],
                };

                for(const idx in this.editing_list){
                    const item = this.editing_list[idx];
                    const leg = {
                        contract_id:item.contract_id,
                        ratio:Math.abs(item.amount),
                        action:item.amount > 0? Constants.OrderAction.BUY:Constants.OrderAction.SELL,
                    };

                    bagContract.combo_legs.push(leg);
                }

                console.log("contract bagContract:", bagContract);
                const ret = await vtradeAdapter.postCreateBagContract(bagContract);
                console.log("created contract:", ret);

                this.$emit('input', ret.id);
            }else {
                this.$emit('input', this.editing_contract_id);
            }

            this.editor_open = false;
        },
        on_editor_cancel:function (){
            console.log("on_editor_cancel");
            this.editor_open = false;
        },
        on_bag_mode_change:function (){
            console.log("on_bag_mode_change");
        },
        on_add_contract:function (){
            console.log("on_add_contract");

            //检查不允许添加重复的合约
            for(const idx in this.editing_list){
                const item = this.editing_list[idx];
                console.log("check item:", item);
                if(item.contract_id === this.wait_add_contract_id){
                    util.show_notice("warning","不能加入已经存在的合约");
                    return;
                }
            }

            const leg = {'contract_id':this.wait_add_contract_id, 'amount':0};
            this.editing_list.push(leg);
        },
        on_remove:function (leg){
            console.log("on_remove:", leg);
            this.editing_list = this.editing_list.filter(item => item.contract_id !== leg.contract_id);
        },
    },
    computed:{
        can_commit:function (){
            //是否允许点击提交按钮
            if(this.bag_mode === false){
                return true;
            }else {
                if(this.editing_list.length <= 1){
                    return false;
                }

                for (let idx in this.editing_list) {
                    const item = this.editing_list[idx];
                    if(item == null || item.contract_id == null || item.amount === 0){
                        return false;
                    }
                }
            }
            return true;
        },
        can_add_to_bag:function (){
            //是否允许点击 bag 模式下的添加按钮
            if(this.wait_add_contract_id != null)
                return true;

            return false;
        },
    },
}
</script>

<style scoped>

</style>