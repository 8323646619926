<template>
    <div>
        <template v-if="value != null && value.length > 0">
            <div v-for="item in value" v-bind:key="item" >
                <ConditionSymbol v-bind:condition_id="util.unpack_depend_str(item)[1]"></ConditionSymbol>
            </div>
        </template>
        <template v-else>
            <Tag class="sec_type_tag" color="default">NULL</Tag>
        </template>
        <Button size="small"
                v-if="showEditorBtn"
                v-bind:disabled="disabled"
                v-on:click="on_click_modify"
        >
            修改
        </Button>

        <Modal v-model="editorOpen" class="modal2" scrollable width="350px"
               footer-hide
               v-bind:mask-closable="true"
               v-bind:closable="false"
               v-bind:mask="true"

        >

            <Form v-bind:label-width="100"  style="max-width: 450px">
                <FormItem label="依赖列表" prop="">
                    <template v-if="editingList != null && editingList.length > 0">
                        <div v-for="item in editingList" v-bind:key="item" >
                            <ConditionSymbol v-bind:condition_id="util.unpack_depend_str(item)[1]"></ConditionSymbol>
                            <Button size="small" v-on:click="on_remove(item)">删除</Button>
                        </div>
                    </template>
                    <template v-else >
                        <Tag v-cloak class="sec_type_tag" color="default">NULL</Tag>
                    </template>
                </FormItem>

                <FormItem label="" prop="">
                    <Button size="small"
                            v-if="showEditorBtn"
                            v-bind:disabled="underscore.isEqual(editingList, value)"
                            v-on:click="on_confirm"
                            style="margin-right: 5px"
                    >
                        确定
                    </Button>

                    <Button size="small"
                            v-if="showEditorBtn"
                            v-on:click="on_cancel"
                    >
                        取消
                    </Button>
                </FormItem>
            </Form>

            <Divider style="margin: 5px 0 5px 0 "></Divider>

            <DependSelector v-model="dependSelectorValue" ></DependSelector>

            <Form v-bind:label-width="100"  style="max-width: 450px">
                <FormItem label="" prop="">
                    <Button size="small"
                            v-if="showEditorBtn"
                            v-bind:disabled="dependSelectorValue == null || (editingList != null && editingList.includes(dependSelectorValue))"
                            v-on:click="on_click_add"
                    >
                        添加
                    </Button>


                </FormItem>
            </Form>
        </Modal>
    </div>
</template>

<script>
    /*
      编辑一个 Depends 列表
      目前用于 job 的 depends 属性
    */
    import ConditionSymbol from "../display/ConditionSymbol";
    import DependSelector from "@/components/common/editor/DependSelector.vue";
    import util from "@/util";
    import _ from 'underscore';

    export default {
        name: "DependsListEditor",
        components: {
            ConditionSymbol,
            DependSelector,
        },
        props:{
            value:{// depends 列表
                type: Array,
                default:function () {
                    return [];
                },
                required:true
            },
            disabled:{ //是否仅展示合约，禁用编辑
                type:Boolean,
                default: false,
                require:false
            },
            showEditorBtn:{ //是否显示编辑按钮
                type:Boolean,
                default: true,
                require:false
            }
        },
        data:function (){
            return {
                'underscore':_, //underscore 库
                editorOpen:false, //显示修改合约列表的模态框
                dependSelectorValue:null, //dependSelector 选择框的值
                editingList:null,//正在临时编辑的条件列表（修改的模态框中使用）
                util:util
            };
        },
        methods:{
            on_click_modify:function () {
                console.log("on_click_modify");
                this.editorOpen = true;
                this.editingList = this.value.slice(); //把当前列表复制一份用于编辑
            },
            on_click_add:function (){
                console.log("on_click_add");
                this.editingList.push(this.dependSelectorValue);
            },
            on_confirm:function (){
                console.log("on_confirm");
                this.$emit('input', this.editingList);
                this.editorOpen = false;
            },
            on_cancel:function (){
                console.log("on_cancel");
                this.editorOpen = false;
                this.editingList = null;
            },
            on_remove:function (dependStr){
                console.log(`on_remove: dependStr=${dependStr}`);

                let index = this.editingList.indexOf(dependStr);

                if (index !== -1) {
                    this.editingList.splice(index, 1);
                }
            },
        },
        watch:{
            editingList:function (value){
                console.log(`editingList change to ${value}`);
            }
        }
    }
</script>

<style scoped src="@/style/compactForm.css" />
<style scoped>

</style>