<template>
    <div class="dashboard">

        <Layout>
            <Sider hide-trigger v-bind:style="{background: '#fff', 'min-width':'240px'}">
                <Menu  active-name="marketData" v-on:on-select="menu_select">
                    <MenuGroup title="市场分析">
                        <MenuItem name="marketData">
                            <Icon type="md-document" />
                            市场数据
                        </MenuItem>
                    </MenuGroup>
                    <MenuGroup title="功能管理">
                        <MenuItem name="group">
                            <Icon type="md-heart" />
                            合约组
                        </MenuItem>
                        <MenuItem name="subscribe">
                            <Icon type="md-heart" />
                            数据订阅
                        </MenuItem>
                        <MenuItem name="condition">
                            <Icon type="md-heart" />
                            条件监控
                        </MenuItem>
                        <MenuItem name="notice">
                            <Icon type="md-heart" />
                            提醒
                        </MenuItem>
                    </MenuGroup>
                </Menu>
            </Sider>
            <Layout v-bind:style="{padding: '0 24px 24px', background: '#fff'}">
                <Content >
                    <router-view ></router-view>
                </Content>
            </Layout>
        </Layout>

    </div>
</template>

<script>

    export default {
        name: 'analyze',
        components: {
        },
        methods:{
            url_change(value){
                this.$root.url_change(value);
            },
            menu_select(value){
                console.log("menu_select:", value);
                var url = "/analyze/" + value;
                this.url_change(url);
            }
        }
    }

</script>

<style scoped>





</style>
