import { render, staticRenderFns } from "./ContractSymbol.vue?vue&type=template&id=45be9e27&scoped=true&"
import script from "./ContractSymbol.vue?vue&type=script&lang=js&"
export * from "./ContractSymbol.vue?vue&type=script&lang=js&"
import style0 from "@/style/compactTag.css?vue&type=style&index=0&id=45be9e27&prod&scoped=true&lang=css&"
import style1 from "./ContractSymbol.vue?vue&type=style&index=1&id=45be9e27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45be9e27",
  null
  
)

export default component.exports