<template>
    <div class="card_container" ref="container">
        <div>
            {{baseContract.symbol}}@{{baseContract.market}}
        </div>
    </div>
</template>

<script>
    import Konva from "konva";
    import PositionGroupModel from "../../model/PositionGroupModel";

    export default {
        name: 'positionCard',
        props:{
            data:{// 输入数据
                type: Object,
                default:null,
                required:true
            },
        },
        components: {
        },
        mounted() {
            this.draw();
        },
        computed:{
            baseContract:function () {
                return this.data.base.contract;
            }
        },
        methods: {
            draw:function () {

                //获取高宽度
                var container = this.$refs['container'];
                var width = container.clientWidth;
                var height = container.clientHeight;

                var guiModel = new PositionGroupModel(this.data, {'width':width, 'height':height});

                var stage = new Konva.Stage({
                    container: container,
                    width: width,
                    height: height
                });

                console.log("stage",stage, width, height);
                var layer = new Konva.Layer();

                //左上角基础合约名字
                var baseContractText = new Konva.Text({
                    x: 0,
                    y: 0,
                    text: "fff",
                    fontSize: 12,
                    align: 'left',
                    fontFamily: 'Calibri',
                    fill: 'black'
                });
                baseContractText.text( this.baseContract.symbol + "@" + this.baseContract.market);
                layer.add(baseContractText);

                //底部价格横轴
                var baselineSVGItem = guiModel.getBaselineSVGItem();
                layer.add(baselineSVGItem);

                //计算价格范围，标记点
                var allMarkSVGItem = guiModel.getAllMarkSVGItem();
                var optDateLevelLineSVGItemList = allMarkSVGItem.optDateLevelLineSVGItemList;
                var priceMarkSVGItemList = allMarkSVGItem.priceMarkSVGItemList;

                for(const markSVGItem of optDateLevelLineSVGItemList){
                    layer.add(markSVGItem);
                }

                for(const markSVGItem of priceMarkSVGItemList){

                    console.log("markSVGItem:",markSVGItem);

                    //横轴上的价格标记点
                    const pricePointSVGItem = markSVGItem['pricePoint'];
                    layer.add(pricePointSVGItem);

                    //横轴下方的价格标签
                    var priceTagSVGItem = markSVGItem['priceTag'];
                    layer.add(priceTagSVGItem);

                    //横轴上方的数量标签
                    var countTagSVGItem = markSVGItem['countTag'];
                    layer.add(countTagSVGItem);

                    //上方的合约标签
                    for( const contractTagSVGItem of markSVGItem.contractTagList){
                        layer.add(contractTagSVGItem);
                    }
                }

                stage.add(layer);
            }
        },
    }

</script>

<style scoped>

    .card_container {
        width: 400px;
        border-style: solid;
        border-width: 1px;
        border-color: lightgray;
        min-height: 200px;
        margin: 5px;
    }

</style>
