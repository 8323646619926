import axios from 'axios';
import $ from 'jquery'
import _ from 'underscore';

import util from '../util.js';
import cookies from 'browser-cookies';
import configService from "../service/configService";



var default_faild_handler = function (xhr, textStatus, errorThrown) {
    console.log('request failed',xhr,textStatus,errorThrown);
    if(textStatus == "abort"){
        // 忽略主动终止
        return;
    }

    var errorMsg = null;
    if( xhr.status == 400 ){
        var responseJson = JSON.parse(xhr.responseText);
        errorMsg = "code:" + responseJson['error_code'] + ", message:" + responseJson['error_desc'];
    }else {
        errorMsg = xhr.responseText;
    }

    util.show_notice("warning","处理失败: " + errorMsg);
};

var requestWithConfig = function ({method, url, data, useJson}, successHandler, failedHandler){
    var headers = {};
    var profile = configService.getCurrentProfile();
    if(profile['backendToken'] != null){
        headers['VtToken'] = profile['backendToken'];
    }

    let config = {
        headers: headers,
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        method:method,
        url: url,
        data:data,
        success: successHandler,
        error:function (xhr, textStatus, errorThrown) {

            var ignoreDefaultHandler = false;
            if(failedHandler != null){
                var retJson = null;
                if(xhr.status == 400){
                    retJson = xhr.responseJSON;
                }

                ignoreDefaultHandler = failedHandler(retJson);
            }

            if(ignoreDefaultHandler != true){
                default_faild_handler(xhr, textStatus, errorThrown);
            }
        }
    };

    if(useJson){
        config.contentType = "application/json";
    }

    let req = $.ajax(config);

    return req;
}

var request = function (method, url, data, successHandler, failedHandler) {
    let req = requestWithConfig({method, url, data}, successHandler, failedHandler);
    return req;
};

var get = function (url, data, successHandler, failedHandler) {
    return request("GET", url, data, successHandler, failedHandler);
};

var post = function (url, data, successHandler, failedHandler) {
    return request("POST", url, data, successHandler, failedHandler);
};

/*
对网络请求方法进行包装，返回包装之后的方法
网络请求方法需要返回 req对象（支持通过调用 abort 方法来终止）
能记录当前未完成的请求，当请求未完成右被再次调用时，能自动取消之前请求
主要用于搜索场景，如果随着字符输入开始查询多个关键词，那么随着后续请求的发送，会取消之前进行中的请求
 */
const autoAbortPreReqWrapper = function (reqFunc){
    let processingReq = null;

    const wrapper = function (){
        console.log(`run wrapper, arguments: ${JSON.stringify(arguments)}, reqFunc: ${reqFunc}`);
        if(processingReq != null){
            console.log('processingReq existed, abort it first');
            // ToDo: 判断请求是否已经结束
            processingReq.abort();
        }

        processingReq = reqFunc.apply(this, arguments);
    }

    return wrapper;
}

//在 autoAbortPreReqWrapper 的基础上，加入 underscore 的 debounce 功能
//传入一个方法，返回 具有 debounce 和 autoAbortPreReq 两个特性的方法
const debounceAutoAbortPreReqWrapper = function (reqFunc, delay){

    const autoAbortFunc = autoAbortPreReqWrapper(reqFunc);
    const debounceFunc = _.debounce(autoAbortFunc, delay);

    return debounceFunc;
}



function testServer(successHandler, failedHandler){

}


export default {
    requestWithConfig,
    request,
    get,
    post,
    autoAbortPreReqWrapper,
    debounceAutoAbortPreReqWrapper
}