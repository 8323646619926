<template>
    <textarea disabled="disabled"
            v-model="text">
    </textarea>
</template>

<script>

    export default {
        name: 'textview',
        components: {
        },
        props:['text']
    }

    //ToDo: 高度自适应

</script>

<style scoped>
    textarea  {
        width: 100%;
        min-height: 200px;
    }
</style>
