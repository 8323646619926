
var AccountValueTag  = {};

AccountValueTag.AccountType = "AccountType"; // 账户类型 [IB]
AccountValueTag.BaseCurrency = "BaseCurrency"; // 基础货币

AccountValueTag.NetLiquidation = "NetLiquidation"; //净清算价值（现金+证券价值）[IB]

AccountValueTag.EquityWithLoanValue = "EquityWithLoanValue"; // 含贷款价值的资产(计算保证金用，计算账户中正的可用于充当保证金的资产) [IB]
AccountValueTag.PreviousEquityWithLoanValue = "PreviousEquityWithLoanValue"; // 前一天含贷款价值的资产

AccountValueTag.GrossPositionValue = "GrossPositionValue"; // 证券头寸总市值（所有头寸的绝对值累加） [IB]
AccountValueTag.AccruedCash = "AccruedCash"; // 本月待收利息 [IB]

AccountValueTag.TotalCashBalance = "TotalCashBalance"; // 账户现金 (包含保证金占用部分)[IB]
AccountValueTag.TotalCashBalanceByCurrency = "TotalCashBalanceByCurrency"; // 按币总统计的账户现金 (包含保证金占用部分)

// 保证金
AccountValueTag.InitMarginReq = "InitMarginReq"; // 初始保证金 [IB]
AccountValueTag.MaintMarginReq = "MaintMarginReq"; // 维持保证金 [IB]

// 可用于交易
AccountValueTag.AvailableFunds = "AvailableFunds"; // 可用资金（账户现金减去保证金占用部分）[IB]
AccountValueTag.ExcessLiquidity = "ExcessLiquidity"; // 剩余流动性 (含贷款价值的资产 - 保证金) [IB]

AccountValueTag.UnrealizedPnL = "UnrealizedPnL"; // 浮动盈亏 [IB]

//证券类型
var SecType = {};
SecType.CASH = "CASH";
SecType.STK = "STK";
SecType.OPT = "OPT";
SecType.BAG = "BAG";

//订单状态
var OrderType = {};
OrderType.LMT = "LMT";//限价
OrderType.MKT = "MKT";//市价
OrderType.STP_LMT = "STP_LMT";//限价止损单

//订单方向
var OrderAction = {};
OrderAction.BUY = "BUY";
OrderAction.SELL = "SELL";

//订单有效期
var OrderTif = {};
OrderTif.DAY = "DAY";
OrderTif.GTC = "GTC";

//期权权利
var OptionRight = {};
OptionRight.CALL = "CALL";
OptionRight.PUT = "PUT";

//订单状态
var OrderState = {};
OrderState.ApiPending = "ApiPending";//已经提交至 TWS，等待 IB 确认
OrderState.PendingSubmit = "PendingSubmit";//已经提交至 IB，等待目的地确认
OrderState.PendingCancel = "PendingCancel";//已经发出取消请求，等待订单所在地确认
OrderState.PreSubmitted = "PreSubmitted";//订单到达 IB，等待选举完成后将发送至目的地（选举是什么？）
OrderState.Submitted = "Submitted";//订单已经到达目的地，并在生效中
OrderState.ApiCancelled = "ApiCancelled";//订单在被确认之前收到取消请求
OrderState.Cancelled = "Cancelled";//订单被取消
OrderState.Filled = "Filled";//订单完全成交
OrderState.Inactive = "Inactive";//订单未生效，多个原因

//BotJob 状态
var BotJobStatus = {};
BotJobStatus.Init = "Init";
BotJobStatus.Pending = "Pending";
BotJobStatus.Running = "Running";
BotJobStatus.WaitingSubJob = "WaitingSubJob";
BotJobStatus.Succeed = "Succeed";
BotJobStatus.Failed = "Failed";
BotJobStatus.HandUp = "HandUp";

//提醒级别
var NoticeLevel = {};
NoticeLevel.Slight = "Slight";
NoticeLevel.Normal = "Normal";
NoticeLevel.Important = "Important";
NoticeLevel.Emergency = "Emergency";

//提醒状态
var NoticeStatus = {};
NoticeStatus.Init = "Init";
NoticeStatus.Processing = "Processing";
NoticeStatus.Sent = "Sent";
NoticeStatus.Confirmed = "Confirmed";
NoticeStatus.Canceled = "Canceled";


//条件类型
var VtConditionType = {};
VtConditionType.Price = "Price";
VtConditionType.Margin = "Margin";

//修改状态
var ModifyType = {};
ModifyType.Add = "Add";
ModifyType.Del = "Del";
ModifyType.Replace = "Replace";

//BotJob类型
var AlgoBotJobType = {};
AlgoBotJobType.PriceImprove = "PriceImprove";
AlgoBotJobType.PositionAdjust = "PositionAdjust";

var AlgoBotJobStatus = {};
AlgoBotJobStatus.Init = "Init";
AlgoBotJobStatus.Pending = "Pending";
AlgoBotJobStatus.Running =  "Running";
AlgoBotJobStatus.WaitingSubJob = "WaitingSubJob";
AlgoBotJobStatus.Succeed = "Succeed";
AlgoBotJobStatus.Failed = "Failed";
AlgoBotJobStatus.HandUp = "HandUp";



export default {
    AccountValueTag,
    SecType,
    OrderType,
    OrderAction,
    OrderTif,
    OrderState,
    BotJobStatus,
    OptionRight,
    NoticeLevel,
    NoticeStatus,
    VtConditionType,
    ModifyType,
    AlgoBotJobType,
    AlgoBotJobStatus,
};