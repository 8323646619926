<template>
    <div class="selector_container">
        <!-- 最外面合约显示（打开模态框之前的） -->
        <ContractSymbol v-bind:data="value" v-bind:contract_id="contract_id"
                        v-if="!hide_symbol === true"
        >
        </ContractSymbol>
        <Button size="small" v-bind:disabled="editor_open || disabled"
                v-on:click="on_open_editor"
                v-if="!hide_entry_btn === true"
        >
            <template v-if="entry_btn_text != null">
                {{entry_btn_text}}
            </template>
            <template v-else>
                修改
            </template>
        </Button>

        <Modal v-model="editor_open" class="modal2" scrollable width="350px"
            footer-hide
            v-bind:mask-closable="true"
            v-bind:closable="false"
            v-bind:mask="true"

        >
            <div v-bind:class="{selector_div:showBorder}" ref="formContainerDiv">
                <Form v-bind:label-width="100"  style="max-width: 450px">

                    <FormItem label="合约" prop="xxx">
                        <div v-if="form_data.sec_type == constants.SecType.OPT && queryOptionProcessing "
                             style="display: inline-block">
                            <spin size="small"></spin>
                        </div>
                        <template v-else>
                            <ContractSymbol v-bind:contract="editing_contract"></ContractSymbol>
                        </template>
                        <Button size="small" v-on:click="on_remove" style="margin-right: 5px"
                        > 移除 </Button>
                    </FormItem>

                    <Divider style="margin: 10px 0 10px 0 "></Divider>

                    <FormItem label="证券类型" prop="sec_type">
                        <RadioGroup size="small"
                                    v-model="form_data.sec_type"
                                    type="button">
                            <Radio v-bind:label="constants.SecType.STK"
                                   v-bind:disabled="disabled || lock_contract_type!= null">
                                <span>股票</span>
                            </Radio>
                            <Radio v-bind:label="constants.SecType.OPT"
                                   v-bind:disabled="disabled || lock_contract_type!= null">
                                <span>期权</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    
                    <FormItem label="代码" prop="symbol">
                        <a-select
                                size="small"
                                v-bind:value="form_data.base_contract_id_str"
                                show-search
                                placeholder="input symbol to search"
                                v-bind:filter-option="false"
                                v-bind:not-found-content="queryBaseContractProcessing ? undefined:'无匹配对象'"
                                v-bind:getPopupContainer="getPopupContainer"
                                @search="onBaseContractQueryChange"
                                @change="handleBaseContractSelectChange"
                        >

                            <!--上面 select 中，not_found_content 为 undefined 的时候，才会显示 slot 的内容，用于区别是没找到还是在搜索中的两种情况-->
                            <a-spin v-if="queryBaseContractProcessing " slot="notFoundContent" size="small" />
                            <a-select-option v-for="(item, key) in baseContractSearchRetMap" :key="key"
                                v-bind:getPopupContainer="getPopupContainer"
                            >
                                {{ item.symbol }} @ {{item.primary_exchange}}
                            </a-select-option>
                        </a-select>

                    </FormItem>

                    <template v-if="form_data.sec_type == constants.SecType.OPT">

                        <FormItem label="期权界面" prop="opt_select_type">
                            <RadioGroup size="small" v-model="optSelectType" type="button">
                                <Radio label="simpleMode">
                                    <span>简单模式</span>
                                </Radio>
                                <Radio label="tMode">
                                    <span>T形报价</span>
                                </Radio>
                            </RadioGroup>
                        </FormItem>

                        <template v-if="optSelectType=='simpleMode'">

                            <FormItem label="期权类型" prop="opt_select_type">
                                <RadioGroup size="small" v-model="form_data.option_right" type="button">
                                    <Radio v-bind:label="constants.OptionRight.CALL"
                                           v-bind:disabled="disabled && form_data.option_right != constants.OptionRight.CALL">
                                        <span>CALL</span>
                                    </Radio>
                                    <Radio v-bind:label="constants.OptionRight.PUT"
                                           v-bind:disabled="disabled && form_data.option_right !=constants.OptionRight.PUT">
                                        <span>PUT</span>
                                    </Radio>
                                </RadioGroup>
                            </FormItem>

                            <FormItem label="行权价" prop="opt_select_type">
                                <InputNumber size="small"
                                             v-model="form_data.option_strike"
                                             v-bind:disabled="disabled"
                                             v-bind:min="0"
                                             v-bind:step="0.5"
                                             style="width: 100%">
                                </InputNumber>
                            </FormItem>

                            <FormItem label="到期日" prop="opt_select_type">
                                <a-date-picker  v-bind:getCalendarContainer="getPopupContainer"
                                                v-model="form_data.last_trade_date"
                                                v-bind:disabled="disabled"
                                                format="YYYYMMDD"
                                                size="small"
                                                allowClear
                                                placeholder="选择期权到期日"
                                                style="width: 100%"
                                />
                            </FormItem>

                        </template>
                        <template v-if="optSelectType=='tMode'">
                            <FormItem label="" >
                                T形报价. ToDo
                            </FormItem>

                        </template>
                    </template>

                    <FormItem label="" prop="xxx">
                        <Button size="small" v-on:click="on_editor_commit" style="margin-right: 5px"
                                v-bind:disabled="can_commit == false"
                        >确定</Button>

                        <Button size="small" v-on:click="on_editor_cancel" style="margin-right: 5px"

                        >取消</Button>
                    </FormItem>
                </Form>

                <!--

                form_data:{{form_data}}
                <hr>
                value:{{this.value}}
                <hr>
                editing_contract:{{editing_contract}}
                <hr>
                contract_id:{{this.contract_id}}
                <hr>
                baseContractSearchRetMap:{{baseContractSearchRetMap}}

                -->
            </div>
        </Modal>

    </div>
</template>

<script>
    import moment from "moment";
    import ContractSymbol from "../display/ContractSymbol";
    import vtradeAdapter from "../../../adapter/vtradeAdapter";
    import constants from '../../../constants.js';
    import util from "../../../util";
    import dataService from "../../../service/dataService";
    import requestAdapter from "@/adapter/requestAdapter";
    import Constants from "../../../constants.js";

    export default {
        name: 'ContractSelector',
        props:{
            /* 数据绑定：
               value  <-> contract 对象
               contract_id  <->  contract_id (v-sync 指令绑定)

               输入:
               value 和 contract_id 只需要且只能输入一个，都不为空且 value.id 和 contract_id 不一样则报错

               输出：
               合约选择后 value 和 contract_id 都会被更新
            * */

            /*
            数据流向:

            1, props: value(contract) , contract_id 输入
            2, 最外围的 contractSymbol 直接绑定 1 中的两个值，进行显示
            3, 修改的 modal 框打开时， 从输入计算出一个 contract, props -> contract(局部变量)
                3.1 contract -> editing_contract
                3.2 contract -> form_data 通过合约同步表单状态
            4, 搜索并选中时更新 editing_contract
            5，modal 框内部的 contractSymbol 绑定显示 editing_contract 中的值
            6, 提交时更新外部绑定变量: value, contract_id (需要注意的是，这里更新是通过 emit 事件触发父组件更新，如果外部没有绑定
            其中的一个，那么未绑定的变量就不会更新，这也没有影响)

            * */

            value:{// contract 对象
                type: Object,
                default:null,
                required:false
            },
            contract_id:{
                type: Number,
                default:null,
                required:false
            },
            lock_contract_type:{
                //从外部指定允许选择的合约类型，指定了之后，无法从 ui 上修改
                // 需要为 Constants.SecType.STK 或者 Constants.SecType.OPT
                type:String,
                default: null,
                require: false,
            },
            hide_symbol:{
                //是否隐藏自带的 contractSymbol
                type:Boolean,
                default: null,
                require: false,
            },
            hide_entry_btn:{
                //是否隐藏自带的 修改按钮
                type:Boolean,
                default: null,
                require: false,
            },
            entry_btn_text:{
                //入口按钮的文字，默认为 "修改"
                type:String,
                default: null,
                require: false,
            },
            disabled:{ //是否仅展示合约，禁用编辑
                type:Boolean,
                default: false,
                require:false
            },
            showBorder:{
                type:Boolean,
                default: false,
                require: false,
            },
        },
        components: {
            ContractSymbol
        },
        data:function () {
            return {
                constants,
                editor_open:false, //编辑器是否被打开

                lastBaseContractSearchStr:null,//记录上次搜索词，检查是否发生变化
                baseContractSearchRetMap:{}, //基础 contract 搜索框搜索到的合约，key = contract_id, value = contract，用来构建基础合约下拉框
                optContractSearchRetMap:{}, //搜索返回的期权合约，用来构建 T 形报价

                editing_contract:null,//当前编辑中的合约(为了后续支持多选，这里使用列表)

                //查询数据状态
                queryBaseContractProcessing:false,//是否正在查询基础合约
                queryOptionProcessing:false,//是否正在查询期权

                optSelectType:"simpleMode",
                loading:false,

                form_data:{//编辑中的合约对象
                    sec_type:"STK",
                    base_contract_id_str:null, //绑定基础合约选择框的值，因类型限制，这里必须为 str
                    option_right:null,
                    option_strike:null, //行权价
                    last_trade_date:null // Moment 对象
                }
            }
        },
        mounted: function() {
            if(this.value != null){
                console.log("mount with contract not null, update form_data",this.value);
            }
        },
        methods:{
            getPopupContainer(){
                //解决下拉框被弹窗覆盖的问题
                var item=this.$refs.formContainerDiv.parentNode;
                console.info('getPopupContainer:',item);
                return item;
            },
            on_open_editor:async function(){
                console.log("on_open_edit, value, contract_id=",this.value,this.contract_id);

                if(this.lock_contract_type != null){
                    this.form_data.sec_type = this.lock_contract_type === Constants.SecType.STK ? Constants.SecType.STK: Constants.SecType.OPT;
                }

                //从 value 构建表单
                if(this.value != null || this.contract_id != null) {

                    var contract = null;
                    if(this.value != null){
                        contract = util.deepCopy(this.value);
                        console.log("get contract by value");
                        this.$emit('update:contract_id', contract.id);
                    }else {
                        contract = await dataService.getContract(this.contract_id);
                        console.log("get contract by contract_id");
                        this.$emit('input', util.deepCopy(contract));
                    }

                    console.log("contract:", JSON.stringify(contract));

                    this.editing_contract = contract;

                    var form_data = null;
                    var contractMap = {};

                    //构造下拉框 baseContractSearchRetMap
                    if (contract['sec_type'] == "STK") {
                        console.log("go stk");
                        form_data = {
                            sec_type: contract['sec_type'],
                            base_contract_id_str: contract['id'].toString(),
                            option_right: contract['option_right'],
                            option_strike: contract['option_strike'],
                            last_trade_date: null,
                        };

                        contractMap[contract['id'].toString()] = util.deepCopy(contract);
                    } else {
                        console.log("go opt");
                        const base_contract_id = contract['base_contract_id'];
                        form_data = {
                            sec_type: contract['sec_type'],
                            base_contract_id_str: base_contract_id.toString(),
                            option_right: contract['option_right'],
                            option_strike: contract['option_strike'],
                            last_trade_date: moment(contract['last_trade_date'], "YYYYMMDD") //需要传入 moment 对象
                        };
                        contractMap[base_contract_id.toString()] = {'id': base_contract_id, 'sec_type': "STK", "symbol": contract.symbol};
                    }

                    this.baseContractSearchRetMap = contractMap;
                    console.log("set form data:", JSON.stringify(form_data));
                    this.form_data = form_data;
                }
                else{
                    // 如果外部传入的没有值，但是表单有值，那么进行清空
                    console.log("contractSelector clear form");
                }

                this.editor_open = true;
            },
            on_editor_cancel:function(){
                this.editor_open = false;
            },
            on_editor_commit:function(){
                var contract_id = this.editing_contract != null ? this.editing_contract.id:null;

                this.$emit('input', this.editing_contract);
                this.$emit('update:contract_id', contract_id);

                this.editor_open = false;
            },
            on_remove:function(){
                //清除选中的合约
                this.editing_contract = null;
            },
            queryBaseContractWrapper:requestAdapter.debounceAutoAbortPreReqWrapper(vtradeAdapter.searchContract, 1200),
            queryOptionWrapper:requestAdapter.debounceAutoAbortPreReqWrapper(vtradeAdapter.searchOption, 1200),
            onBaseContractQueryChange:function(queryStr){
                //每次合约输入框文字变化时调用
                console.log("on-query-change:",queryStr, this.lastBaseContractSearchStr);

                if(queryStr == null || queryStr == "" || queryStr.toUpperCase() == this.lastBaseContractSearchStr){
                    console.log("queryStr not changed, ignore", this.lastBaseContractSearchStr);
                    return;
                }

                this.lastBaseContractSearchStr = queryStr.toUpperCase();
                this.form_data.base_contract_id_str = null;

                var that = this;
                this.baseContractSearchRetMap = {};

                console.log("search base contract:" + queryStr);

                this.queryBaseContractProcessing = true;
                this.queryBaseContractWrapper(
                    that.$store.state.currentAccount,
                    queryStr,
                    function (ret) {
                        console.log("search base contract ret:",ret);
                        that.queryBaseContractProcessing = false;

                        var contractMap = {};
                        for(var i=0;i<ret.length;i++){
                            var item = ret[i];
                            console.log("base contract item:",item);
                            contractMap[item.contract.id.toString()] = item.contract;
                        }
                        that.baseContractSearchRetMap = contractMap;
                    },function () {
                        that.queryBaseContractProcessing = false;
                    }
                );
            },
            handleBaseContractSelectChange(value) {
                console.log('handleBaseContractSelectChange:',value);
                var contract = this.baseContractSearchRetMap[value];
                console.log("contract:",contract);
                this.editing_contract = contract;
                this.form_data.base_contract_id_str = value;
            },
        },
        computed:{
            can_commit:function () {
                if(this.queryBaseContractProcessing || this.queryOptionProcessing){
                    return false;
                }

                return true;
            },
        },
        watch:{
            'form_data':{
                deep:true,
                handler:function (new_value, old_value) {
                    // 界面输入框变动时调用，检查 form_data
                    // 如果是有效的合约，则更新 editing_contract 为当前合约
                    // 如果无效，则设置 editing_contract 为 null 表示当前未选中
                    console.log("selector form changed:", new_value);
                    var form_data = this.form_data;
                    var contract = null;
                    if(form_data.sec_type == "STK"){
                        this.editing_contract = null;
                        if(form_data.base_contract_id_str != null){
                            //console.log("emit input value:");
                            //从 map 中获取 contract 原始对象提交
                            contract = this.baseContractSearchRetMap[form_data['base_contract_id_str']];
                            this.editing_contract = util.deepCopy(contract);
                            //console.log("flag 1");
                        }else {
                            this.editing_contract = null;
                            //console.log("flag 2");
                        }
                    }else if(form_data.sec_type == "OPT"){
                        //选择器 v-model 产生 Date 类型，所以在这里转换

                        if(this.optSelectType == "simpleMode"){
                            this.editing_contract = null;

                            if(form_data.base_contract_id_str != null &&
                                form_data.option_right != null &&
                                form_data.option_strike != null &&
                                form_data.last_trade_date != null
                            ){
                                let that = this;
                                var option_data = {
                                    option_right: form_data.option_right,
                                    option_strike: form_data.option_strike,
                                    option_last_trade_date: form_data.last_trade_date.format('YYYYMMDD'),
                                };

                                console.log(`query option, option_data=${option_data}`);
                                this.queryOptionProcessing = true;
                                this.queryOptionWrapper(form_data.base_contract_id_str, option_data, function (ret) {
                                    that.queryOptionProcessing = false;
                                    console.log('query option ret:',ret);
                                    if(ret == null || ret.length == 0){
                                        util.show_notice("warning","查询期权失败, 未找到匹配期权");
                                    }else if (ret.length > 1){
                                        util.show_notice("warning","查询期权失败, 找到多个匹配期权, count=",ret.length);
                                    }else {
                                        that.editing_contract = ret[0];
                                    }
                                },function () {
                                    console.log('set queryOptionReq null');
                                    that.queryOptionProcessing = false;
                                });

                            }
                        }
                    }

                    return;

                }
            },
            'value':function () {
                //ToDo: 从合约 id 更新合约符号的显示
            }
        }
    }

</script>

<style scoped src="@/style/compactForm.css" />
<style scoped>

    .selector_container {
        display: inline-block;
        margin-right: 4px;
    }

    .selector_div {
        border: 1px solid;
        border-color: #2d8cee;
        border-radius:5px;
    }

</style>
