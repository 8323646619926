<template>
    <div>
        <!--
        <hr>
        from:{{$data}}
        <hr>
        value:{{value}}
        <hr>
        -->

        <ConditionActiveInfo v-bind:value="value">
        </ConditionActiveInfo>
        <Button size="small" v-bind:disabled="editor_open"
                v-on:click="on_open_editor"
        >
            修改
        </Button>
        <div v-if="editor_open">
            <div class="editor_container">

                <Select v-model="market_phase" size="small" placeholder="时间段" class="editor_item">
                    <Option value="Closed" >Closed</Option>
                    <Option value="PreExt" >PreExt</Option>
                    <Option value="AfterExt" >AfterExt</Option>
                    <Option value="Running" >Running</Option>
                </Select>

                <a-input-number size="small" v-model="time_begin" class="editor_item"
                             v-bind:step="1"
                >
                </a-input-number>
                ~
                <a-input-number size="small" v-model="time_end" class="editor_item"
                             v-bind:step="1"
                >
                </a-input-number>

            </div>

            <Button size="small" v-on:click="on_editor_cancel" style="margin-right: 5px"

            >取消</Button>

            <Button size="small" v-on:click="on_editor_commit" style="margin-right: 5px"
                    v-bind:disabled="can_commit == false"
            >提交</Button>
        </div>
    </div>


</template>

<script>

    import ConditionActiveInfo from "./ConditionActiveInfo";
    import util from "@/util";

    export default {
        name: 'conditionActiveInfoEditor',
        props:{
            value:{// conditionActiveInfo 对象
                type:Object,
                default:null,
                required:false
            },
        },
        components: {
            ConditionActiveInfo
        },
        data:function(){
            return {
                editor_open:false, //编辑器是否被打开
                market_phase:null,//市场阶段
                time_begin:null,
                time_end:null,
            }
        },
        computed:{

        },
        methods:{
            on_open_editor:function () {
                //ToDo 每次打开的时候，清除原始值，并且把当前的值填充到编辑器
                const marketPhase = this.value['MarketPhase'];
                const unpackValue = util.unpack_phase_exp(marketPhase);
                if(unpackValue.success){
                    this.market_phase = unpackValue.phase_name;
                    this.time_begin = unpackValue.phase_start;
                    this.time_end = unpackValue.phase_end;
                }

                this.editor_open = true;
            },
            on_editor_commit:function () {
                //点击提交按钮的时候，更改模型的值
                var newValue = {};
                var time_suffix = '';
                if( this.time_begin != null || this.time_end != null ){
                    time_suffix = "[" +
                                        (this.time_begin != null ?  this.time_begin:"") +
                                       ":" +
                                        (this.time_end != null ?  this.time_end:"") +
                                       "]"
                }

                newValue['MarketPhase'] = [this.market_phase + time_suffix];

                console.log(`conditionActiveInfoEditor emit value:${JSON.stringify(newValue)}`);
                this.$emit('input', newValue);

                this.editor_open = false;
            },
            on_editor_cancel:function () {
                this.editor_open = false;
            }
        }
    }

</script>

<style scoped>

    .editor_item{
        display: inline-block;
        margin-right: 5px;
        width: 90px;
    }

</style>
