import vtradeAdapter from '../adapter/vtradeAdapter';
import messageBus from './messageBusService.js';
import messageBusService from "@/service/messageBusService";

//从后端订阅市场数据，写入 store
//先 receive all

var app = null;
var wsClient = null;

var contract_cache = {
    // key = contract_key
    // value = contract
};


function startSubscribe() {

    console.log("startSubscribe server market data");
    app.$store.commit('updateServerMarketDataWsStatus', 'connecting');
    wsClient = vtradeAdapter.connectServerMarketDataWs(function () {

        console.log("wsClient connect:",this);

        //服务端对每个 ws 连接，其中完整的 contract 对象只会下发一次，后续通过 contract_key 字符串代替，所以这里需要缓存
        //清空 contract cache 缓存
        contract_cache = {};

        app.$store.commit('updateServerMarketDataWsStatus', 'connected');
        var command = {"type":"config", "value": {"receive_all":true}};
        console.log("send subscribe cmd:", command);
        this.send(JSON.stringify(command))

        messageBusService.postEvent(messageBusService.EVENT_MARKET_DATA_WS_CONNECT);

    },function (eventData) {
        //console.log("eventData:",eventData);
        var eventType = eventData['event_type'];
        var value = eventData['value'];

        fill_market_data(eventType, value);

    },function () {
        app.$store.commit('updateServerMarketDataWsStatus', 'disconnected');
        messageBusService.postEvent(messageBusService.EVENT_MARKET_DATA_WS_DISCONNECT);
    });
}

function stopSubscribe() {
    console.log("stop subscribe");
}

function addContractWatchReason(contractId, reasonType, reasonRef){
    let req = {"type":"addWatch", "value": {"contractId":contractId, "reasonType": reasonType, "reasonRef":reasonRef}};
    wsClient.send(JSON.stringify(req));
}

function removeContractWatchReason(contractId, reasonType, reasonRef){
    let req = {"type":"removeWatch", "value": {"contractId":contractId, "reasonType": reasonType, "reasonRef":reasonRef}};
    wsClient.send(JSON.stringify(req));
}

function fill_market_data(data_type, value) {

    if(data_type == "Data"){

        var data = value['data'];
        var contract_id = value['contract_id'];
        var contract = value['contract'];

        if(contract != null){
            //当数据中包含 contract 对象时，进行缓存
            contract_cache[contract_id] = contract;
        }

        app.$store.commit('updateMarketLatestData', {
            "id": contract_id,
            "data": data
        });
    }
}

function setApp(item) {
    app = item;
}


export default {
    setApp,
    startSubscribe,
    stopSubscribe,
    addContractWatchReason,
    removeContractWatchReason,
}