import $ from 'jquery'


import dataService from './service/dataService';

function show_notice(type, message) {

    var app = dataService.getApp();

    //type: info, success, warning, error, loading
    app.$Message[type]( {
        'content': message,
        'duration': 5
    });
}

function show_confirm(title, content, confirmHandler, cancelHandler) {

    if(cancelHandler == null){
        cancelHandler = function () {};
    }

    if(content != null){
        content = content.replace(/\n/g,"<br>");
    }

    var app = dataService.getApp();
    app.$Modal.confirm({
        title: title,
        content: content,
        onOk: confirmHandler,
        onCancel: cancelHandler
    });
}

function refresh() {
    window.location.reload();
}

//深拷贝对象
function deepCopy(item) {
    if(item == null)
        return null;

    return JSON.parse(JSON.stringify(item));
}

// 对浮点数取指定位数的函数(4舍5入)
function roundToNDecimal(number, decimals){
    return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
}

//取整数（向上，向下）

function build_depend_str(depend_type, ref_id) {
    if (!["CONDITION"].includes(depend_type)) {
        throw new Error("depend_type is not CONDITION");
    }

    return `${depend_type}:${ref_id}`;
}

function unpack_depend_str(depend_str){
    let depend_type = null;
    let ref_id = null;

    if (depend_str.startsWith("CONDITION:")) {
        depend_type = "CONDITION:";
    } else {
        console.log("unknown depend str:" + depend_str)
        return null;
    }

    ref_id = parseInt(depend_str.substring(depend_type.length));
    return [depend_type, ref_id];
}


function unpack_phase_exp(phase_str) {
    // 正则表达式匹配
    // phase_name[n1:n2]
    // phase_name[n1:]
    // phase_name[:n2]
    // phase_name

    const regex = /^([a-zA-Z]*)(?:\[([-]?[0-9]+)?:([-]?[0-9]+)?])?$/;
    const match = regex.exec(phase_str);
    if (match === null) {
        console.warning(`unpack_phase_exp ailed, phase_str=${phase_str}`);
        return { success: false };
    }

    const phase_name = match[1];
    const phase_start = match[2];
    const phase_end = match[3];

    return { success: true, phase_name, phase_start, phase_end }
}

//获取期权的短名称
//例如: TSLA 200P 240510
function get_option_short_name(option_contract, config){
    if(option_contract == null)
        return null;

    const include_base_symbol = config !=null && config['include_symbol'] === false? false:true;

    var ret = '';
    if(include_base_symbol){
        ret += `${option_contract.symbol} `
    }

    ret += `${option_contract.option_strike}` +
        `${option_contract.option_right[0]}` +
        ` ${option_contract.last_trade_date.substring(2).replaceAll('-','')}`

    return ret;
}


export default {
    "name": "util",
    show_notice,
    show_confirm,
    refresh,
    deepCopy,
    roundToNDecimal,
    build_depend_str,
    unpack_depend_str,
    unpack_phase_exp,
    get_option_short_name,
}





